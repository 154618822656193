import React, { useState, useEffect, useContext } from 'react'
import { /*useHistory,*/ useLocation } from 'react-router-dom'
import { Form, Button } from 'react-bootstrap'
import UserContext from '../../UserContext'
import { contentLoading, contentLoaded } from '../../reducers/actions'
import Onboarding from '../layouts/Onboarding'
import * as auth from '../../api/auth'

const ResetPassword = () => {
    const {dispatch} = useContext(UserContext)
    // const history = useHistory()
    // TODO Remove email and password
    const [email, setEmail] = useState((process.env.NODE_ENV === 'development') ? 'francisco.sinopoli@gmail.com' : '')
    const [isResetting, setIsResetting] = useState(false)
    const location = useLocation()
    const [params, setParams] = useState()
   
    const handleReset = (e) => {
        e.preventDefault()
        setIsResetting(true)
    }

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        setParams({
            email: searchParams.get('email') || '',
            token: searchParams.get('token') || null
        })
    }, [location])

    useEffect(() => {
        if (isResetting) {
            (async() => {
                dispatch(contentLoading())
                const res = await auth.resetPassword(email)
                dispatch(contentLoaded())
                setIsResetting(false)
                if (!res.error) {
                    alert('Controlla la tua casella di posta')
                } else {
                    alert(Array(res.error).map(obj => { return obj[Object.keys(obj)[0]] }).join('\n'))
                }
            })()
        }
    }, [isResetting])

    return (
        <Onboarding title="Reset Password" 
                    body="Inserisci l'indirizzo email con il quale sei registrato e clicca su Invia, riceverai una mail con le istruzioni per impostare una nuova password."
                    context="reset-password">
            <Form onSubmit={handleReset}>
                <Form.Group>
                    <Form.Control type="email" value={email} onChange={(e) => setEmail(e.target.value)} 
                        placeholder="Email" required />
                </Form.Group>
                <Form.Group>
                    <Button variant="primary" type="submit" 
                        disabled={isResetting}>Invia</Button>
                </Form.Group>
            </Form>
    </Onboarding>
    )
}

export default ResetPassword