import React, { useEffect, useState } from "react";
import { useHistory, useParams, Link } from "react-router-dom";
import {
  Modal,
  Row,
  Col,
  Container,
  Form,
  Button,
  Card,
} from "react-bootstrap";
import Map from "../../components/Map";
import { getArea, saveArea } from "../../api/areas";
import BaseLayout from "../layouts/BaseLayout";
import Footer from "../../components/Footer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import GoBack from "../../components/GoBack";

/**
 * Handled routes:
 * /areas/new
 * /areas/:areaId/edit
 * /areas/:areaid/subareas/new
 * /areas/:areaId/subareas/:subareasId/edit
 */
const AreaEditor = (props) => {
  const history = useHistory();
  const { areaId, subareaId } = useParams();
  const [area, setArea] = useState({
    id: null,
    parent_id: null,
    name: "",
    lat: 0,
    lng: 0,
    radius: 0,
  });
  const [parentArea, setParentArea] = useState();
  const [validated, setValidated] = useState(false);
  const [error, setError] = useState({});
  const isArea = props.model === "area";

  useEffect(() => {
    if (props.model === "subarea") {
      (async () => {
        const parent = await getArea(areaId);
        setParentArea(parent);
      })();
    }

    if (props.isNew) {
      if (props.model !== "area") {
        // Set the Parent ID of the new area
        setArea({ ...area, parent_id: parseInt(areaId) });
      }
    } else {
      (async () => {
        let res;
        if (props.model !== "area") {
          // It's a child area
          console.log(`Loading subarea ${subareaId}`);
          res = await getArea(subareaId, areaId);
        } else {
          // It's a main area
          console.log(`Loading area ${areaId}`);
          res = await getArea(areaId);
        }
        setArea(res);
      })();
    }
  }, []);

  const changeAreaHandler = (e) => {
    const { name, value } = e.target;
    setArea({ ...area, [name]: value });
  };

  const saveAreaHandler = (e) => {
    (async () => {
      e.preventDefault();
      e.stopPropagation();
      setValidated(true);
      if (!e.currentTarget.checkValidity()) return;

      const res = await saveArea(area);
      if (res.error) {
        setError(res.error);
        if (res.error.message) {
          alert(res.error.message);
        }
      } else {
        history.goBack();
      }
    })();
  };

  // #region Map dialog

  const [posPickerModalVisibility, setPosPickerModalVisibility] =
    useState(false);
  const [posPickerPoint, setPosPickerPoint] = useState();

  const posPickerOpenModalHandler = (e) => {
    if (area.lat !== 0 && area.lng !== 0) {
      setPosPickerPoint({
        lat: area.lat,
        lng: area.lng,
      });
      setPosPickerModalVisibility(true);
    } else {
      navigator.geolocation.getCurrentPosition((pos) => {
        setPosPickerPoint({
          lat: pos.coords.latitude,
          lng: pos.coords.longitude,
        });
        setPosPickerModalVisibility(true);
      });
    }
  };

  const closePosPickerModalHandler = (e) => {
    setPosPickerModalVisibility(false);
  };

  const posPickerChanged = (e) => {
    setPosPickerPoint(e.latLng);
  };

  const confirmPosFromPicker = (e) => {
    const updatedArea = {
      ...area,
      lat: posPickerPoint.lat,
      lng: posPickerPoint.lng,
    };
    setArea(updatedArea);
    setPosPickerModalVisibility(false);
  };

  // #endregion Map dialog

  return (
    <Card>
      <Card.Header>
        <Card.Title>
          <GoBack to={isArea ? "/areas" : "/areas/" + areaId + "/subareas/"} />
          <span>
            {props.isNew
              ? isArea
                ? "Nuova area"
                : "Nuova sottoarea"
              : isArea
              ? "Modifica area"
              : "Modifica sottoarea"}
          </span>
        </Card.Title>
      </Card.Header>
      <Card.Body>
        <Modal
          size="lg"
          show={posPickerModalVisibility}
          onHide={closePosPickerModalHandler}
        >
          <Modal.Header closeButton>
            <Modal.Title>Individua sulla mappa</Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ height: "70vh", padding: "0" }}>
            <Map
              mapCenter={posPickerPoint}
              initialPos={posPickerPoint}
              posChanged={posPickerChanged}
            />
          </Modal.Body>
          <Modal.Footer>
            <Container>
              <Row>
                <Col>
                  <Button
                    variant="secondary"
                    onClick={closePosPickerModalHandler}
                  >
                    Annulla
                  </Button>
                  <Button variant="primary" onClick={confirmPosFromPicker}>
                    Conferma
                  </Button>
                </Col>
              </Row>
            </Container>
          </Modal.Footer>
        </Modal>

        <Container fluid>
          <Form noValidate validated={validated} onSubmit={saveAreaHandler}>
            {props.model === "subarea" ? (
              <Form.Group>
                <Form.Label>Area principale</Form.Label>
                <Form.Control
                  disabled
                  value={parentArea ? parentArea.name : ""}
                />
              </Form.Group>
            ) : (
              ""
            )}

            <Form.Group>
              <Form.Label>Nome</Form.Label>
              <Form.Control
                type="text"
                placeholder="Nome dell'area"
                name="name"
                value={area.name}
                onChange={changeAreaHandler}
                required
                isInvalid={error?.name}
              />
              <ErrorLabel errors={!area?.name || area.name === ""}>
                Inserici il nome dell'area
              </ErrorLabel>
            </Form.Group>

            <Form.Row>
              <Form.Group as={Col} sm={12} md={6}>
                <Form.Label>Latitudine</Form.Label>
                <Form.Control
                  type="number"
                  placeholder="Latitudine"
                  name="lat"
                  value={area.lat}
                  onChange={changeAreaHandler}
                  min="-90"
                  max="90"
                  step="any"
                  required
                />
              </Form.Group>

              <Form.Group as={Col} sm={12} md={6}>
                <Form.Label>Longitudine</Form.Label>
                <Form.Control
                  type="number"
                  placeholder="Longitudine"
                  name="lng"
                  value={area.lng}
                  onChange={changeAreaHandler}
                  min="0"
                  max="180"
                  step="any"
                  required
                />
              </Form.Group>
            </Form.Row>

            <Button onClick={posPickerOpenModalHandler}>
              Apri nella mappa
            </Button>

            <Form.Group>
              <Form.Label>Raggio Operativo (in mt)</Form.Label>
              <Form.Control
                type="number"
                placeholder="Raggio in metri"
                min="0"
                name="radius"
                value={area.radius}
                onChange={changeAreaHandler}
              />
            </Form.Group>

            <Button
              variant="secondary"
              onClick={() => history.goBack()}
              type="button"
            >
              Annulla
            </Button>
            <Button variant="primary" type="submit">
              Salva
            </Button>
          </Form>
        </Container>
      </Card.Body>
    </Card>
  );
};
function ErrorLabel({ errors, children }) {
  if (errors)
    return <Form.Label className="text-danger">{children}</Form.Label>;
  else return null;
}
export default AreaEditor;
