import { baseUrl, options } from './common'
import axios from 'axios'

const listAgencies = async (name = '', pageIx, status = '') => {
    try {
        const url = `${baseUrl}/api/agencies?name=${name}&page=${pageIx}&status=${status}`;
        const response = await axios.get(url, options())
        return response.data
    } catch (err) {
        console.log(err)
        return {
            error: err
        }
    }
}

const getAgency = async (id) => {
    try {
        const url = `${baseUrl}/api/agencies/${id}`;
        const response = await axios.get(url, options())
        return response.data
    } catch (err) {
        console.log(err)
        return {
            error: err
        }
    }
}

const saveAgency = async (agency) => {
    try {
        let isNew = (agency.id === null)

        let url = `${baseUrl}/api/agencies`


        let response
        if (isNew) {
            response = await axios.post(url, agency, options())
        } else {
            url += `/${agency.id}`
            response = await axios.put(url, agency, options())
        }
        return response.data
    } catch (err) {
        console.log(err)
        return {
            error: err.response ? err.response.data : err
        }
    }
}

const deleteAgency = async (agency) => {
    try {
        const url = `${baseUrl}/api/agencies/${agency.id}`
        const response = await axios.delete(url, options())
        return response.data
    } catch (err) {
        console.log(err)
        return {
            error: err
        }
    }
}

export { listAgencies, getAgency, saveAgency, deleteAgency }