import React, { useEffect, useState, useContext } from "react";
import UserContext from "../../UserContext";
import { useHistory, useParams, useLocation } from "react-router-dom";
import { contentLoading, contentLoaded } from "../../reducers/actions";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import BaseLayout from "../layouts/BaseLayout";
import { payments } from "../../api/payments";
import { getProduct } from "../../api/products";
import { formatDayAndMonth } from "../../utils";
import { Row, Col, Container, Button, Card } from "react-bootstrap";

const Checkout = () => {
  const { dispatch } = useContext(UserContext);
  const history = useHistory();
  const { productId } = useParams();
  const useQuery = new URLSearchParams(useLocation().search);
  const areaId = useQuery.get("area_id");
  const areaName = useQuery.get("area_name");
  const dateStart = useQuery.get("date_start");
  const dateEnd = useQuery.get("date_end");
  const quantity = useQuery.get("quantity") || 1;

  const [product, setProduct] = useState({
    id: null,
    name: "",
    description: "",
    price: "",
  });

  const [order, setOrder] = useState({
    id: null,
    product_id: productId,
    price: 0.0,
    area_id: areaId,
    date_start: dateStart,
    date_end: dateEnd,
    quantity: parseInt(quantity),
  });

  useEffect(() => {
    console.log("Area name ", areaName);
  });

  useEffect(() => {
    if (product.id !== undefined) {
      const updatedOrder = { ...order, product_id: product.id };
      setOrder(updatedOrder);
    }
  }, [product]);

  useEffect(() => {
    if (productId) {
      (async () => {
        dispatch(contentLoading());
        const res = await getProduct(productId);
        setProduct(res);
        dispatch(contentLoaded());
      })();
    }
  }, [productId]);

  const createOrder = (data, actions) => {
    return actions.order.create({
      purchase_units: [
        {
          amount: {
            value: product.price * quantity,
          },
        },
      ],
    });
  };

  const makePayment = (currentOrder) => {
    (async () => {
      dispatch(contentLoading());
      const res = await payments(currentOrder);
      dispatch(contentLoaded());

      if (res.error) {
        if (res.error.message) {
          alert(res.error.message);
        }
      } else {
        history.push("/stores/transactions");
      }
    })();
  };

  const onApprove = (data, actions) => {
    const updatedOrder = { ...order, id: data.orderID };
    setOrder(updatedOrder);
    return makePayment(updatedOrder);
  };

  return (
    <Card>
      <Card.Header>
        <Card.Title>{product?.name}</Card.Title>
        <Card.Subtitle>{product?.description ?? ""}</Card.Subtitle>
        <Button className="pl-0" variant="link" onClick={() => history.goBack()}>
          Per tornare all'elenco clicca qui
        </Button>
      </Card.Header>
      <Card.Body>
        <Container fluid>
          <Row>
            <Col>
              {areaId != null ? (
                <div>
                  Area selezionata: <b>{areaName}</b>
                </div>
              ) : (
                ""
              )}
              {dateStart != null ? (
                <div>
                  Periodo: <b>{formatDayAndMonth(dateStart)}</b> -{" "}
                  <b>{formatDayAndMonth(dateEnd)}</b>
                </div>
              ) : (
                ""
              )}
              <hr></hr>
              <small>Prezzo iva inclusa</small>
              <h2> € {product ? product.price * quantity : ""} </h2>
            </Col>
          </Row>
          <Row>
            <Col>
              <PayPalScriptProvider
                options={{
                  currency: "EUR",
                  "client-id":
                    "AQZ-pD_jZ1dkJFD0Rkfrfeeq5iGP45qQYFf7r6ylkg7AAqGMxCjPIhgdV1TZFIeHmhvwGT4-b_11cAka",
                }}
              >
                <PayPalButtons
                  style={{ layout: "vertical" }}
                  createOrder={(data, actions) => createOrder(data, actions)}
                  onApprove={(data, actions) => onApprove(data, actions)}
                />
              </PayPalScriptProvider>
            </Col>
          </Row>
        </Container>
      </Card.Body>
    </Card>
  );
};

export default Checkout;
