import React, { useEffect, useState } from 'react'
import Dropzone from 'react-dropzone'
import { upload } from '../api/utils'

const ImageUploader = (props) => {
    const [image, setImage] = useState(props.initialImage || '')

    useEffect(() => {
        if (props.imageURL) {
            setImage(props.imageURL)
        }
    }, [props.imageURL])

    const dropHandler = dropped => {
        uploadFile(dropped[0])
    }

    const uploadFile = file => {
        (async () => {
            const response = await upload(file)
            if (response.error) {
                console.log('uploadFile', response)
                alert('Impossibile caricare il file')
                return
            }
            setImage(URL.createObjectURL(file))
            if (props.uploadedFileHandler) {
                props.uploadedFileHandler(response)
            }
        })()
    }

    return (
        <div style={{ border: "dotted 1px #cecece"}}>
            <Dropzone onDrop={dropHandler}>
                {({ getRootProps, getInputProps }) => (
                    <div {...getRootProps()} style={{ height: props.wrapperHeight || ''}} >
                        <img style={{ height: props.height || "350px", maxWidth: "250", border: "dotted 1px #4798e8" }} src={image} alt="" />
                        <input {...getInputProps()} />
                    </div>
                )}
            </Dropzone>
        </div>
    )
}

export default ImageUploader
