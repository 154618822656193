import React from "react";
import { useHistory } from "react-router-dom";
import { Button, Card, Col, Container, Row, Table } from "react-bootstrap";
import {
  formatDateYYYYMMDD,
  formatDateShort,
  formatTimeShort,
} from "../../utils";

const CalendarDetail = (props) => {
  const history = useHistory();
  const calendarEntrySelectedHandler = (item) => {
    history.push(`/deceased/${item.deceased.id}/edit`);
  };

  const calendarHeaderAndBody = () => {
    if (!props.weekInfo) return null;

    const cellHeaders = [];
    const cellBody = [];
    const start = props.weekInfo.startDate.clone();
    const end = props.weekInfo.endDate.clone().add(1, "days");
    while (!start.isSame(end)) {
      const key = formatDateYYYYMMDD(start);

      cellHeaders.push(<th key={"H" + key}>{formatDateShort(start)}</th>);

      const eventsInDay =
        props.events && props.events[key] ? props.events[key] : [];
      const eventEntries = eventsInDay.map((item) => {
        const eventType = props.eventTypes.find((et) => et.type === item.type);
        return (
          <Card
            className="shadow-none bg-primary text-white my-2 cursor-pointer"
            key={item.id}
            role="button"
            onClick={(e) => calendarEntrySelectedHandler(item)}
          >
            <Card.Header>
              <Card.Title>
                {item.deceased.firstname} {item.deceased.lastname}
              </Card.Title>
              <Card.Subtitle>{eventType.label}</Card.Subtitle>
            </Card.Header>
            <Card.Body>
              <Container>
                <Row>
                  <Col>
                    {item.location_name} <br />
                    ore {formatTimeShort(item.date)}
                  </Col>
                </Row>
              </Container>
            </Card.Body>
          </Card>
        );
      });

      // Notices
      const noticesInDay =
        props.notices && props.notices[key] ? props.notices[key] : [];
      const noticeEntries = noticesInDay.map((item) => {
        const eventType = props.eventTypes.find((et) => et.type === item.type);
        return (
          <Card
            className="shadow-none bg-warning my-2 cursor-pointer"
            key={item.id}
            variant="warning"
            role="button"
            onClick={(e) => calendarEntrySelectedHandler(item)}
          >
            <Card.Header>
              <Card.Title>
                {item.deceased.firstname} {item.deceased.lastname}
              </Card.Title>
              <Card.Subtitle>Notifica {eventType.label}</Card.Subtitle>
            </Card.Header>
            <Card.Body>
              <Container>
                <Row>
                  <Col>
                    <p>
                      {item.location_name} <br />
                      ore {formatTimeShort(item.date)}
                    </p>
                  </Col>
                </Row>
              </Container>
            </Card.Body>
          </Card>
        );
      });

      const cellEvent = (
        <td key={"B" + key}>
          {eventEntries}
          {noticeEntries}
        </td>
      );
      cellBody.push(cellEvent);

      start.add(1, "day");
    }

    return (
      <>
        <thead>
          <tr style={{ whiteSpace: "nowrap", textAlign: "center" }}>
            {cellHeaders}
          </tr>
        </thead>
        <tbody>
          <tr>{cellBody}</tr>
        </tbody>
      </>
    );
  };

  return (
    <Table bordered responsive>
      {calendarHeaderAndBody()}
    </Table>
  );
};

export default CalendarDetail;
