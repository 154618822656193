import React, { Component } from 'react'
import { EditorState, ContentState, convertToRaw, RichUtils, /*, convertFromHTML*/ } from 'draft-js'
import draftToHtml from 'draftjs-to-html'
import htmlToDraft from 'html-to-draftjs'
import { Editor } from 'react-draft-wysiwyg'
import '../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css'

class HTMLEditor extends Component {
    constructor(props) {
        super(props)

        this.state = {
            editorState: this.buildEditorStateFromHTML(props.value)
        }

        this.handleReturn = this.handleReturn.bind(this);
    }

    buildEditorStateFromHTML(html) {
        if (html) {
            // WARNING!!! don't use the draft-js's convertFromHTML function
            // const blocksFromHTML = convertFromHTML(html)
            const blocksFromHTML = htmlToDraft(html)
            const state = ContentState.createFromBlockArray(
                blocksFromHTML.contentBlocks,
                blocksFromHTML.entityMap
            );
            return EditorState.createWithContent(state)
        }
        return  EditorState.createEmpty()
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.value !== prevProps.value) {
            // const currentSelection = this.state.editorState.getSelection()
            const newState = this.buildEditorStateFromHTML(this.props.value)
            // const newStateWithSelection = EditorState.forceSelection(newState, currentSelection)
            
            this.setState({
                // editorState: newStateWithSelection
                editorState: newState
            })
        }
    }

    onEditorStateChange = (editorState) => {
        this.setState({
            editorState
        })
        // WARNING!!! Use the onBlur event to notify parent
        // if (this.props.valueChanged) {
        //     this.props.valueChanged(
        //         draftToHtml(convertToRaw(this.state.editorState.getCurrentContent()))
        //     )
        // }
    }

    toolbar = {
        options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'textAlign', /*'image', 'remove',*/ 'history'],
        inline: {
            inDropdown: false,
            options: ['bold', 'italic', 'underline', 'strikethrough']
        },
        blockType: {
            inDropdown: true,
            options: ['Normal', 'H1', 'H2', 'H3']
        },
        fontSize: {
            options: [8, 9, 10, 11, 12, 14, 16, 18, 24, 30, 36, 48, 60, 72, 96]
        },
        fontFamily: {
            options: ['Arial', 'Georgia', 'Impact', 'Tahoma', 'Times New Roman', 'Verdana']
        },
        textAlign: {
            inDropdown: false,
            options: ['left', 'center', 'right', 'justify']
        },
        // image: {
        //     urlEnabled: false,
        //     uploadEnabled: true,
        //     alignmentEnabled: true,
        //     previewImage: false,
        //     inputAccept: 'image/gif,image/jpeg,image/jpg,image/png,image/svg',
        //     alt: { present: false, mandatory: false },
        //     defaultSize: {
        //         height: 'auto',
        //         width: 'auto',
        //     }
        // },
        history: {
            inDropdown: false,
            options: ['undo', 'redo']
        }
    }

    // Using BR instead of P give errors when settings the H1 tag
    handleReturn(e, _editorState) {
        if (e.nativeEvent.shiftKey) {
            const { editorState } = this.state;
            this.setState({ editorState: RichUtils.insertSoftNewline(editorState) });
            return true
        }
        return false
    }

    render() {
        const { editorState } = this.state
        return (
            <Editor
                editorState={editorState}
                onEditorStateChange={this.onEditorStateChange}
                onBlur={e => {
                    this.props.valueChanged(
                        draftToHtml(convertToRaw(this.state.editorState.getCurrentContent()))
                    )
                }}
                handleReturn={this.handleReturn}
                toolbar={this.toolbar} 
                wrapperClassName={this.props.className}
                editorStyle={{ border: "solid 1px #cecece", height: "90%"}}
                localization={{
                    locale: 'it',
                }} />
        )
    }
}

export default HTMLEditor
