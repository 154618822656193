import React from "react";
import { Card, Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import AuthBg from "../../assets/images/background/auth-bg.jpg";

const Onboarding = (props) => {
  const RegisterButton = () => {
    return (
      <div>
        Non hai un account? &nbsp;
        <Link to="/signup">
          <b>Registrati</b>
        </Link>
      </div>
    );
  };

  const ForgetPassword = () => {
    return (
      <div>
        Hai dimenticato la password? &nbsp;
        <Link to="/reset_password">
          <b>Imposta una nuova password</b>
        </Link>
      </div>
    );
  };

  const AlreadyAnAccount = () => {
    return (
      <div>
        Hai già un account? &nbsp;
        <Link to="/signin">
          <b>Esegui l'accesso</b>
        </Link>
      </div>
    );
  };

  const renderLinks = (param) => {
    switch (param) {
      case "login":
        return (
          <div>
            <RegisterButton /> <ForgetPassword />
          </div>
        );
      case "signup":
        return (
          <div>
            <AlreadyAnAccount />
          </div>
        );
      case "reset-password":
        return (
          <div>
            <RegisterButton /> <AlreadyAnAccount />
          </div>
        );
      default:
        return "";
    }
  };

  return (
    <Container
      id="onboarding-wrapper"
      className="d-flex align-items-center justify-content-center"
      style={{
        height: "calc(100vh - 80px)",
        background: `url(${AuthBg}) no-repeat center center`,
      }}
    >
      <Card>
        <Card.Header>
          <Card.Title>{props.title}</Card.Title>
          <Card.Subtitle>{props.body}</Card.Subtitle>
        </Card.Header>
        <Card.Body>
          <Container fluid>{props.children}</Container>
        </Card.Body>
        <Card.Footer>{renderLinks(props.context)}</Card.Footer>
      </Card>
    </Container>
  );
};

export default Onboarding;
