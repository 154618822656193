import React from 'react'
import { useParams } from 'react-router-dom'
import { previewPosterUrl } from '../../api/posters'

const PosterPreview = () => {
    const {deceasedId, posterId} = useParams()
    const posterUrl = () => previewPosterUrl({id: deceasedId}, {id: posterId})

    return (
        <>
            <img src={ posterUrl() } style={{width: "100%"}} />
        </>
    )
}

export default PosterPreview
