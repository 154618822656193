import { faEdit, faEye, faEyeSlash, faLock, faLockOpen, faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FormEvent, FormEventHandler, MouseEvent, useEffect, useState } from "react";
import { Button, Card, Col, Form, Modal, Row, Table, Container } from "react-bootstrap";
import { createProduct, deleteProduct, editProduct, listProducts } from "../../api/products";
import { PosterType, Product } from "../../api/types";
import GoBack from "../../components/GoBack";

const getCategoryDefinition = (category: string) => {
    switch (category) {
        case "P":
            return "Manifesto";
        case "A":
            return "Pubblicità";
        case "S":
            return "Generico";
        default:
            return "N\\A";
    }
}

/**
 * @TODO handle errors displaying them on the forms.
 * @TODO handle edit feature (front-end sugar for hide/create)
 * @returns 
 */
export default function Products() {
    const [products, setProducts] = useState<Product[] | null>(null);
    const [filteredProducts, setFilteredProducts] = useState<Product[] | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);
    const [showHideModal, setShowHideModal] = useState<boolean>(false);
    const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
    const [showCreateModal, setShowCreateModal] = useState<boolean>(false);
    const [product, setProduct] = useState<Product | null>(null);
    const [showHiddenElements, setShowHiddenElements] = useState<boolean>(true);

    const fetchProducts = async () => {
        const productsResponse = await listProducts();
        if ('error' in productsResponse) {
            setError("C'è stato un errore nel richiedere i prodotti")
        } else {
            setProducts(productsResponse)
            setFilteredProducts(productsResponse)
        }
        setLoading(false);
    }

    useEffect(() => {
        if (!products) fetchProducts();
    }, [])

    const handleHideBtnClick = (product: Product) => {
        setShowHideModal(true);
        setProduct(product)
    }

    const handleHideProduct = async (product: Product) => {
        const response = await editProduct(product.id, { "hidden": !product.hidden });
        if ('error' in response) {
        }
        else {
            fetchProducts();
        }
        setShowHideModal(false);
    }

    const handleDeleteProduct = async (product: Product) => {
        const response = await deleteProduct(product);
        if ('error' in response) {
        }
        else {
            fetchProducts();
        }
        setShowDeleteModal(false);
    }

    const handleCreateProduct = async (event: FormEvent<Element>) => {
        const formData = new FormData(event.currentTarget as HTMLFormElement);

        // @TODO
        // Sinceramente non posso credere che per una multiselect ci sia il bisogno
        // di dover trattare "manualmente" il FormData.
        // Per ora non ho trovato una soluzione migliore

        const product: Partial<Product> = {};
        product.name = formData.get("name") as string;
        product.description = formData.get("description") as string;
        product.price = parseFloat(formData.get("price") as string);
        product.category = formData.get("category") as any;
        product.vat = parseFloat(formData.get("vat") as string);
        product.posters_count = parseInt(formData.get("posters_count") as string);
        product.ads_position = formData.get("ads_position") as string;

        const posters_set: PosterType[] = [];
        if (formData.get("enable_F")) posters_set.push("F");
        if (formData.get("enable_FT")) posters_set.push("FT");
        if (formData.get("enable_T")) posters_set.push("T");
        if (formData.get("enable_TT")) posters_set.push("TT");
        if (formData.get("enable_R")) posters_set.push("R");
        if (formData.get("enable_RT")) posters_set.push("RT");
        if (formData.get("enable_RC")) posters_set.push("RC");
        if (formData.get("enable_O")) posters_set.push("O");
        product.posters_set = posters_set;

        const response = await createProduct(product as Omit<Product, "id">);
        if ('error' in response) {

        }
        else {
            setShowCreateModal(false);
            fetchProducts();
        }
    }

    const handleSearch: React.ChangeEventHandler<any> = (event) => {
        setFilteredProducts(products?.filter(product => {
            return JSON.stringify(product).includes(event.target.value)
        }) ?? [])
    }

    const handleDeleteBtnClick = async (product: Product) => {
        setProduct(product);
        setShowDeleteModal(true);
    }

    const handleShowHiddenProducts: React.ChangeEventHandler<HTMLInputElement> = (event) => {
        setShowHiddenElements(event.currentTarget.checked);
    }

    return (
        <Card>
            {product && <HideProductModal show={showHideModal} product={product} onConfirm={handleHideProduct} onDismiss={() => setShowHideModal(false)} />}
            {product && <DeleteProductModal show={showDeleteModal} product={product} onConfirm={handleDeleteProduct} onDismiss={() => setShowHideModal(false)} />}
            <ProductFormModal title="Crea un nuovo prodotto" actionName="Crea" show={showCreateModal} onSubmit={handleCreateProduct} onCancel={() => setShowCreateModal(false)} preventDefault />
            <Card.Header>
                <Card.Title><GoBack /> Prodotti</Card.Title>
            </Card.Header>
            <Card.Body className="px-3">
                {loading && <p>Sto caricando i prodotti</p>}
                <Container>
                    <Row className="justify-content-center align-items-center">
                        <Col>
                            <Form.Control name="search" onChange={handleSearch} placeholder="Ricerca prodotti" />
                            <Form.Label className="my-1">
                                <small className="d-inline-flex align-items-center">
                                    <Form.Check name="hidden_products" onChange={handleShowHiddenProducts} placeholder="Ricerca prodotti" defaultChecked={true} />
                                    Mostra i prodotti nascosti
                                </small>
                            </Form.Label>
                        </Col>
                        <Col>
                            <Button className="float-right m-2" onClick={() => setShowCreateModal(true)}><FontAwesomeIcon className="mr-1" icon={faPlus} role="img" />Aggiungi</Button>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Table responsive className="w-100">
                                <thead>
                                    <tr>
                                        <th>Nome</th>
                                        <th>Descrizione</th>
                                        <th>Categoria</th>
                                        <th>Prezzo</th>
                                        <th aria-label="Azioni"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        filteredProducts?.filter(product => {
                                            if (showHiddenElements) return product;
                                            return !product.hidden;
                                        })
                                            .map(product => {
                                                return (
                                                    <tr key={product.id} className={`${product.hidden ? 'opacity-50' : ''}`}>
                                                        <td>{product.name}</td>
                                                        <td>{product.description}</td>
                                                        <td>{getCategoryDefinition(product.category)}</td>
                                                        <td><strong>€{product.price}</strong></td>
                                                        <td className="d-flex">
                                                            {!product.hidden ? <Button variant="secondary" color="error" onClick={event => handleHideBtnClick(product)}><FontAwesomeIcon icon={faEye} title='nascondi prodotto' /></Button> : null}
                                                            {product.hidden ? <Button variant="danger" color="error" onClick={event => handleHideBtnClick(product)}><FontAwesomeIcon icon={faEyeSlash} title='abilita prodotto' /></Button> : null}
                                                            {product.can_delete ? <Button className="mx-2" variant="danger" color="error" onClick={event => handleDeleteBtnClick(product)}><FontAwesomeIcon icon={faTrash} title='elimina prodotto' /></Button> : null}
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                    }
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                </Container>
            </Card.Body>
        </Card>
    )
}

interface DeleteProductModalProps {
    show: boolean,
    product: Product,
    onConfirm: (product: Product, event: MouseEvent) => any
    onDismiss: (product: Product, event: MouseEvent) => any
}

function HideProductModal({ show, product, onConfirm, onDismiss }: DeleteProductModalProps) {
    return (
        <Modal show={show} centered>
            <Modal.Header>
                <Modal.Title>Attenzione</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                Sei sicuro di voler {product.hidden ? 'mostrare' : 'nascondere'} il prodotto: {product?.name}?
            </Modal.Body>
            <Modal.Footer>
                <Button variant="link" onClick={(event) => onDismiss(product, event)}>Annulla</Button>
                <Button variant="danger" onClick={(event) => onConfirm(product, event)}>Sì, {product.hidden ? 'mostralo' : 'nascondilo'}.</Button>
            </Modal.Footer>
        </Modal>
    )
}

function DeleteProductModal({ show, product, onConfirm, onDismiss }: DeleteProductModalProps) {
    return (
        <Modal show={show} centered>
            <Modal.Header>
                <Modal.Title>Attenzione</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                Sei sicuro di voler eliminare il prodotto: {product?.name}? Questa azione è irreversibile.
            </Modal.Body>
            <Modal.Footer>
                <Button variant="link" onClick={(event) => onDismiss(product, event)}>Annulla</Button>
                <Button variant="danger" onClick={(event) => onConfirm(product, event)}>Sì, eliminalo.</Button>
            </Modal.Footer>
        </Modal>
    )
}

interface ProductFormModalProps {
    title: string
    actionName: string
    show: boolean
    onSubmit: (event: FormEvent, id?: string | number) => any
    onCancel: (event: MouseEvent) => any
    product?: Product
    preventDefault?: boolean
}

function ProductFormModal({ title, show, onSubmit, onCancel, product, preventDefault, actionName }: ProductFormModalProps) {
    const [category, setCategory] = useState<string>('P');

    const handleSubmit: FormEventHandler<HTMLFormElement> = (event) => {
        if (preventDefault) event.preventDefault();
        onSubmit(event, product?.id);
    }

    const handleChange: FormEventHandler<HTMLFormElement> = (event) => {
        const formData = new FormData(event.currentTarget);
        const category = formData.get("category") as string;
        if (['P', 'S', 'A'].includes(category)) {
            setCategory(category);
        }
    }

    return (
        <Modal show={show} centered>
            <Modal.Header>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body className="overflow-auto" style={{ maxHeight: "70vh" }}>
                <Form id="product-form" name="product-form" onSubmit={handleSubmit} onChange={handleChange}>
                    <Form.Group controlId="category" >
                        <Form.Label>Categoria</Form.Label>
                        <Form.Control name="category" as="select" required defaultValue={product?.category}>
                            <option value="P">Manifesti</option>
                            <option value="A">Pubblicità</option>
                            <option value="S">Prodotti</option>
                        </Form.Control>
                    </Form.Group>
                    <Form.Group controlId="name">
                        <Form.Label>Nome</Form.Label>
                        <Form.Control name="name" required defaultValue={product?.name} />
                    </Form.Group>
                    <Form.Group controlId="description" >
                        <Form.Label>Descrizione</Form.Label>
                        <Form.Control name="description" required defaultValue={product?.description} />
                    </Form.Group>
                    <Form.Group controlId="price" >
                        <Form.Label>Prezzo (€)</Form.Label>
                        <Form.Control name="price" type="number" required min="0" defaultValue={product?.price} />
                    </Form.Group>
                    <Form.Group controlId="vat" >
                        <Form.Label>Tasse (%)</Form.Label>
                        <Form.Control name="vat" type="number" required min="0" step="0.01" max="1" defaultValue={product?.vat || 0.22} />
                    </Form.Group>
                    <Form.Group controlId="posters_count" >
                        <Form.Label>Quantità</Form.Label>
                        <Form.Control name="posters_count" type="number" required min="0" defaultValue={product?.posters_count} />
                    </Form.Group>
                    {
                        category === 'P' && (
                            <Form.Group controlId="posters_set">
                                <Form.Label>Tipi di manifesto abilitati per il set</Form.Label>
                                <Form.Check type="checkbox" name="enable_F" label="Funerale"></Form.Check>
                                <Form.Check type="checkbox" name="enable_FT" label="Ringraziamento funerale"></Form.Check>
                                <Form.Check type="checkbox" name="enable_T" label="Trigesimo"></Form.Check>
                                <Form.Check type="checkbox" name="enable_TT" label="Ringraziamento trigesimo"></Form.Check>
                                <Form.Check type="checkbox" name="enable_R" label="Suffragio"></Form.Check>
                                <Form.Check type="checkbox" name="enable_RT" label="Ringraziamento suffragio"></Form.Check>
                                <Form.Check type="checkbox" name="enable_RC" label="Ricordino"></Form.Check>
                                <Form.Check type="checkbox" name="enable_O" label="Altro"></Form.Check>
                            </Form.Group>
                        )
                    }
                    {
                        category === 'A' && (
                            <Form.Group>
                                <Form.Label>Seleziona la posizione ADS</Form.Label>
                                <Form.Control type="number" name="ads_position" min="0" max="10"></Form.Control>
                            </Form.Group>
                        )
                    }
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button type="submit" form="product-form">{actionName}</Button>
                <Button variant="link-secondary" type="button" onClick={onCancel}>Esci</Button>
            </Modal.Footer>
        </Modal >
    )
}