import React, { useEffect, useState } from "react";
import { Card, ListGroup } from "react-bootstrap";
import { transactionInfoGrouped, wallet } from "../../api/payments";

const SupplierWallet = (props) => {
  const [data, setData] = useState();

  useEffect(() => {
    if (props.supplier && props.supplier.id) {
      (async () => {
        // const res = await wallet(props.supplier.user_id)
        const res = await transactionInfoGrouped(props.supplier.user_id);
        setData(res);
      })();
    }
  }, [props.supplier]);

  const getBadge = (item) => {
    const totProducts = parseInt(item.tot_products);
    const totUsed = parseInt(item.used_products);
    const className = totProducts > totUsed ? "info" : "warning";
    return (
      <span className={`badge badge-${className} float-right`}>
        Usati {totUsed} su {totProducts}
      </span>
    );
  };

  // const getBadge = item => {
  //     const totPurchased = parseInt(item.total_purchased)
  //     const totSpent = parseInt(item.total_consumed)
  //     const className = (totPurchased > totSpent) ? 'info' : 'warning'
  //     return (
  //         <span className={`badge badge-${className} float-right`}>
  //             Usati {totSpent} su {totPurchased}
  //         </span>
  //     )
  // }

  if (!data || data?.length === 0) {
    return <h6>Nessun dato da mostrare</h6>;
  } else
    return (
      <Card>
        <Card.Body>
          {data.map((item, ix) => (
            <ListGroup key={`key${ix}`}>
              <ListGroup.Item>
                <i></i>
                {item.product_name}
                {getBadge(item)}
              </ListGroup.Item>
            </ListGroup>
          ))}
        </Card.Body>
      </Card>
    );
};

export default SupplierWallet;
