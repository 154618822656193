import React, { useState } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Dropdown,
  ListGroup,
  ButtonGroup,
} from "react-bootstrap";
import DeceasedEventModal from "./DeceasedEventModal";
import DeceasedPostersList from "./DeceasedPostersList";
import DeceasedPosterTypeModal from "./DeceasedPosterTypeModal";
import DeceasedPosterModal from "./DeceasedPosterModal";
import moment from "moment/min/moment-with-locales";
import { deleteEvent } from "../../api/events";
import {
  deletePoster,
  approvePoster,
  rejectPoster,
  downloadPoster,
} from "../../api/posters";
import { hasCreditFor } from "../../api/payments";
import {
  formatDateISO8601,
  formatDateLong,
  formatTimeShort,
} from "../../utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";

const emptyEvent = {
  id: null,
  event_id: null,
  event: null,
  type: null,
  date: null,
  notice_date: null,
  thanksgiving_poster: null,
  location_name: null,
  location_address: null,
  location_city: null,
  location_prov: null,
  posters: [],
};

const emptyPoster = {
  id: null,
  text: "",
  board_ids: [],
};

const DeceasedEvents = (props) => {
  // #region Event modal

  const [event, setEvent] = useState(null);

  const calculateDate = (eventType, fromDate) => {
    const updatedDate = fromDate.add(eventType.event_units, eventType.event_um);
    return formatDateISO8601(updatedDate);
  };

  const eventTypeDisabled = (item) => {
    // Funeral or Thirtieth
    const notDuplicableItems = ["F", "T"];
    if (notDuplicableItems.indexOf(item.type) > -1) {
      return props.deceased.events?.find((i) => i.type === item.type);
    }
    return false;
  };

  const openEventModalHandler = (eventType) => {
    const newEvent = { ...emptyEvent };

    newEvent.deceased_id = props.deceased.id;
    newEvent.type = eventType.type;
    newEvent.thanksgiving_poster = eventType.thanksgiving_poster;
    if (props.deceased.death) {
      const deathDate = moment(props.deceased.death).utc(0);
      // Prefill event dates
      if (eventType.event_units) {
        newEvent.date = calculateDate(eventType, deathDate);
      }

      // Notice date is calculated according to the date of the event
      if (newEvent.date && eventType.notice_days) {
        newEvent.notice_date = formatDateISO8601(
          moment(newEvent.date).subtract(eventType.notice_days, "days")
        );
      }
    }
    setEvent(newEvent);
  };

  const saveEventHandler = (item) => {
    if (item) {
      props.onEventSaved(item);
    }
    setEvent(null);
  };

  const editEventHandler = (item) => {
    setEvent(item);
  };

  const deleteEventHandler = (item) => {
    (async () => {
      if (
        !window.confirm(
          "Eliminare in modo definitivo l'evento e tutti i manifesti associati?"
        )
      )
        return;
      const res = await deleteEvent(item);
      if (res.error) {
        alert("Si è verificato un errore");
        return;
      }
      props.eventDeletedHandler(res);
    })();
  };

  // #endregion Event modal

  // #region Poster modal

  const [posterTypeModalOpen, setPosterTypeModalOpen] = useState(false);
  const [poster, setPoster] = useState(null);

  const openPosterTypeModalHandler = (item) => {
    setSelectedEvent(item);
    setTimeout(() => setPosterTypeModalOpen(true), 400);
  };

  const posterTypeSelectedHandler = (posterType) => {
    setPosterTypeModalOpen(false);
    if (posterType) {
      checkCreditAndOpenPosterModal(posterType);
    } else {
      setTimeout(() => setSelectedEvent(null), 400);
    }
  };

  const checkCreditAndOpenPosterModal = (posterType) => {
    (async () => {
      const response = await hasCreditFor(props.deceased.id, posterType);
      if (response.usableTransaction) {
        console.log("User has credit");
        // Set poster type for the new poster
        const newPoster = {
          ...emptyPoster,
          poster_type: posterType,
          event: selectedEvent,
          event_id: selectedEvent.id,
        };
        setPoster(newPoster);
      } else {
        console.log("User doesn't have credit for the requested poster");
        alert(
          "Per poter aggiungere il manifesto è necessario prima acquistare del credito"
        );
      }
    })();
  };

  const savePosterHandler = (item) => {
    if (item) {
      props.onPosterSaved(item);
      updatePosterInSelectedEvent(item);
    }
    setPoster(null);
  };

  // #endregion Poster modal

  // #region Poster approve / reject

  const replacePosterInSelectedEvent = (item) => {
    const updatedPosters = [...selectedEvent.posters];
    const ix = updatedPosters.findIndex((element) => item.id === element.id);
    updatedPosters[ix] = item;
    const updatedSelectedEvent = { ...selectedEvent, posters: updatedPosters };
    setSelectedEvent(updatedSelectedEvent);
  };

  const approvePosterHandler = (item) => {
    if (
      !window.confirm(
        "ATTENZIONE, L'OPERAZIONE NON È REVERSIBILE.\nApprovando il manifesto, questo sarà pubblico a tutti gli utenti.\nProcedere con l'approvazione?"
      )
    )
      return;
    (async () => {
      const res = await approvePoster(props.deceased, item);
      if (res.error) {
        alert("Non è stato possibile approvare il poster");
        return;
      }
      props.onPosterSaved(res);
      replacePosterInSelectedEvent(res);
    })();
  };

  const rejectPosterHandler = (item) => {
    if (
      !window.confirm(
        "ATTENZIONE, L'OPERAZIONE NON È REVERSIBILE.\nRifiutando il manifesto, questo non sarà pubblicato sul sito.\nRifiutare il manifesto?"
      )
    )
      return;
    (async () => {
      const res = await rejectPoster(props.deceased, item);
      if (res.error) {
        alert("Non è stato possibile scartare il poster");
        return;
      }
      props.onPosterSaved(res);
      replacePosterInSelectedEvent(res);
    })();
  };

  // #endregion Poster approve / reject

  // #region Poster toolbar

  const editPosterHandler = (item) => {
    // setSelectedEvent(null)
    setTimeout(() => setPoster(item), 200);
  };

  const updatePosterInSelectedEvent = (item) => {
    const updatedPosters = [...selectedEvent.posters];
    const ix = updatedPosters.findIndex((element) => item.id === element.id);
    if (ix > -1) {
      updatedPosters[ix] = item;
    }
    const updatedSelectedEvent = { ...selectedEvent, posters: updatedPosters };
    setSelectedEvent(updatedSelectedEvent);
  };

  const removePosterFromSelectedEvent = (item) => {
    const updatedPosters = [...selectedEvent.posters];
    const ix = updatedPosters.findIndex((element) => item.id === element.id);
    if (ix > -1) {
      updatedPosters.splice(ix, 1);
    }
    const updatedSelectedEvent = { ...selectedEvent, posters: updatedPosters };
    setSelectedEvent(updatedSelectedEvent);
  };

  const deletePosterHandler = (item) => {
    (async () => {
      if (
        !window.confirm(
          "Confermi l'eliminazione del poster (" +
            item.id +
            ")in modo definitivo?"
        )
      )
        return;
      const res = deletePoster(props.deceased, item);
      if (res.error) {
        console.warn(res.error);
        alert("Si è verificato un errore durante l'eliminazione del manifesto");
        return;
      }
      props.onPosterDeleted(item);
      removePosterFromSelectedEvent(item);
    })();
  };

  const openPreviewPosterHandler = (item) => {
    const port = window.location.port ? `:${window.location.port}` : "";
    const previewUrl = `${window.location.protocol}//${window.location.hostname}${port}/deceased/${props.deceased.id}/posters/${item.id}`;
    window.open(previewUrl, "_blank");
  };

  const downloadPosterHandler = (item) => {
    (async () => {
      // const url = `${process.env.REACT_APP_API_BASE_URL}/api/deceased/${props.deceased.id}/posters/${item.id}/export`
      // Download using api
      const file = await downloadPoster(
        { id: props.deceased.id },
        { id: item.id }
      );
      const url = URL.createObjectURL(file);
      window.open(url);
    })();
  };

  // #endregion Poster toolbar

  // #region Posters List Modal

  const [selectedEvent, setSelectedEvent] = useState();
  const openPostersListModal = (eventItem) => {
    setSelectedEvent(eventItem);
  };

  // Used to close the posters list modal when item parameter is null
  const posterSelectedHandler = (item) => {
    setSelectedEvent(null);
    if (item) {
      // Do something with the poster that user has selected
    }
  };

  // #endregion Posters List Modal

  const postersButtonFromEvent = (item) => {
    if (item) {
      const totPosters = item.posters.length;
      if (totPosters > 0) {
        const postersToBeApproved = item.posters.filter(
          (element) => element.state === "P"
        ).length;
        let buttonText;
        switch (postersToBeApproved) {
          case 0:
            buttonText =
              totPosters > 1 ? `${totPosters} manifesti` : `1 manifesto`;
            break;
          case 1:
            buttonText = `${postersToBeApproved} manifesto da approvare`;
            break;
          default:
            buttonText = `${postersToBeApproved} manifesti da approvare`;
            break;
        }
        return (
          <Button
            variant={postersToBeApproved > 0 ? "danger" : "info"}
            style={{ textAlign: "left" }}
            title="Mostra tutti i manifesti dell'evento"
            onClick={(e) => openPostersListModal(item)}
          >
            <i className="ti-layout-grid3 mr-3"></i>
            {buttonText}
          </Button>
        );
      }
    }
  };

  return (
    <>
      <DeceasedEventModal event={event} onEventSaved={saveEventHandler} />
      <DeceasedPostersList
        posterTypes={props.posterTypes}
        selectedEvent={selectedEvent}
        onPosterSelected={posterSelectedHandler}
        onEditPoster={editPosterHandler}
        onDeletePoster={deletePosterHandler}
        onPosterPreview={openPreviewPosterHandler}
        onPosterDownload={downloadPosterHandler}
        onApprovePoster={approvePosterHandler}
        onRejectPoster={rejectPosterHandler}
      />
      <DeceasedPosterTypeModal
        posterTypes={props.posterTypes}
        selectedEvent={selectedEvent}
        posterTypeModalOpen={posterTypeModalOpen}
        onPosterTypeSelected={posterTypeSelectedHandler}
      />
      <DeceasedPosterModal
        poster={poster}
        onPosterSaved={savePosterHandler}
        deceased={props.deceased}
      />
      <div>
        <Dropdown>
          <Dropdown.Toggle
            variant="primary"
            id="add-event-button"
            title="Aggiungi un nuovo evento"
          >
            Aggiungi Evento
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {props.eventTypes?.map((item) => (
              <Dropdown.Item
                disabled={eventTypeDisabled(item)}
                key={item.type}
                onClick={(e) => openEventModalHandler(item)}
              >
                {item.label}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>

        {props.deceased.events ? (
          <ListGroup>
            {props.deceased.events.map((item) => (
              <ListGroup.Item key={item.id}>
                <Container>
                  <Row className="align-items-center">
                    <Col xs={12} md={4}>
                      <h5>
                        {
                          props.eventTypes.find((ev) => ev.type === item.type)
                            .label
                        }
                      </h5>
                      <small>{formatDateLong(item.date)}</small>
                      <br />
                      <small>Ore {formatTimeShort(item.date)}</small>
                    </Col>
                    <Col xs={12} md={4}>
                      <h5>{item.location_name}</h5>
                      <small>{item.location_address}</small>
                      <br />
                      <small className="text-muted">
                        {item.location_city} {item.location_prov}
                      </small>
                    </Col>
                    <Col xs={12} md={4}>
                      <ListGroup>
                        <ListGroup.Item
                          className="text-right py-1"
                          key={`${item.id}_posters`}
                        >
                          <Button
                            variant="link"
                            onClick={(e) => deleteEventHandler(item)}
                            title="Elimina l'evento"
                          >
                            <FontAwesomeIcon icon={faTrash} /> Elimina l'evento
                          </Button>
                        </ListGroup.Item>
                        <ListGroup.Item
                          className="text-right py-0"
                          key={`${item.id}_posters`}
                        >
                          <Button
                            variant="link"
                            onClick={(e) => editEventHandler(item)}
                            title="Modifica l'evento"
                          >
                            <FontAwesomeIcon icon={faEdit} /> Modifica l'evento
                          </Button>
                        </ListGroup.Item>
                        <ListGroup.Item
                          className="text-right py-1"
                          key={`${item.id}_posters`}
                        >
                          {postersButtonFromEvent(item)}
                        </ListGroup.Item>
                        <ListGroup.Item
                          className="text-right py-0"
                          key={`${item.id}_add`}
                        >
                          <Button
                            variant="secondary"
                            title="Aggiungi un nuovo manifesto"
                            onClick={(e) => openPosterTypeModalHandler(item)}
                          >
                            <FontAwesomeIcon icon={faPlus} className="mr-2" />
                            Aggiungi&nbsp;manifesto
                          </Button>
                        </ListGroup.Item>
                      </ListGroup>
                    </Col>
                  </Row>
                </Container>
              </ListGroup.Item>
            ))}
          </ListGroup>
        ) : null}
      </div>
    </>
  );
};

export default DeceasedEvents;
