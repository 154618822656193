import React, { useContext, useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { Container, Tab, Tabs, Row, Col, Button, Card } from "react-bootstrap";
import UserContext from "../../UserContext";
import { getSupplier } from "../../api/suppliers";
import BaseLayout from "../layouts/BaseLayout";
import SupplierForm from "../suppliers/SupplierForm";
import SupplierWallet from "../suppliers/SupplierWallet";
import GoBack from "../../components/GoBack";

const SupplierEditor = (props) => {
  const { state } = useContext(UserContext);
  const history = useHistory();
  const { supplierId } = useParams();
  const [supplier, setSupplier] = useState({
    id: null,
    name: "",
    city: null,
    prov: null,
    email: "",
  });
  const [transactionButtonVisible, setTransactionButtonVisible] =
    useState(false);

  useEffect(() => {
    if (props.supplier) {
      console.log(`Setting supplier with id ${props.supplier.id}`);
      setSupplier(props.supplier);
    } else if (supplierId > 0) {
      (async () => {
        const res = await getSupplier(supplierId);
        setSupplier(res);
      })();
    }
  }, []);

  const savedSupplierHandler = (updatedSupplier) => {
    setSupplier(updatedSupplier);
  };

  return (
    <Card>
      <Card.Header>
        <Card.Title>
          <GoBack />
          {supplier.id ? "Modifica fornitore" : "Nuovo fornitore"}
        </Card.Title>
        {transactionButtonVisible ? (
          <div style={{ float: "right" }}>
            <Button
              variant="secondary"
              onClick={() => history.push("/stores/transactions")}
            >
              I tuoi acquisti
            </Button>
          </div>
        ) : null}
      </Card.Header>
      <Card.Body>
        <Row>
          <Col xs={12} lg={8}>
            <h4></h4>
          </Col>
        </Row>

        <Container fluid>
          <Row>
            <Col>
              <Tabs
                defaultActiveKey="data"
                id="supplier-tabs"
                onSelect={(k) => setTransactionButtonVisible(k === "wallet")}
              >
                <Tab eventKey="data" title="Anagrafica">
                  <Container fluid className="mt-3">
                    <SupplierForm
                      supplier={supplier}
                      onSupplierSaved={savedSupplierHandler}
                    />
                  </Container>
                </Tab>
                <Tab eventKey="wallet" title="Portafoglio Ads">
                  <Container fluid className="mt-3">
                    <SupplierWallet supplier={supplier} />
                  </Container>
                </Tab>
              </Tabs>
            </Col>
          </Row>
        </Container>
      </Card.Body>
    </Card>
  );
};

export default SupplierEditor;
