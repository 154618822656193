import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import { InputGroup, Row, Col, Form, Button, Container } from "react-bootstrap";
import UserContext from "../../UserContext";
import { userLoggedIn } from "../../reducers/actions";
import * as auth from "../../api/auth";
import Onboarding from "../layouts/Onboarding";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faKey, faUser } from "@fortawesome/free-solid-svg-icons";

const SignIn = () => {
  const { dispatch } = useContext(UserContext);
  const history = useHistory();
  // TODO Remove email and password
  const [email, setEmail] = useState(
    process.env.NODE_ENV === "development" ? "agency1@email.com" : ""
  );
  const [password, setPassword] = useState(
    process.env.NODE_ENV === "development" ? "12345678" : ""
  );
  const [isLoggingIn, setIsLoggingIn] = useState(false);
  const handleSignIn = (e) => {
    e.preventDefault();
    setIsLoggingIn(true);
  };
  useEffect(() => {
    if (isLoggingIn) {
      (async () => {
        const res = await auth.signIn(email, password);
        setIsLoggingIn(false);
        if (res.token) {
          dispatch(userLoggedIn(res.user));
          history.push("/");
        } else {
          console.log("Signin error ", res.error);
          if (res.status === 401 && res.error['user-status'] === 'P') {
            history.push("/waiting_approval");
            return;
          }
          if (res && res.error) {
            if (Array.isArray(res.error)) {
              alert(
                Array(res.error).map((obj) => {
                  return obj[Object.keys(obj)[0]];
                })
              ).join("\n");
            } else {
              alert(res.error.message);
            }
          }
        }
      })();
    }
  }, [isLoggingIn]);

  return (
    <Onboarding title="Login" body="" context="login">
      <Row>
        <Col xs={12}>
          <Form onSubmit={handleSignIn}>
            <InputGroup>
              <InputGroup.Prepend>
                <InputGroup.Text id="basic-addon1">
                  <FontAwesomeIcon icon={faUser} />
                </InputGroup.Text>
              </InputGroup.Prepend>
              <Form.Control
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Email"
                required
              />
            </InputGroup>
            <InputGroup className="my-2">
              <InputGroup.Prepend>
                <InputGroup.Text id="basic-addon1">
                  <FontAwesomeIcon icon={faKey} />
                </InputGroup.Text>
              </InputGroup.Prepend>
              <Form.Control
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Password"
              />
            </InputGroup>
            <Form.Group>
              <Button variant="primary" type="submit" disabled={isLoggingIn}>
                Login
              </Button>
            </Form.Group>
          </Form>
        </Col>
      </Row>
    </Onboarding>
  );
};

export default SignIn;
