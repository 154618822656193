import { baseUrl, options, formatError } from './common'
import axios from 'axios'

const hasCreditFor = async (deceasedId, posterType) => {
    try {
        const url = `${baseUrl}/api/transactions/has-credit-for?deceased_id=${deceasedId}&poster_type=${posterType}`
        const response = await axios.get(url, options())
        return response.data
    } catch (err) {
        return formatError(err)
    }
}

const payments = async (order) => {
    try {
        var params = {
            order_id: order.id,
            product_id: order.product_id,
            area_id: order.area_id,
            date_start: order.date_start,
            date_end: order.date_end,
            quantity: order.quantity
        }

        const response = await axios.post(`${baseUrl}/api/transactions`, params, options())
        return response.data
    } catch (err) {
        return formatError(err)
    }
}

const listTransactions = async (name = '', notCompleted = false, pageIx) => {
    try {
        const notCompletedValue = notCompleted ? 1 : 0
        const url = `${baseUrl}/api/transactions?name=${name}&page=${pageIx}&notconfirmed=${notCompletedValue}`
        const response = await axios.get(url, options())
        return response.data
    } catch (err) {
        return formatError(err)
    }
}

// Deprecated: use transactionInfoGrouped / transactionInfoDetails
const wallet = async userId => {
    try {
        const url = `${baseUrl}/api/transactions/wallet?user_id=${userId}`
        const response = await axios.get(url, options())
        return response.data
    } catch (err) {
        return formatError(err)
    }
}


const transactionInfoGrouped = async userId => {
    try {
        const url = `${baseUrl}/api/transactions/info/grouped?user_id=${userId}`
        const response = await axios.get(url, options())
        return response.data
    } catch (err) {
        return formatError(err)
    }
}

const transactionInfoDetails = async () => {
    try {
        const url = `${baseUrl}/api/transactions/info/details`
        const response = await axios.get(url, options())
        return response.data
    } catch (err) {
        return formatError(err)
    }
}

const transactionInfo = async id => {
    try {
        const url = `${baseUrl}/api/transactions/info?id=${id}`
        const response = await axios.get(url, options())
        return response.data
    } catch (err) {
        return formatError(err)
    }
}



export { hasCreditFor, payments, listTransactions, wallet, transactionInfoGrouped, transactionInfoDetails, transactionInfo }
