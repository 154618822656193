import { useState } from 'react'

/*
How to use it

import useContinuousPagination from '../hooks/useContinuousPagination'

    const [loading, setLoading, pageNo, hasMorePages, 
           setHasMorePages, data, setData, loadNextPage] = useContinuousPagination()

    useEffect(() => {
        async function fetchData() {
            setLoading(true)
            const posters = await listPosters(pageNo)
            setLoading(false)
            setData([...data, ...posters.data])
            setHasMorePages(posters.current_page < posters.last_page)
        }
        fetchData()
    }, [pageNo])
*/

const useContinuousPagination = () => {
    const [loading, setLoading] = useState(false)
    const [pageNo, setPageNo] = useState(1)
    const [hasMorePages, setHasMorePages] = useState(false)
    const [data, setData] = useState([])

    const loadNextPage = () => {
        setPageNo(pageNo + 1)
    }

    return [loading, setLoading, 
            pageNo, setPageNo,
            hasMorePages, setHasMorePages,
            data, setData, 
            loadNextPage]
}

export default useContinuousPagination
