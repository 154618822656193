import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "react-bootstrap/esm/Button";
import propTypes from "prop-types";
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';

// list of available mail-types for this component
const TYPES = ["request-new-board"];

/**
 * Renders a link used to send a mail 
 * @param {*} props 
 * @returns 
 */
export default function MailSender({ text, type, mailDetails }) {
  const details = mergeMailDetails(
    getMailDetailsFromType(type),
    mailDetails
  );

  return (
    <Button variant="link" href={getMailDescriptor(details)}>
      <FontAwesomeIcon icon={faEnvelope} className="mr-1" />
      <span>{text}</span>
    </Button>
  );
}

MailSender.propTypes = {
  text: propTypes.string.isRequired,
  type: propTypes.oneOf(TYPES).isRequired,
  mailDetails: propTypes.shape({
    subject: propTypes.string,
    body: propTypes.string,
    to: propTypes.string,
    cc: propTypes.arrayOf(propTypes.string),
    bcc: propTypes.arrayOf(propTypes.string),
  }),
};

//
// Component Utilities
//

/**
 * Given a Mail Sender type, returns the relative Mail Details
 * @param {*} type
 * @returns
 */
function getMailDetailsFromType(type) {
  switch (type) {
    case "request-new-board":
      return {
        subject: `Richiesta di aggiunta di una nuova bacheca`,
        body: getMultilineText(['Spett.le RDM S.R.L.,', 'Richiedo l\'aggiunta di una bacheca per l\'area [INSERISCI L\'AREA]', 'Cordiali saluti,']),
        to: `bacheche@rdm.it`,
      };
    default:
      throw new Error(
        `${type} type for Mail Sender could not be found, you should use one of: ${TYPES.join(
          ", "
        )}`
      );
  }
}

/**
 * Get mailto string given a Mail Details object
 * @param {*} param0
 * @returns
 */
function getMailDescriptor({ subject, body, to, cc, bcc }) {
  // basic required informations
  const baseDescriptor = `mailto:${to}?subject=${subject}&body=${body}`;

  let descriptor = baseDescriptor;
  if (cc) {
    descriptor = descriptor.concat(`&cc=${cc.join(",")}`);
  }
  if (bcc) {
    descriptor = descriptor.concat(`&bcc=${bcc.join(",")}`);
  }

  return descriptor;
}

/**
 * Merge mailDetailsB into mailDetailsA
 * @param {*} mailDetailsA
 * @param {*} mailDetailsB
 * @returns
 */
function mergeMailDetails(
  mailDetailsA,
  mailDetailsB
) {
  return {
    subject: mailDetailsB?.subject ? mailDetailsB.subject : mailDetailsA.subject,
    body: mailDetailsB?.body ? mailDetailsB.body : mailDetailsA.body,
    to: mailDetailsB?.to ? mailDetailsB.to : mailDetailsA.to,
    cc: mailDetailsB?.cc ? mailDetailsB.cc : mailDetailsA.cc,
    bcc: mailDetailsB?.bccB ? mailDetailsB.bcc : mailDetailsA.bcc,
  };
}

/**
 * Get a string with html line braks, suitable for mailto:
 * @param {*} texts - array of texts, each element is on a single line
 * @returns string
 */
function getMultilineText(texts) {
    return texts.join('%0D%0A');
}