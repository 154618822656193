import { baseUrl, formatError, multiPartOptions } from './common'
import axios from 'axios'

const upload = async file => {
    try {
        const url = `${baseUrl}/upload`

        const formData = new FormData()
        formData.append('attachment', file)

        const response = await axios.post(url, formData, multiPartOptions())
        return response.data
    } catch (err) {
        return formatError(err)
    }
}

export { upload }