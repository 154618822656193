import React, { useContext, useEffect, useReducer, useState } from 'react'
import UserContext from '../../UserContext'
import { contentLoading, contentLoaded } from '../../reducers/actions'
import { getProfile } from '../../api/users'
import BaseLayout from '../layouts/BaseLayout'
import AgencyEditor from '../agencies/AgencyEditor'
import SupplierEditor from '../suppliers/SupplierEditor'
import CompanyType from '../../utils/CompanyType'

const ProfileEditor = (props) => {
    const {dispatch} = useContext(UserContext)
    const [entity, setEntity] = useState()

    useEffect(() => {
        (async () => {
            dispatch(contentLoading())
            const res = await getProfile()
            setEntity(res)
            dispatch(contentLoaded())
        })()
    }, [])

    return(
        (entity && entity.user) ? ((entity.user.role === CompanyType.agency) ?
            <AgencyEditor agency={entity}></AgencyEditor> :
            <SupplierEditor supplier={entity}></SupplierEditor>) : (
                <>
                    <div  style={{display: "block", paddingBottom: "50px"}}>
                        <div >
                            { entity !== undefined ? 
                                <>
                                    <h3>Profilo non esistente</h3>
                                    <p>
                                        Contatta l'amministratore di sistema
                                    </p>
                                </>
                                : null }
                        </div>
                    </div>
                </>
            )
    )
}

export default ProfileEditor