import React, { useEffect, useState } from "react";
import { useHistory, useParams, Link } from "react-router-dom";
import {
  Row,
  Col,
  Container,
  Form,
  Button,
  Collapse,
  Card,
} from "react-bootstrap";
import {
  listTemplateLayouts,
  getTemplate,
  saveTemplate,
  targetTypes,
} from "../../api/templates";
import { listPosterTypes } from "../../api/posters";
import BaseLayout from "../layouts/BaseLayout";
import ImageUploader from "../../components/ImageUploader";
import HTMLEditor from "../../components/editor/Editor";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";

const TemplateEditor = (props) => {
  const history = useHistory();
  const { templateId } = useParams();
  const [template, setTemplate] = useState({
    id: null,
    name: "",
    layout_name: "",
    text: "",
    poster_type: "F",
    target: "*",
  });
  const [layouts, setLayouts] = useState();
  const [posterTypes, setPosterTypes] = useState([]);
  const [validated, setValidated] = useState(false);
  const [error, setError] = useState({});
  const [placeholderOpen, setPlaholderOpen] = useState(false);

  useEffect(() => {
    (async () => {
      if (!layouts) {
        const resLayouts = await listTemplateLayouts();
        setLayouts(resLayouts);
      }
      const resPosterTypes = await listPosterTypes();
      setPosterTypes(resPosterTypes);
      if (templateId && templateId !== template.id) {
        const res = await getTemplate(templateId);
        const newTemplate = res;
        setTemplate(newTemplate);
      }
    })();
  }, [templateId]);

  const changeTemplateHandler = (e) => {
    const { name, value } = e.target;
    setTemplate({ ...template, [name]: value });
  };

  const saveTemplateHandler = (e) => {
    (async () => {
      e.preventDefault();
      e.stopPropagation();
      setValidated(true);

      if (!e.currentTarget.checkValidity()) return;

      const res = await saveTemplate(template);
      if (res.error) {
        setError(res.error);
        if (res.error.message) {
          alert(res.error.message);
        }
      } else {
        history.goBack();
      }
    })();
  };

  const setImage = (data) => {
    if (data) {
      setTemplate({
        ...template,
        attachment: {
          filename: data.filename,
          url: data.url,
        },
      });
    } else {
      if (template.attachment) {
        const updatedTemplate = { ...template };
        delete updatedTemplate.attachment;
        setTemplate(updatedTemplate);
      }
    }
  };

  const templateAttachmentImageUrl = (item) =>
    item?.attachment?.url || "/sample-template.png";

  const usablePlaceholders = () => {
    const placeholders = [
      { id: "{firstname}", title: "Nome di battesimo del defunto" },
      { id: "{lastname}", title: "Cognomde del defunto" },
      {
        id: "{death-date-wd}",
        title: "Giorno della data di decesso (es: Lunedì)",
      },
      { id: "{death-date-dmy}", title: "Data di decesso (es: 02-11-2020)" },
      {
        id: "{death-date-dM}",
        title: "Giorno e mese del decesso (es: 20 Novembre)",
      },
      {
        id: "{event-date-wd}",
        title: "Giorno della data dell'evento (es: Lunedì)",
      },
      { id: "{event-date-dmy}", title: "Data dell'evento (es: 02-11-2020)" },
      {
        id: "{event-date-dM}",
        title: "Giorno e mese dell'evento (es: 20 Novembre)",
      },
      { id: "{event-time}", title: "Ora dell'evento' (es: 15:30)" },
      { id: "{location-name}", title: "Nome del luogo dell'evento" },
      { id: "{location-address}", title: "Indirizzo del luogo dell'evento" },
      { id: "{location-city}", title: "Città del luogo dell'evento" },
      { id: "{location-prov}", title: "Provincia del luogo dell'evento" },
    ];
    return (
      <div>
        {placeholders.map((item) => (
          <span key={item.id} title={item.title}>
            {item.id}{" "}
          </span>
        ))}
      </div>
    );
  };

  return (
    <Card>
      <Card.Header>
        <Card.Title>
          <Link
            className="mr-3"
            to="/templates"
            style={{ color: "#000000", textDecoration: "none" }}
          >
            <FontAwesomeIcon icon={faArrowLeft} />
          </Link>
          {props.isNew ? "Nuovo manifesto" : "Modifica manifesto"}
        </Card.Title>
      </Card.Header>

      <Card.Body>
        <Container fluid>
          <Form noValidate validated={validated} onSubmit={saveTemplateHandler}>
            <Form.Group>
              <Form.Label>Nome</Form.Label>
              <Form.Control
                type="text"
                placeholder="Nome del template"
                name="name"
                value={template.name}
                onChange={changeTemplateHandler}
                maxLength="50"
                required
                isInvalid={error?.name}
              />
              <ErrorLabel errors={!template?.name || template.name === ""}>
                Inserici il nome del manifesto
              </ErrorLabel>
            </Form.Group>

            <Form.Group>
              <Form.Label>Target</Form.Label>
              <Form.Control
                as="select"
                name="target"
                value={template.target}
                onChange={changeTemplateHandler}
                custom
              >
                {Object.entries(targetTypes).map(([key, value]) => (
                  <option key={key} value={key}>
                    {value}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>

            <Form.Group>
              <Form.Label>Tipologia</Form.Label>
              <Form.Control
                as="select"
                name="poster_type"
                value={template.poster_type}
                onChange={changeTemplateHandler}
                custom
              >
                {Object.entries(posterTypes).map(([key, value]) => (
                  <option key={key} value={key}>
                    {value}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>

            <Form.Group>
              <Form.Label>Anteprima del manifesto</Form.Label>
              <ImageUploader
                height={"100px"}
                initialImage={templateAttachmentImageUrl(template)}
                imageURL={templateAttachmentImageUrl(template)}
                uploadedFileHandler={setImage}
              />
            </Form.Group>

            <Form.Group controlId="selectCustom">
              <Form.Label>Usa Layout</Form.Label>
              <Form.Control
                as="select"
                name="layout_name"
                value={template.layout_name}
                onChange={changeTemplateHandler}
                custom
              >
                {layouts?.map((item) => (
                  <option key={item.name} value={item.layout_name}>
                    {item.name}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>

            <div>
              <Button
                variant="info"
                aria-controls="placeholder-info"
                aria-expanded={placeholderOpen}
                onClick={() => setPlaholderOpen(!placeholderOpen)}
              >
                Segnaposto utilizzabili nell'editor
              </Button>
              <Collapse in={placeholderOpen}>
                <div id="placeholder-info">
                  <small>
                    I seguenti segnaposto possono essere utilizzati all'interno
                    del testo e verranno rimpiazzati in modo automatico durante
                    la generazione del manifesto:
                  </small>
                  <br />
                  {usablePlaceholders()}
                </div>
              </Collapse>
            </div>

            <Form.Group style={{ marginBottom: "20px", height: "400px" }}>
              <HTMLEditor
                value={template.text}
                valueChanged={(updatedValue) => {
                  setTemplate({ ...template, text: updatedValue });
                }}
              />
            </Form.Group>

            <div>
              <Button
                variant="secondary"
                className="float-left"
                onClick={() => history.goBack()}
                type="button"
              >
                Annulla
              </Button>
              <Button variant="primary" type="submit" className="float-right">
                Salva
              </Button>
            </div>
          </Form>
        </Container>
      </Card.Body>
    </Card>
  );
};

function ErrorLabel({ errors, children }) {
  if (errors)
    return <Form.Label className="text-danger">{children}</Form.Label>;
  else return null;
}

export default TemplateEditor;
