import React, { useContext, useEffect } from 'react'
import { Route, Switch, useHistory } from 'react-router-dom'
import UserContext from './UserContext'
import { userLoggedIn } from './reducers/actions'
import PrivateRoute from './PrivateRoute'
import Calendar from './views/events/Calendar'
import Users from './views/users/Users'
import Templates from './views/templates/Templates'
import TemplateEditor from './views/templates/TemplateEditor'
import Suppliers from './views/suppliers/Suppliers'
import SupplierEditor from './views/suppliers/SupplierEditor'
import Agencies from './views/agencies/Agencies'
import AgencyEditor from './views/agencies/AgencyEditor'
import Boards from './views/boards/Boards'
import BoardEditor from './views/boards/BoardEditor'
import Deceased from './views/deceased/Deceased'
import DeceasedEditor from './views/deceased/DeceasedEditor'
import Posters from './views/posters/Posters'
import PosterPreview from './views/posters/PosterPreview'
import Areas from './views/areas/Areas'
import AreaEditor from './views/areas/AreaEditor'
import SignIn from './views/auth/SignIn'
import SignUp from './views/auth/SignUp'
import NotFound from './views/common/NotFound'
import { getAuthenticatedUser } from './api/auth'
import WaitingConfirmation from './views/auth/WaitingConfirmation'
import ResetPassword from './views/auth/ResetPassword'
import ConfirmPassword from './views/auth/ConfirmPassword'
import ProfileEditor from './views/users/ProfileEditor'
import StoreAds from './views/stores/StoreAds'
import StorePosters from './views/stores/StorePosters'
import Checkout from './views/stores/Checkout'
import Transactions from './views/stores/Transactions'
import WaitingApproval from './views/auth/WaitingApproval'
import Products from './views/products/Products'

const Routing = () => {
    const history = useHistory()
    const { dispatch } = useContext(UserContext)
    const user = getAuthenticatedUser()
    useEffect(() => {
        if (user) {
            dispatch(userLoggedIn(user))
        } else {
            if (!history.location.pathname.startsWith('/signup') &&
                !history.location.pathname.startsWith('/waiting_confirmation') &&
                !history.location.pathname.startsWith('/reset_password') &&
                !history.location.pathname.startsWith('/confirm_password')) {
                history.push('/signin')

            }
        }
    }, [])

    return (
        <Switch>
            <PrivateRoute exact path="/">
                <Calendar />
            </PrivateRoute>
            {/*                     Store routes                  */}
            <PrivateRoute exact path="/stores/ads" user={user} requiredRole="S" component={() => <StoreAds model="supplier" />} />
            <PrivateRoute exact path="/stores/posters" user={user} requiredRole="G" component={() => <StorePosters model="agency" />} />
            <PrivateRoute exact path="/stores/checkout/:productId">
                <Checkout />
            </PrivateRoute>
            <PrivateRoute exact path="/stores/transactions">
                <Transactions />
            </PrivateRoute>

            {/*                     Deceased routes                   */}
            <PrivateRoute exact path="/deceased">
                <Deceased />
            </PrivateRoute>
            <PrivateRoute path="/deceased/new">
                <DeceasedEditor />
            </PrivateRoute>
            <PrivateRoute path="/deceased/:deceasedId/edit">
                <DeceasedEditor />
            </PrivateRoute>
            <PrivateRoute path="/deceased/:deceasedId/posters/:posterId">
                <PosterPreview />
            </PrivateRoute>

            {/*                     Users routes                 */}
            <PrivateRoute exact path="/users" component={Users} />
            <PrivateRoute exact path="/profile" component={ProfileEditor} />

            {/*                     Template routes                  */}
            <PrivateRoute exact path="/templates" component={Templates} />
            <PrivateRoute exact path="/templates/new" user={user} requiredRole="A" component={() => <TemplateEditor isNew={true} model="template" />} />
            <PrivateRoute exact path="/templates/:templateId/edit" user={user} requiredRole="A" component={() => <TemplateEditor model="template" />} />

            {/*                     Supplier routes                  */}
            <PrivateRoute exact path="/suppliers" component={Suppliers} />
            <PrivateRoute exact path="/suppliers/new" user={user} requiredRole="A" component={() => <SupplierEditor isNew={true} model="supplier" />} />
            <PrivateRoute exact path="/suppliers/:supplierId/edit" user={user} requiredRole="A" component={() => <SupplierEditor model="supplier" />} />

            {/*                     Agency routes                  */}
            <PrivateRoute exact path="/agencies" component={Agencies} />
            <PrivateRoute exact path="/agencies/new" user={user} requiredRole="A" component={() => <AgencyEditor model="agency" />} />
            <PrivateRoute exact path="/agencies/:agencyId/edit" user={user} requiredRole="A" component={() => <AgencyEditor model="agency" />} />

            {/*                     Board routes                  */}
            <PrivateRoute exact path="/boards" component={Boards} />
            <PrivateRoute exact path="/boards/new" user={user} requiredRole="A" component={() => <BoardEditor isNew={true} model="board" />} />
            <PrivateRoute exact path="/boards/:boardId/edit" user={user} requiredRole="A" component={() => <BoardEditor model="board" />} />
            <PrivateRoute path="/boards/page/:pageNo">
                <Boards />
            </PrivateRoute>
            <PrivateRoute path="/boards/edit">
                <BoardEditor />
            </PrivateRoute>

            {/*                     Poster routes                  */}
            <PrivateRoute path="/posters">
                <Posters />
            </PrivateRoute>

            {/*                     Area routes                  */}
            <PrivateRoute exact path="/areas" user={user} requiredRole="A" component={Areas} />
            <PrivateRoute exact path="/areas/new" user={user} requiredRole="A" component={() => <AreaEditor isNew={true} model="area" />} />
            <PrivateRoute exact path="/areas/:areaId/edit" user={user} requiredRole="A" component={() => <AreaEditor model="area" />} />
            <PrivateRoute exact path="/areas/:areaId/subareas" user={user} requiredRole="A" component={Areas} />
            <PrivateRoute exact path="/areas/:areaId/subareas/new" user={user} requiredRole="A" component={() => <AreaEditor isNew={true} model="subarea" />} />
            <PrivateRoute exact path="/areas/:areaId/subareas/:subareaId/edit" user={user} requiredRole="A" component={() => <AreaEditor model="subarea" />} />

            {/* Product routes */}
            <PrivateRoute exact path="/products" user={user} requiredRole="A" component={() => <Products />} />

            {/*                     Auth routes                  */}
            <Route path="/signin">
                <SignIn />
            </Route>
            <Route path="/signup">
                <SignUp />
            </Route>
            <Route path="/waiting_confirmation">
                <WaitingConfirmation />
            </Route>
            <Route path="/waiting_approval">
                <WaitingApproval />
            </Route>
            <Route path="/reset_password">
                <ResetPassword />
            </Route>
            <Route path="/confirm_password">
                <ConfirmPassword />
            </Route>

            {/*                     Other routes                  */}
            <Route>
                <NotFound />
            </Route>
        </Switch>
    )
}

export default Routing
