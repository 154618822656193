import React, { useEffect, useState } from "react";
import { Pagination as BPagination } from "react-bootstrap";

const Pagination = (props) => {
  const maxVisibleItems = 5;
  const [bounds, setBounds] = useState();

  useEffect(() => {
    const currentPage = props.currentPage;
    const totPages = props.totPages;

    if (currentPage > totPages) {
      console.log("Invalid page index");
      return;
    }

    let firstIndex = currentPage - Math.floor(maxVisibleItems / 2);
    if (firstIndex < 1) firstIndex = 1;
    let lastIndex = firstIndex + maxVisibleItems - 1;
    if (lastIndex > totPages) lastIndex = totPages;

    const hasPrev = currentPage > firstIndex;
    const hasNext = currentPage < lastIndex;

    setBounds({
      currentPage,
      totPages,
      firstIndex,
      lastIndex,
      hasPrev,
      hasNext,
    });
  }, [props.currentPage, props.totPages]);

  return (
    <div className="d-flex align-items-center justify-content-center">
      {bounds && bounds.currentPage ? (
        <BPagination >
          <BPagination.First
            disabled={!bounds.hasPrev}
            onClick={() => props.pageChanged(1)}
          />
          <BPagination.Prev
            disabled={!bounds.hasPrev}
            onClick={() => props.pageChanged(bounds.currentPage - 1)}
          />
          {[...Array(bounds.lastIndex - bounds.firstIndex + 1)].map(
            (_, index) => {
              const currentPage = bounds.firstIndex + index;
              return (
                <BPagination.Item
                  key={currentPage}
                  active={currentPage === bounds.currentPage}
                  onClick={() => props.pageChanged(currentPage)}
                >
                  {currentPage}
                </BPagination.Item>
              );
            }
          )}
          <BPagination.Next
            disabled={!bounds.hasNext}
            onClick={() => props.pageChanged(bounds.currentPage + 1)}
          />
          <BPagination.Last
            disabled={!bounds.hasNext}
            onClick={() => props.pageChanged(bounds.totPages)}
          />
        </BPagination>
      ) : (
        ""
      )}
    </div>
  );
};

export default Pagination;
