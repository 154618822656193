import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import UserContext from "../../UserContext";
import BaseLayout from "../layouts/BaseLayout";
import WeekSelector from "../../components/WeekSelector";
import CalendarDetail from "./CalendarDetail";
import Footer from "../../components/Footer";
import { Container, Row, Col, Card } from "react-bootstrap";
import { getCancelTokenSource } from "../../api/common";
import { listEventsTypes, listEvents } from "../../api/events";
import { formatDateYYYYMMDD } from "../../utils";

const Calendar = () => {
  const { state } = useContext(UserContext);
  const history = useHistory();
  const [eventTypes, setEventTypes] = useState();
  const [weekInfo, setWeekInfo] = useState();
  const [events, setEvents] = useState();
  const [notices, setNotices] = useState();
  const [requestTimeout, setRequestTimeout] = useState();
  const [cancelable, setCancelable] = useState();

  const weekChanged = (info) => {
    setWeekInfo(info);
  };

  useEffect(() => {
    if (state.user?.role === "S") {
      history.push("/profile");
      return;
    }
    if (state.user?.role === "A") {
      history.push("/deceased");
      return;
    }
    (async () => {
      const res = await listEventsTypes();
      setEventTypes(res);
    })();
  }, [state]);

  useEffect(() => {
    cancelPendingRequests();
    if (weekInfo) {
      setRequestTimeout(
        setTimeout(() => {
          makeRequest();
        }, 300)
      );
    }
  }, [weekInfo]);

  const cancelPendingRequests = () => {
    if (requestTimeout) {
      clearTimeout(requestTimeout);
    }
    if (cancelable) {
      cancelable.cancel(`Request canceled`);
      setCancelable(null);
    }
  };

  const makeRequest = () => {
    (async () => {
      const cancelTokenSource = getCancelTokenSource();
      setCancelable(cancelTokenSource);
      const res = await listEvents(
        formatDateYYYYMMDD(weekInfo.startDate),
        formatDateYYYYMMDD(weekInfo.endDate),
        cancelTokenSource
      );
      setCancelable(null);
      setRequestTimeout(null);
      if (res) {
        setEvents(groupedByDay(res.events));
        setNotices(groupedByDay(res.notices, "notice_date"));
      }
    })();
  };

  const groupedByDay = (items, dateFieldName = "date") => {
    const groupedByDay = [];
    if (items) {
      items.forEach((item) => {
        const key = formatDateYYYYMMDD(item[dateFieldName]);
        if (!groupedByDay[key]) {
          groupedByDay[key] = [];
        }
        groupedByDay[key].push(item);
      });
    }
    return groupedByDay;
  };

  return (
    <>
      <Card className="mt-3">
        <Card.Header>
          <Card.Title>Eventi</Card.Title>
          <Card.Subtitle>
            <WeekSelector weekChanged={weekChanged} />
          </Card.Subtitle>
        </Card.Header>
        <Card.Body>
          <CalendarDetail
            eventTypes={eventTypes}
            weekInfo={weekInfo}
            events={events}
            notices={notices}
          />
        </Card.Body>
      </Card>
    </>
  );
};

export default Calendar;
