import { UserStatus } from "./../api/types"

/**
 * Given a property string, returns if the property is
 * included in the SignUpRequest interface
 * @param property 
 * @returns 
 */
export function propertyInSignUpRequest(property: string): boolean {
    return ["email", "password", "type", "name", "address", "city", "prov", "vat", "tax_code", "unique_code", "terms"].includes(property);
}

export const getLabelFromUserStatus = (status: UserStatus) => {
    switch (status) {
        case "A":
            return "Approvato";
        case "I":
            return "Email da confermare";
        case "L":
            return "Bloccato";
        case "P":
            return "In attesa di validazione"
    }
}

export const getColorFromUserStatus = (status: UserStatus) => {
    switch (status) {
        case "A":
            return 'success';
        default:
            return 'danger';
    }
}