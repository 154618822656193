import React, { useEffect, useState, useContext } from "react";
import UserContext from "../../UserContext";
import { contentLoading, contentLoaded } from "../../reducers/actions";
import {
  Container,
  Row,
  Col,
  Table,
  ButtonToolbar,
  ButtonGroup,
  Button,
  Form,
  Card,
} from "react-bootstrap";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import { listAreas, deleteArea, getArea } from "../../api/areas";
import BaseLayout from "../layouts/BaseLayout";
import Pagination from "../../components/Pagination";
import Footer from "../../components/Footer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faSearch, faTrash } from "@fortawesome/free-solid-svg-icons";
import GoBack from "../../components/GoBack";

const Areas = () => {
  const { dispatch } = useContext(UserContext);
  const location = useLocation();
  const { areaId } = useParams();
  const [parentArea, setParentArea] = useState();
  const history = useHistory();
  const [filter, setFilter] = useState("");
  const [params, setParams] = useState();
  const [data, setData] = useState();

  useEffect(() => {
    // const currentPath = location.pathname
    const searchParams = new URLSearchParams(location.search);
    setParams({
      areaId: areaId,
      pageNo: searchParams.get("page") || 1,
      filter: searchParams.get("name") || "",
    });
  }, [location]);

  useEffect(() => {
    if (params) {
      (async () => {
        dispatch(contentLoading());
        const res = await listAreas(
          params.areaId,
          params.filter,
          params.pageNo
        );
        setData(res);
        dispatch(contentLoaded());
      })();

      if (params.areaId) {
        (async () => {
          const area = await getArea(params.areaId);
          setParentArea(area);
        })();
      } else {
        setParentArea(null);
      }
    }
  }, [params]);

  const changeFilter = (e) => {
    e.preventDefault();
    // Always reset page to 1
    history.push(location.pathname + "?name=" + filter);
  };

  const pageChangedHandler = (page) => {
    let url = location.pathname + "?page=" + page;
    if (params.filter) url += "&name=" + params.filter;
    history.push(url);
  };

  const editItem = (area) => {
    history.push(
      area.parent_id
        ? `/areas/${area.parent_id}/subareas/${area.id}/edit`
        : `/areas/${area.id}/edit`
    );
  };

  const deleteItem = (area) => {
    if (!window.confirm(`Confermi l'eliminazione dell'area\n"${area.name}"?`))
      return;

    (async () => {
      await deleteArea(area);
      history.go(0);
    })();
  };
  
  return (
    <Card>
      <Card.Header>
        <Card.Title>
          <GoBack />
          {parentArea ? (
            <Link
              to="/areas"
              style={{ color: "#000000", textDecoration: "none" }}
            >
              <i></i>
            </Link>
          ) : (
            ""
          )}
          Aree
          {parentArea ? <> - {parentArea.name}</> : ""}
          {data ? <small> - {data.last_page} pagine</small> : ""}
        </Card.Title>
      </Card.Header>
      <Card.Body>
        <Container fluid>
          <Row className="align-items-center">
            <Col xs={12} md={8}>
              <Form onSubmit={changeFilter}>
                <Row className="align-items-center">
                  <Col className="pr-0">
                    <Form.Control
                      type="text"
                      placeholder="Cerca per cognome..."
                      onChange={(e) => setFilter(e.target.value)}
                      value={filter}
                    />
                  </Col>
                  <Col className="pl-1">
                    <Button type="submit">
                      <FontAwesomeIcon icon={faSearch} />
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Col>
            <Col xs={12} md={4}>
              <Link to={areaId ? `/areas/${areaId}/subareas/new` : "areas/new"}>
                Aggiungi
              </Link>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <Table responsive hover className="mt-2">
                <thead>
                  <tr>
                    <th>Nome</th>
                    <th className="d-xs-none" >Lat/Lng</th>
                    <th className="d-xs-none" >Raggio in mt</th>
                    <td></td>
                  </tr>
                </thead>
                <tbody>
                  {data && data.data ? (
                    data.data.map((area) => (
                      <tr key={area.id}>
                        <td>
                          <strong>{area.name}</strong>
                          <br />
                          <Link
                            hidden={params.areaId}
                            to={`/areas/${area.id}/subareas`}
                          >
                            <small>Visualizza sotto-aree</small>
                          </Link>
                        </td>
                        <td className="d-xs-none" >
                          {area.lat} / {area.lng}
                        </td>
                        <td className="d-xs-none" >{area.radius}</td>
                        <td className="d-flex justify-content-end">
                          <ButtonToolbar>
                            <ButtonGroup>
                              <Button
                                variant="secondary"
                                onClick={(e) => editItem(area)}
                              >
                                <FontAwesomeIcon icon={faEdit} />
                              </Button>
                              <Button
                                variant="danger"
                                onClick={(e) => deleteItem(area)}
                              >
                                <FontAwesomeIcon icon={faTrash} />
                              </Button>
                            </ButtonGroup>
                          </ButtonToolbar>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="4">No result</td>
                    </tr>
                  )}
                </tbody>
                <tfoot>
                  <tr>
                    <td colSpan="5" style={{ paddingBottom: 0 }}>
                      <Pagination
                        currentPage={data?.current_page}
                        totPages={data?.last_page}
                        pageChanged={pageChangedHandler}
                      />
                    </td>
                  </tr>
                </tfoot>
              </Table>
            </Col>
          </Row>
        </Container>
      </Card.Body>
    </Card>
  );
};

export default Areas;
