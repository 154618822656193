import React, { useEffect, useState, useContext } from "react";
import UserContext from "../../UserContext";
import {
  Container,
  Row,
  Col,
  Table,
  ButtonToolbar,
  ButtonGroup,
  Button,
  Form,
  InputGroup,
  FormControl,
  Card,
} from "react-bootstrap";
import { contentLoading, contentLoaded } from "../../reducers/actions";
import { Link, useHistory, useLocation } from "react-router-dom";
import { listPosterTypes } from "../../api/posters";
import {
  listTemplates,
  deleteTemplate,
  targetTypes,
} from "../../api/templates";
import BaseLayout from "../layouts/BaseLayout";
import Pagination from "../../components/Pagination";
import Footer from "../../components/Footer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faSearch, faTrash } from "@fortawesome/free-solid-svg-icons";
import GoBack from "../../components/GoBack";

const Templates = () => {
  const { dispatch } = useContext(UserContext);
  const location = useLocation();
  const history = useHistory();
  const [nameFilter, setNameFilter] = useState("");
  const [posterTypeFilter, setPosterTypeFilter] = useState("");
  const [params, setParams] = useState();
  const [data, setData] = useState();
  const [posterTypes, setPosterTypes] = useState([]);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    setParams({
      pageNo: searchParams.get("page") || 1,
      nameFilter: searchParams.get("name") || "",
      posterTypeFilter: searchParams.get("type") || "",
    });
  }, [location]);

  useEffect(() => {
    if (params) {
      (async () => {
        dispatch(contentLoading());
        const resPosterTypes = await listPosterTypes();
        setPosterTypes(resPosterTypes);
        const res = await listTemplates(
          params.nameFilter,
          params.posterTypeFilter,
          params.pageNo
        );
        setData(res);
        dispatch(contentLoaded());
      })();
    }
  }, [params]);

  useEffect(() => {
    applyFilters();
  }, [posterTypeFilter]);

  const applyFilters = (e) => {
    if (e && e.preventDefault) e.preventDefault();
    // Always reset page to 1
    history.push(
      `${location.pathname}?name=${nameFilter}&type=${posterTypeFilter}`
    );
  };

  const pageChangedHandler = (page) => {
    let url = location.pathname + "?page=" + page;
    if (params.nameFilter) url += "&name=" + params.filter;
    history.push(url);
  };

  const editItem = (template) => {
    history.push(`/templates/${template.id}/edit`);
  };

  const deleteItem = (template) => {
    if (
      !window.confirm(
        `Confermi l'eliminazione del template\n"${template.name}"?`
      )
    )
      return;

    (async () => {
      await deleteTemplate(template);
      history.go(0);
    })();
  };

  return (
    <Card>
      <Card.Header>
        <Card.Title><GoBack /> Modelli di manifesto</Card.Title>
      </Card.Header>
      <Card.Body>
        <Container fluid>
          <Row>
            <Col xs={12} md={10}>
              <Form onSubmit={applyFilters}>
                <Form.Row>
                  <Form.Group as={Col} xs={4} controlId="searchPosterType">
                    <Form.Control
                      as="select"
                      custom
                      value={posterTypeFilter}
                      onChange={(e) => setPosterTypeFilter(e.target.value)}
                    >
                      <option value="" key="all">
                        Tutti
                      </option>
                      {posterTypes &&
                        Object.entries(posterTypes).map(([key, value]) => (
                          <option key={key} value={key}>
                            {value}
                          </option>
                        ))}
                    </Form.Control>
                  </Form.Group>
                  <Form.Group as={Col} xs={8} controlId="searchPosterName">
                    <InputGroup>
                      <FormControl
                        name="name"
                        placeholder="Cerca"
                        onChange={(e) => setNameFilter(e.target.value)}
                        value={nameFilter}
                      />
                      <InputGroup.Append>
                        <Button type="submit">
                          <FontAwesomeIcon icon={faSearch} />
                        </Button>
                      </InputGroup.Append>
                    </InputGroup>
                  </Form.Group>
                </Form.Row>
              </Form>
            </Col>
            <Col xs={12} md={2}>
              <Link to="templates/new">Aggiungi</Link>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <Table responsive hover>
                <thead>
                  <tr>
                    <th className="d-xs-none">Nome e tipo</th>
                    <th className="d-xs-none" >Target</th>
                    <th className="d-xs-none" >Testo</th>
                    <th>Anteprima</th>
                    <td></td>
                  </tr>
                </thead>
                <tbody>
                  {data ? (
                    data.map((template) => (
                      <tr key={template.id}>
                        <td className="d-xs-none">
                          <strong>{template.name}</strong>
                          <br />
                          <small>{posterTypes[template.poster_type]}</small>
                        </td>
                        <td className="d-xs-none" >{targetTypes[template.target]}</td>
                        <td className="d-xs-none" >
                          {template.text
                            .replace(/<\/?[^>]+(>|$)/g, "")
                            .substr(0, 50)}
                        </td>
                        <td >
                          {template.attachment ? (
                            <img
                              style={{ height: 50 }}
                              src={template.attachment.url}
                              alt="Template preview"
                            />
                          ) : (
                            "Non disponibile"
                          )}
                        </td>
                        <td className="d-flex justify-content-end">
                          <ButtonToolbar>
                            <ButtonGroup>
                              <Button
                                variant="secondary"
                                onClick={(e) => editItem(template)}
                              >
                                <FontAwesomeIcon icon={faEdit} />
                              </Button>
                              <Button
                                variant="danger"
                                onClick={(e) => deleteItem(template)}
                              >
                                <FontAwesomeIcon icon={faTrash} />
                              </Button>
                            </ButtonGroup>
                          </ButtonToolbar>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="4">Nessun risultato</td>
                    </tr>
                  )}
                </tbody>
                <tfoot>
                  <tr>
                    <td colSpan="5" style={{ paddingBottom: 0 }}>
                      <Pagination
                        currentPage={data?.current_page}
                        totPages={data?.last_page}
                        pageChanged={pageChangedHandler}
                      />
                    </td>
                  </tr>
                </tfoot>
              </Table>
            </Col>
          </Row>
        </Container>
      </Card.Body>
    </Card>
  );
};

export default Templates;
