import React, { useState } from "react";
import {
  Modal,
  Container,
  Row,
  Col,
  Form,
  Button,
  ListGroup,
} from "react-bootstrap";
import ImageUploader from "../../components/ImageUploader";
import { saveAttachment, deleteAttachment } from "../../api/deceased";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload, faTrash } from "@fortawesome/free-solid-svg-icons";

const emptyAttachment = {
  id: null,
  filename: "",
  text: "",
};

const DeceasedAttachments = (props) => {
  const [attachment, setAttachment] = useState(emptyAttachment);
  const [attachmentModalVisibility, setAttachmentModalVisibility] =
    useState(false);

  const changeAttachmentFieldHandler = (e) => {
    const { name, value } = e.target;
    setAttachment({ ...attachment, [name]: value });
  };

  const openAttachmentModalHandler = () => {
    setAttachmentModalVisibility(true);
  };

  const editAttachment = (item) => {
    setAttachment(item);
    openAttachmentModalHandler();
  };

  const closeAttachmentModalHandler = () => {
    setAttachmentModalVisibility(false);
  };

  const saveAttachmentHandler = () => {
    (async () => {
      const res = await saveAttachment(props.deceased, attachment);
      if (res.error) {
        console.warn(res.error);
        alert("Si è verificato un errore durante il salvataggio");
        return;
      }
      setAttachmentModalVisibility(false);
      props.attachmentSavedHandler(res);
    })();
  };

  const deleteAttachmentHandler = (attachment) => {
    (async () => {
      if (!window.confirm("Eliminare in modo definitivo l'allegato?")) return;
      const res = await deleteAttachment(props.deceased, attachment);
      if (res.error) {
        console.warn(res.error);
        alert("Si è verificato un errore");
        return;
      }
      props.attachmentDeletedHandler(res);
    })();
  };

  const setAttachmentImage = (data) => {
    if (data) {
      setAttachment({ ...attachment, filename: data.filename, url: data.url });
    } else {
      setAttachment({ ...attachment, filename: "", url: "/document.svg" });
    }
  };

  return (
    <>
      <Modal
        size="lg"
        show={attachmentModalVisibility}
        onShow={openAttachmentModalHandler}
        onHide={closeAttachmentModalHandler}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {attachment.id ? "Modifica allegato" : "Aggiungi allegato"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ height: "70vh" }}>
          <Container className="d-flex flex-column h-100">
            <Row>
              <Col>
                {/* TODO Get a suitable list of document types */}
                <datalist id="documenttypes">
                  <option>Certificato 1</option>
                  <option>Documento 2</option>
                  <option>Attestato 3</option>
                </datalist>
                <Form.Group>
                  <Form.Label>Descrizione</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Descrizione dell'allegato"
                    list="documenttypes"
                    name="text"
                    value={attachment.text}
                    onChange={changeAttachmentFieldHandler}
                    required
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row className="h-100 cursor-pointer">
              <Col
                className="h-100 text-center"
                style={{ overflowY: "scroll" }}
              >
                <div
                  className="h-100 text-center"
                  style={{ position: "absolute", left: 0, right: 0 }}
                >
                  <ImageUploader
                    initialImage="/document.svg"
                    uploadedFileHandler={setAttachmentImage}
                    wrapperHeight={"100%"}
                    height={"100%"}
                  />
                </div>
              </Col>
            </Row>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Container>
            <Row>
              <Col>
                <Button
                  variant="secondary"
                  onClick={closeAttachmentModalHandler}
                >
                  Annulla
                </Button>
                <Button
                  variant="primary"
                  className="float-right"
                  onClick={saveAttachmentHandler}
                >
                  Salva
                </Button>
              </Col>
            </Row>
          </Container>
        </Modal.Footer>
      </Modal>

      <div>
        <Button
          onClick={(e) => {
            editAttachment(emptyAttachment);
          }}
          title="Aggiungi un nuovo allegato"
        >
          Aggiungi
        </Button>
        <div>
          {props.deceased.attachments?.length ? (
            <ListGroup className="mt-3">
              {props.deceased.attachments.map((item) => (
                <ListGroup.Item key={item.id}>
                  <Container fluid>
                    <Row className="align-items-center justify-content-center">
                      <Col sm={8}>
                        <h6>{item.text || "Senza nome"}</h6>
                      </Col>
                      <Col sm={4} className="text-right">
                        <a
                          href={item.url}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="mr-3"
                        >
                          Scarica <FontAwesomeIcon icon={faDownload} />
                        </a>

                        <Button
                          variant="danger"
                          onClick={(e) => deleteAttachmentHandler(item)}
                        >
                          Elimina <FontAwesomeIcon icon={faTrash} />
                        </Button>
                      </Col>
                    </Row>
                  </Container>
                </ListGroup.Item>
              ))}
            </ListGroup>
          ) : null}
        </div>
      </div>
    </>
  );
};

export default DeceasedAttachments;
