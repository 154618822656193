import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import BaseLayout from "../layouts/BaseLayout";
import AsyncSelect from "react-select/async";
import { listProducts } from "../../api/products";
import { Row, Col, Container, Form, Button, Card } from "react-bootstrap";

const StorePosters = () => {
  const history = useHistory();
  const [validated, setValidated] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState("");
  const [order, setOrder] = useState({
    id: null,
    product_id: null,
    price: 0.0,
  });
  const [quantity, setQuantity] = useState(1);

  const loadProducts = (inputValue, callback) => {
    setTimeout(() => {
      (async () => {
        const res = await listProducts();
        const keyValues = res.map((item) => {
          return {
            value: item.id,
            label: item.name,
            price: item.price,
            description: item.description,
            vat: item.vat,
          };
        });

        callback(keyValues);
      })();
    }, 1000);
  };

  const handleSelectChange = (newValue) => {
    setSelectedProduct({
      value: newValue.value,
      label: newValue.label,
      description: newValue.description,
      price: newValue.price,
    });
    setOrder({ ...order, product_id: parseInt(newValue.value) });
  };

  const handleChangeQuantity = (e) => {
    setQuantity(parseInt(e.target.value));
  };

  return (
    <Card>
      <Card.Header>
        <Card.Title>Acquista Manifesti</Card.Title>
        <Card.Subtitle>
          Compra manifesti da utilizzare per la tua attività
        </Card.Subtitle>
        <Button
          className="float-right"
          variant="secondary"
          onClick={() => history.push("/stores/transactions")}
        >
          I tuoi acquisti
        </Button>
      </Card.Header>
      <Card.Body>
        <Container fluid>
          <p>
            Scegli la tipologia di manifesto che più si addice alle necessità
            operative della tua agenzia.
          </p>

          <Form noValidate validated={validated}>
            <Form.Group>
              <AsyncSelect
                name="product_id"
                value={selectedProduct}
                cacheOptions
                placeholder="Seleziona dall'elenco"
                loadOptions={loadProducts}
                defaultOptions
                onChange={handleSelectChange}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>{selectedProduct.description}</Form.Label>
            </Form.Group>
            {selectedProduct.price ? (
              <Form.Group>
                <Form.Label>Seleziona una quantità</Form.Label>
                <Form.Control as="select" onChange={handleChangeQuantity}>
                  <option value="1">1</option>
                  <option value="5">5</option>
                  <option value="10">10</option>
                  <option value="25">25</option>
                  <option value="50">50</option>
                </Form.Control>
              </Form.Group>
            ) : (
              ""
            )}
            {selectedProduct.price ? (
              <Form.Group>
                <Form.Label>
                  <small>Prezzo iva inclusa</small>
                  <br />
                  <strong className="h3"> € {selectedProduct.price * quantity} </strong>
                </Form.Label>
              </Form.Group>
            ) : (
              ""
            )}
            <Button
              disabled={!selectedProduct}
              variant="success"
              onClick={() =>
                history.push(
                  "/stores/checkout/" +
                    selectedProduct.value +
                    "?quantity=" +
                    quantity
                )
              }
            >
              Vai in Cassa
            </Button>
          </Form>
        </Container>
      </Card.Body>
      <Card.Footer>
        Puoi pagare usando PayPal, per più informazioni{" "}
        <a href="#">clicca qui</a>
      </Card.Footer>
    </Card>
  );
};

export default StorePosters;
