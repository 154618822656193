import { baseUrl, options } from './common'
import axios from 'axios'

const listEventsTypes = async () => {
    try {
        const url = `${baseUrl}/api/events/types`;
        const response = await axios.get(url, options())
        return response.data
    } catch (err) {
        return {
            error: err
        }
    }
}

const listEvents = async (startDate, endDate, cancelTokenSource) => {
    try {
        const response = await axios.get(`${baseUrl}/api/events?from=${startDate}&to=${endDate}`, 
            options(
                {cancelToken: cancelTokenSource.token}
            ))
        return response.data
    } catch (err) {
        return {
            error: err
        }
    }
}

const saveEvent = async (event) => {
    try {
        const response = event.id ?
            await axios.put(`${baseUrl}/api/deceased/${event.deceased_id}/events/${event.id}`, event, options()) : 
            await axios.post(`${baseUrl}/api/deceased/${event.deceased_id}/events`, event, options())
        return response.data
    } catch (err) {
        return {
            error: err
        }
    }
}

const deleteEvent = async (event) => {
    try {
        const response = await axios.delete(`${baseUrl}/api/deceased/${event.deceased_id}/events/${event.id}`, options())
        return response.data
    } catch (err) {
        return {
            error: err
        }
    }
}

export { listEventsTypes, listEvents, saveEvent, deleteEvent }