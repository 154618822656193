import { faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Container, Row, Col, ButtonGroup, Button } from "react-bootstrap";
import { weekFromDate, formatWeekInfo } from "../utils";

const WeekSelector = (props) => {
  const [weekInfo, setWeekInfo] = useState();

  useEffect(() => {
    currWeek();
  }, []);

  const currWeek = () => {
    const info = weekFromDate();
    setWeekInfo(info);
    if (props.weekChanged) {
      props.weekChanged(info);
    }
  };

  const prevWeek = () => {
    const info = { ...weekInfo };
    info.startDate.subtract(1, "weeks");
    info.endDate.subtract(1, "weeks");
    setWeekInfo(info);
    if (props.weekChanged) {
      props.weekChanged(info);
    }
  };

  const nextWeek = () => {
    const info = { ...weekInfo };
    info.startDate.add(1, "weeks");
    info.endDate.add(1, "weeks");
    setWeekInfo(info);
    if (props.weekChanged) {
      props.weekChanged(info);
    }
  };

  return (
    <Container>
      <Row>
        <Col className="text-center">
          <h4>{formatWeekInfo(weekInfo)}</h4>
        </Col>
      </Row>
      <Row>
        <Col className="text-left">
          <ButtonGroup>
          <Button aria-label="prev" onClick={prevWeek}>
            <FontAwesomeIcon icon={faChevronLeft}/>
          </Button>
            <Button
              className="mx-1"
              size="sm"
              disabled={false}
              onClick={currWeek}
            >
              Oggi
            </Button>
            <Button
              aria-label="next"
              onClick={nextWeek}
            >
            <FontAwesomeIcon icon={faChevronRight}/>
            </Button>
          </ButtonGroup>
        </Col>
      </Row>
    </Container>
  );
};

export default WeekSelector;
