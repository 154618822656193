import React from 'react'
import { Row, Col } from 'react-bootstrap'
import BaseLayout from '../layouts/BaseLayout'

const NotFound = () => {
    return (
        <>
            <div  style={{display: "block"}}>
                <div >
                    <Row>
                        <Col xs={5}>
                            <h4 >Questa pagina non esiste</h4>
                        </Col>
                    </Row>
                </div>
            </div>
        </>
    )
}

export default NotFound
