import React, { useState, useEffect, useContext } from 'react'
import UserContext from '../../UserContext'
import { contentLoading, contentLoaded } from '../../reducers/actions'
import { useHistory, Link, useLocation } from 'react-router-dom'
import { Row, Col, Button } from 'react-bootstrap'
import * as auth from '../../api/auth'
import Onboarding from '../layouts/Onboarding'

const WaitingConfirmation = () => {
    const {dispatch} = useContext(UserContext)
    const [email, setEmail] = useState('')
    const history = useHistory()
    const location = useLocation()
    const [params, setParams] = useState()

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        setParams({
            email: searchParams.get('email') || '',
            token: searchParams.get('token') || null
        })
    }, [location])

    useEffect(() => {
        if (params && params.email) {
            setEmail(params.email)
            if (params.token) {
                
                (async() => {
                    
                    dispatch(contentLoading())
                    const res = await auth.verifyToken(params.token)
                    if (!res.error) {
                        alert("Indirizzo email confermato!")
                        history.push('/signin')
                    }
                    dispatch(contentLoaded())
                })()
            }

        }
    }, [params])

    const resendHandler = (e) => {
        (async() => {
            const res = await auth.verify(email)

            if (!res.error) {
               // fai qualcosa
               console.log("conferma re-inviata")
               alert('Email di conferma inviata di nuovo! Controlla la tua casella di posta')
            } else {
                // fai altro
                console.log("errore nel reinvio ", res.error)
            }
        })()
    }

    return (
        <Onboarding title="Ci siamo quasi" body="Una email ti è stata inviata per conferma. Dopo puoi fare i login.">
            <Row>
                    <Col xs={12}>
                        <div> Se hai già confermato il link effettua il <Link to="/signin">login</Link></div>
                        <br/>
                        <div><Button variant="primary"  onClick={resendHandler}>Invia una nuova email di conferma</Button></div>
                    </Col>
                </Row>
        </Onboarding>
 
    )
}

export default WaitingConfirmation