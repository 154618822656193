import React, { useState, useEffect, useContext } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { Form, Button } from 'react-bootstrap'
import UserContext from '../../UserContext'
import { contentLoading, contentLoaded } from '../../reducers/actions'
import * as auth from '../../api/auth'
import Onboarding from '../layouts/Onboarding'

const ConfirmPassword = () => {
    const {dispatch} = useContext(UserContext)
    const history = useHistory()
    const [password, setPassword] = useState('')
    const [passwordMatch, setPasswordMatch] = useState('')
    const [token, setToken] = useState(null)
    const [isConfirming, setIsConfirming] = useState(false)
    const location = useLocation()
    const [params, setParams] = useState()
    const [validated, setValidated] = useState(false)
   
    const handleNewPassword = (e) => {
        e.preventDefault()
        e.stopPropagation()
        setValidated(true)

        if (!e.currentTarget.checkValidity()) return

        setIsConfirming(true)
    }

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        setParams({
            token: searchParams.get('token') || null
        })
    }, [location])

    useEffect(() => {
        if (isConfirming) {
            (async() => {
                
                dispatch(contentLoading())
                const res = await auth.verifyPassword(token, password)
                dispatch(contentLoaded())
                setIsConfirming(false)

                if (!res.error) {
                    alert('Cambio password avvenuto con successo')
                    history.push('/signin');
                } else {
                    alert(Array(res.error).map(obj => { return obj[Object.keys(obj)[0]] }).join('\n'))
                }
            })()
        }
    }, [isConfirming])

    useEffect(() => {
        if (params && params.token) {
            setToken(params.token)   
        }     
    }, [params])

    return (
        <Onboarding title="Inserisci una nuova password" body="Scegli una nuova password e conferma.">

            <Form noValidate validated={validated} onSubmit={handleNewPassword}>
                <Form.Group>
                <Form.Control type="password" value={password} onChange={(e) => setPassword(e.target.value)} placeholder="Password" />
                </Form.Group>
                <Form.Group>
                <Form.Control type="password" value={passwordMatch} onChange={(e) => setPasswordMatch(e.target.value)} placeholder="Conferma password" /></Form.Group>
                <Form.Group>
                    <Button variant="primary"  type="submit"
                        disabled={isConfirming}>Invia</Button>
                </Form.Group>
            </Form>
            </Onboarding>
    )
}

export default ConfirmPassword