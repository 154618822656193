import React from 'react'
import { Modal, Container, ButtonGroup, Button } from 'react-bootstrap'

const DeceasedPosterTypeModal = props => {

    const applyFilter = (allowed) => {
        return Object.keys(props.posterTypes)
            .filter(key => allowed.includes(key))
            .reduce((obj, key) => {
                obj[key] = props.posterTypes[key];
                return obj;
            }, {});
    }

    const filterPosterTypes = () => {
        if (!props.selectedEvent) return []
        switch (props.selectedEvent.type) {
            case 'F':   // Funeral
                return applyFilter(['F', 'FT', 'O'])
            case 'T':   // Thirtieth
                return applyFilter(['T', 'TT', 'RC', 'O'])
            case 'S':   // Requiem
                return applyFilter(['R', 'RT', 'O'])
            case 'O':   // Other
                return applyFilter(['O'])
        }
    }

    const closePosterTypeModalHandler = () => {
        props.onPosterTypeSelected(null)
    }

    const posterTypeSelectedHandler = posterType => {
        props.onPosterTypeSelected(posterType)
    }

    return (
        <Modal show={props.posterTypeModalOpen} onHide={closePosterTypeModalHandler}>
            <Modal.Header closeButton>
                <Modal.Title>Seleziona il tipo di manifesto da aggiungere</Modal.Title>
            </Modal.Header>
            <Modal.Body >
                <Container fluid className="p-0">
                    <ButtonGroup className="w-100" vertical>
                        { Object.entries(filterPosterTypes()).map( ([key, value]) => (
                            <Button key={key} variant="info" size="lg"  onClick={e => posterTypeSelectedHandler(key)}>{value}</Button>
                        ))}
                    </ButtonGroup>
                </Container>
            </Modal.Body>
        </Modal>
    )
}

export default DeceasedPosterTypeModal
