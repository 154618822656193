// import moment from 'moment'
import moment from "moment/min/moment-with-locales";

export const formatDateYYYYMMDD = (text) => {
  const format = "YYYY-MM-DD";
  const date = moment.utc(text).local();
  return date.isValid() ? date.format(format) : "";
};

export const formatDateISO8601 = (text) => {
  const date = moment.utc(text).local();
  // Warning
  // toISOString() substract the timezone from the date
  // 2020-10-02 becomes 2020-10-01T22:00:00
  return date.isValid() ? date.format() : "";
};

export const formatDateLong = (text, withWeekday = true) => {
  moment.locale("it");
  const date = moment(text).local(); // .utc(text)
  return date.isValid()
    ? date.format(withWeekday ? "ddd DD/MM/YYYY" : "DD/MM/YYYY")
    : "";
};

export const formatDateShort = (text) => {
  moment.locale("it");
  const date = moment.utc(text).local();
  return date.isValid() ? date.format("ddd DD/MM") : "";
};

export const formatDayAndMonth = (text) => {
  moment.locale("it");
  const date = moment.utc(text).local();
  return date.isValid() ? date.format("DD MMMM") : "";
};

export const formatTimeShort = (text) => {
  moment.locale("it");
  // ParseZone doesn't apply the time zone
  // const date = moment.parseZone(text)
  const date = moment.utc(text).local();
  return date.isValid() ? date.format("HH:mm") : "";
};

export const formatWeekDay = (text) => {
  moment.locale("it");
  const date = moment.utc(text).local();
  return date.isValid() ? date.format("dddd") : "";
};

export const weekFromDate = (dateParam) => {
  moment.locale("it");
  const date = moment.utc(dateParam).local();

  const startDate = date
    .clone()
    .subtract(date.day(), "days")
    .add(1, "days")
    .startOf("day");
  const endDate = startDate.clone().add(6, "days");

  return {
    startDate,
    endDate,
  };
};

export const formatWeekInfo = (info) => {
  if (info) {
    const startMonth = info.startDate.format("MMMM");
    const startYear = info.startDate.format("YYYY");
    const endMonth = info.endDate.format("MMMM");
    const endYear = info.endDate.format("YYYY");

    const sameMonth = startMonth === endMonth;
    const sameYear = startYear === endYear;

    if (sameMonth) {
      return `${info.startDate.format("DD")} - ${info.endDate.format(
        "DD"
      )} ${info.startDate.format("MMMM YYYY")}`;
    } else if (sameYear) {
      return `${info.startDate.format("DD MMMM")} - ${info.endDate.format(
        "DD MMMM"
      )} ${info.startDate.format("YYYY")}`;
    } else {
      return `${info.startDate.format("DD MMMM YYYY")} - ${info.endDate.format(
        "DD MMMM YYYY"
      )}`;
    }
  }
};
