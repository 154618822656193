import { Link } from 'react-router-dom'
import { Row, Col } from 'react-bootstrap'
import Onboarding from '../layouts/Onboarding'

const WaitingApproval = () => {
    return (
        <Onboarding title="La registrazione è stata presa in carico" body="Un membro dello staff analizzerà la richiesta prima possibile">
            <Row>
                <Col xs={12}>
                    <span> Al termine della validazione da parte dello staff, riceverai una e-mail di conferma all'indirizzo che hai fornito. </span>
                </Col>
            </Row>
        </Onboarding>

    )
}

export default WaitingApproval