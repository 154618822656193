import { getAuthToken } from './auth'
import axios from 'axios'

const baseUrl = process.env.REACT_APP_API_BASE_URL

const getCancelTokenSource = () => axios.CancelToken.source()

const options = (customOptions) => ({
    headers: {
        'Accept': 'application/json',
        // "Access-Control-Allow-Origin": "*",
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + getAuthToken(),
    },
    ...customOptions
})

const multiPartOptions = () => ({
    headers: {
        'Accept': 'application/json',
        'content-type': 'multipart/form-data',
        // "Access-Control-Allow-Origin": "*",
        'Authorization': 'Bearer ' + getAuthToken()
    }
})

const formatError = (err) => {
    const {status, statusText} = err.response
    const error = err.response.data ? err.response.data : err
    return {
        status, statusText, error
    }
}

export { baseUrl, getCancelTokenSource, options, multiPartOptions, formatError }
