import { baseUrl, options, formatError } from './common'
import axios from 'axios'
import { Product } from './types'

const listProducts = async () => {
    try {
        const url = `${baseUrl}/api/products`;
        const response = await axios.get(url, options())
        return response.data as Product[]
    } catch (err) {
        console.log(err)
        return {
            error: err
        }
    }
}

const getProduct = async (id: string | number) => {
    try {
        const url = `${baseUrl}/api/products/${id}`
        const response = await axios.get(url, options())
        return response.data as Product
    } catch (err) {
        return formatError(err)
    }
}

const editProduct = async (id: string | number, fields: Partial<Product>) => {
    try {
        const url = `${baseUrl}/api/products/${id}`
        const response = await axios.put(url, fields, options());
        return response.data as Product
    } catch (err) {
        return formatError(err)
    }
}

const createProduct = async (product: Omit<Product, 'id'>) => {
    try {
        const url = `${baseUrl}/api/products`
        const { posters_set, ...productWithoutPSet } = product;
        const requestData = { ...productWithoutPSet, posters_set: JSON.stringify(posters_set) };
        const response = await axios.post(url, requestData, options())
        return response.data as Product
    } catch (err) {
        return formatError(err)
    }
}

const deleteProduct = async (product: Product) => {
    try {
        const url = `${baseUrl}/api/products/${product.id}`
        const response = await axios.delete(url, options())
        return response.data as Product
    } catch (err) {
        return formatError(err)
    }
}

export { listProducts, getProduct, createProduct, editProduct, deleteProduct }