import { baseUrl, options } from './common'
import axios from 'axios'

const availableSlots = async (productId, areaId) => {
    try {
        const url = `${baseUrl}/api/ads/available-slots?product_id=${productId}&area_id=${areaId}`
        const response = await axios.get(url, options())
        return response.data
    } catch (err) {
        console.log(err)
        return {
            error: err
        }
    }
}

export { availableSlots }