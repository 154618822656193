import React, { useContext } from "react";
import { Link, useHistory } from "react-router-dom";
import UserContext from "../UserContext";
import { userLoggedOut } from "../reducers/actions";

import { Navbar, Nav, NavDropdown, Container } from "react-bootstrap";

const Header = () => {
  const navbar = React.useRef(null);
  console.log(navbar);
  const history = useHistory();
  const { state, dispatch } = useContext(UserContext);
  const logout = () => {
    localStorage.removeItem("tokenrdmbo");
    localStorage.removeItem("userrdmbo");
    dispatch(userLoggedOut());
    history.push("/signin");
  };

  const registryMarkup =
    state && state.user && state.user.role === "A" ? (
      <NavDropdown title="Anagrafiche" id="basic-nav-dropdown">
        <NavDropdown.Item as={Link} to="/agencies">
          Agenzie
        </NavDropdown.Item>
        <NavDropdown.Item as={Link} to="/suppliers">
          Fornitori
        </NavDropdown.Item>
        <NavDropdown.Divider />
        <NavDropdown.Item as={Link} to="/users">
          Utenti
        </NavDropdown.Item>
        <NavDropdown.Item as={Link} to="/deceased">
          Defunti
        </NavDropdown.Item>
        <NavDropdown.Divider />
        <NavDropdown.Item as={Link} to="/areas">
          Aree
        </NavDropdown.Item>
        <NavDropdown.Item as={Link} to="/boards">
          Bacheche
        </NavDropdown.Item>
        <NavDropdown.Divider />
        <NavDropdown.Item as={Link} to="/templates">
          Modelli di manifesto
        </NavDropdown.Item>
        <NavDropdown.Item as={Link} to="/products">
          Prodotti
        </NavDropdown.Item>
      </NavDropdown>
    ) : (
      ""
    );

  const userMarkup =
    state && state.user ? (
      <NavDropdown title={state.user.email}>
        {state.user.role !== "A" ? (
          <>
            <NavDropdown.Item as={Link} to="/profile">
              Profilo
            </NavDropdown.Item>
            <NavDropdown.Divider />
          </>
        ) : null}
        <NavDropdown.Item onClick={logout}>Logout</NavDropdown.Item>
      </NavDropdown>
    ) : (
      ""
    );

  return (
    <Navbar ref={navbar} bg="primary" variant="dark" expand="lg" className="mb-3" sticky="top">
      <Container>
        <Navbar.Brand>RdM</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav>
            {state && state.user ? (
              <>
                {state.user.role === "G" ? (
                  <Nav.Link as={Link} to="/">
                    Calendario
                  </Nav.Link>
                ) : null}
                {state.user.role !== "S" && state.user.role !== "A" ? (
                  <Nav.Link as={Link} to="/deceased">
                    Defunti
                  </Nav.Link>
                ) : null}
                {state.user.role === "A" ? (
                  <Nav.Link as={Link} to="/stores/transactions">
                    Transazioni
                  </Nav.Link>
                ) : null}
                {state.user.role === "S" ? (
                  <Nav.Link as={Link} to="/stores/ads">
                    Pubblicità
                  </Nav.Link>
                ) : state.user.role === "G" ? (
                  <Nav.Link as={Link} to="/stores/posters">
                    Acquista
                  </Nav.Link>
                ) : null}
                {registryMarkup}
                {userMarkup}
              </>
            ) : null}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default Header;
