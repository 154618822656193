import React, { useEffect, useState } from "react";
import { Form, Button, Col } from "react-bootstrap";
import ImageUploader from "../../components/ImageUploader";
import { saveAgency } from "../../api/agencies";

const AgencyForm = (props) => {
  const [agency, setAgency] = useState();
  const [validated, setValidated] = useState(false);
  const [error, setError] = useState({});

  useEffect(() => {
    if (agency?.id !== props.agency.id) {
      setAgency(props.agency);
    }
  }, [props.agency.id]);

  const changeAgencyHandler = (e) => {
    const { name, value } = e.target;
    setAgency({ ...agency, [name]: value });
  };

  const saveAgencyHandler = (e) => {
    (async () => {
      e.preventDefault();
      e.stopPropagation();
      setValidated(true);
      if (!e.currentTarget.checkValidity()) {
        setTimeout(() => {
          alert("Ci sono errori nella scheda.");
        }, 200);
        return;
      }

      const res = await saveAgency(agency);
      if (res.error) {
        setError(res.error);
        setTimeout(() => {
          alert(
            res.error.message ||
            "Si sono verificati errori durante il salvataggio"
          );
        }, 200);
      } else {
        setError([]);
        props.onAgencySaved(res);
        setTimeout(() => {
          alert("I dati sono stati aggiornati.");
          setValidated(false);
        }, 200);
      }
    })();
  };

  const setImage = (data) => {
    if (data) {
      setAgency({
        ...agency,
        attachment: {
          filename: data.filename,
          url: data.url,
        },
      });
    } else {
      if (agency.attachment) {
        const updatedAgency = { ...agency };
        delete updatedAgency.attachment;
        setAgency(updatedAgency);
      }
    }
  };


  const agencyAttachmentImageUrl = item => item?.attachment?.url || '/sample-template.png'

  const userIsNotAdmin = props.user.role !== 'A';

  return (
    <Form noValidate validated={validated} onSubmit={saveAgencyHandler}>

      <Form.Group>
        <Form.Label>Nome</Form.Label>
        <Form.Control type="text" placeholder="Nome dell'agenzia" readOnly={userIsNotAdmin}
          name="name" value={agency?.name || ''} onChange={changeAgencyHandler} maxLength={50} required
          isInvalid={error?.name} />
        <ErrorLabel errors={!agency?.name || agency.name === ''}>Inserici il nome dell'agenzia</ErrorLabel>
      </Form.Group>

      <Form.Group>
        <Form.Label>Indirizzo</Form.Label>
        <Form.Control type="text" placeholder="Indirizzo" readOnly={userIsNotAdmin}
          name="address" value={agency?.address || ''} onChange={changeAgencyHandler} maxLength={50} required />
      </Form.Group>

      <Form.Row>
        <Form.Group as={Col} sm={12} md={8}>
          <Form.Label>Città</Form.Label>
          <Form.Control type="text" placeholder="Città" readOnly={userIsNotAdmin}
            name="city" value={agency?.city || ''} onChange={changeAgencyHandler} maxLength={50} required />
        </Form.Group>

        <Form.Group as={Col} sm={12} md={4}>
          <Form.Label>Provincia</Form.Label>
          <Form.Control type="text" placeholder="Provincia" readOnly={userIsNotAdmin}
            name="prov" value={agency?.prov || ''} onChange={changeAgencyHandler} maxLength={2} required />
        </Form.Group>
      </Form.Row>

      <Form.Row>
        <Form.Group as={Col} sm={12} md={6}>
          <Form.Label>Telefono</Form.Label>
          <Form.Control type="text" placeholder="Telefono"
            name="phone" value={agency?.phone || ''} onChange={changeAgencyHandler} maxLength={15} required />
        </Form.Group>

        <Form.Group as={Col} sm={12} md={6}>
          <Form.Label>Email</Form.Label>
          <Form.Control type="email" placeholder="Email di contatto" readOnly={userIsNotAdmin}
            name="email" value={agency?.email || ''} onChange={changeAgencyHandler} maxLength={50} required />
        </Form.Group>
      </Form.Row>

      <Form.Group>
        <Form.Label>Logo agenzia (inserisci un'immagine)</Form.Label>
        <ImageUploader
          height={'100px'}
          initialImage={agencyAttachmentImageUrl(agency)}
          imageURL={agencyAttachmentImageUrl(agency)}
          uploadedFileHandler={setImage} />
      </Form.Group>

      <Form.Group>
        <Form.Label>Descrizione breve</Form.Label>
        <Form.Control as="textarea" rows={4} placeholder="Descrizione breve"
          name="short_description" value={agency?.short_description || ''} onChange={changeAgencyHandler} required />
      </Form.Group>

      <Form.Group>
        <Form.Label>Descrizione</Form.Label>
        <Form.Control as="textarea" rows={12} placeholder="Descrizione"
          name="description" value={agency?.description || ''} onChange={changeAgencyHandler} required />
      </Form.Group>

      <Form.Row>
        <Form.Group as={Col} sm={12} md={6}>
          <Form.Label>Partita IVA</Form.Label>
          <Form.Control type="text" placeholder="Partita IVA" readOnly={userIsNotAdmin}
            isInvalid={!!error?.vat} pattern="[0-9]{11}"
            name="vat" value={agency?.vat || ''} onChange={changeAgencyHandler} maxLength={11} required />
        </Form.Group>

        <Form.Group as={Col} sm={12} md={6}>
          <Form.Label>Codice Fiscale</Form.Label>
          <Form.Control type="text" placeholder="Codice fiscale" readOnly={userIsNotAdmin}
            isInvalid={!!error?.tax_code} pattern="[0-9]{11}|[A-Za-z]{6}[0-9]{2}[A-Za-z]{1}[0-9]{2}[A-Za-z]{1}[0-9]{3}[A-Za-z]{1}"
            name="tax_code" value={agency?.tax_code || ''} onChange={changeAgencyHandler} maxLength={16} required />
        </Form.Group>
      </Form.Row>
      <Form.Row>
        <Form.Group as={Col} sm={12} md={6}>
          <Form.Label>Codice Destinatario SDI</Form.Label>
          <Form.Control type="text" placeholder="Codice Univoco" readOnly={userIsNotAdmin}
            name="unique_code" value={agency?.unique_code || ''} onChange={changeAgencyHandler} maxLength={10} required />
        </Form.Group>

        <Form.Group as={Col} sm={12} md={6}>
          <Form.Label>Email certificata</Form.Label>
          <Form.Control type="email" placeholder="Email certificata"
            name="certified_mail" value={agency?.certified_mail || ''} onChange={changeAgencyHandler} maxLength={20} required />
        </Form.Group>
      </Form.Row>

      <Button variant="primary" type="submit" >Salva</Button>
    </Form>
  )
}

function ErrorLabel({ errors, children }) {
  if (errors) return <Form.Label className="text-danger">{children}</Form.Label>;
  else return null;
}
export default AgencyForm
