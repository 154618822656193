import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import BaseLayout from '../layouts/BaseLayout'
import AsyncSelect from 'react-select/async'
import { listAreas } from '../../api/areas'
import { listProducts } from '../../api/products'
import { formatDayAndMonth } from '../../utils'
import { Row, Col, Container, Form, Button, Card } from 'react-bootstrap'
import { availableSlots } from '../../api/ads'

const StoreAds = () => {
    const history = useHistory()
    const [validated, setValidated] = useState(false)
    const [selectedSubArea, setSelectedSubArea] = useState('')
    // eslint-disable-next-line
    const [inputSubArea, setInputSubArea] = useState('')
    const [selectedProduct, setSelectedProduct] = useState('')
    // eslint-disable-next-line
    const [inputProduct, setInputProduct] = useState('')
    const [range, setRange] = useState({
        date_start: null,
        date_end: null
    })

    const loadProducts = (inputValue, callback) => {
        setTimeout(() => {
            (async () => {
                const res = await listProducts()
                const keyValues = res.map((item) => {
                    return { value: item.id, label: item.name, price: item.price, description: item.description, vat: item.vat }
                })
                callback(keyValues)
            })()
        }, 1000)
    }

    const loadAreas = (inputValue, callback) => {
        setTimeout(() => {
            (async () => {

                if (inputValue === '') return

                const res = await listAreas('', inputValue, 1)
                const keyValues = res.data.map((item) => {
                    return { value: item.id, label: item.name }
                })

                callback(keyValues)

            })()

        }, 1000)
    }

    useEffect(() => {
        setAvailableSlot()
    }, [selectedProduct, selectedSubArea])

    const handleInputChange = (newValue) => {
        const inputValue = newValue.replace(/\W/g, '')
        setInputSubArea({ inputValue })
    }

    const handleSelectChange = (newValue) => {
        setSelectedSubArea({ value: newValue.value, label: newValue.label })
    }

    const handleInputProductChange = (newValue) => {
        const inputValue = newValue.replace(/\W/g, '')
        setInputProduct({ inputValue })
    }

    const handleSelectProductChange = (newValue) => {
        setSelectedProduct({ value: newValue.value, label: newValue.label, description: newValue.description, price: newValue.price })
    }

    const setAvailableSlot = () => {
        if (selectedSubArea.value !== undefined && selectedProduct.value) {
            (async () => {
                const res = await availableSlots(selectedProduct.value, selectedSubArea.value)
                const availableRange = { date_start: res[0][0], date_end: res[0][1] }
                setRange(availableRange)
            })()
        } else {
            setRange({ date_start: null, date_end: null })
        }
    }

    return (
        <>
            <Container fluid>
                <Card>
                    <Card.Header>
                        <Card.Title>Pubblicizza la tua attività acquistando una posizione</Card.Title>
                        <Card.Subtitle> Per 30 giorni a partire dalla data proposta (slot) il tuo logo apparirà nell'area indicata, occupando la posizione selezionata </Card.Subtitle>
                        <div style={{ float: "right" }} className="my-2">
                            <Button variant="secondary" variant="secondary" onClick={() => history.push('/stores/transactions')}>I tuoi acquisti</Button>
                        </div>
                    </Card.Header>
                    <Card.Body className="px-3">
                        <ul className="list-inline font-12 dl m-r-10">
                            <span>Puoi acquistare la tua posizione con i seguenti metodi di pagamento:</span><br />
                            <li className="list-inline-item">
                                <i className="fab fa-cc-paypal text-info"></i> - PayPal
                            </li>
                            <li >
                                <i className="fab fa-cc-visa text-info"></i> - VISA
                            </li>
                            <li >
                                <i className="fab fa-cc-mastercard text-info"></i> - Mastercard</li>
                        </ul>
                        <Form noValidate validated={validated}>
                            <Form.Group>
                                <Form.Label>Seleziona una area</Form.Label>
                                <AsyncSelect name="area_id" value={selectedSubArea}
                                    placeholder="Inizia a digitare per cercare nell'elenco"
                                    loadOptions={loadAreas}
                                    defaultOptions
                                    onInputChange={handleInputChange}
                                    onChange={handleSelectChange} />
                            </Form.Group>

                            <Form.Group controlId="exampleForm.ControlSelect1">
                                <Form.Label>Seleziona una posizione</Form.Label>
                                <AsyncSelect name="product_id" value={selectedProduct}
                                    placeholder="Seleziona dall'elenco"
                                    loadOptions={loadProducts}
                                    defaultOptions
                                    onInputChange={handleInputProductChange}
                                    onChange={handleSelectProductChange} />
                            </Form.Group>

                            <Form.Group>
                                <Form.Label>Acquista per 30 giorni nel seguente periodo</Form.Label>
                                <div >
                                    <div className="box bg-success text-center">
                                        <h1></h1>
                                        <h5 >
                                            {(range.date_start != null && range.date_end != null) ?
                                                formatDayAndMonth(range.date_start) + '-' + formatDayAndMonth(range.date_end)
                                                :
                                                'Seleziona data e posizione'}
                                        </h5>
                                    </div>
                                </div>
                            </Form.Group>
                            <div>
                                <hr></hr>
                                <Button disabled={!selectedProduct || !selectedSubArea} variant="primary"
                                    onClick={() => history.push('/stores/checkout/' + selectedProduct.value + '?area_id=' + selectedSubArea.value + '&area_name=' + selectedSubArea.label + '&date_start=' + range.date_start + '&date_end=' + range.date_end)}>
                                    Paga con PayPal
                                </Button>
                            </div>
                        </Form>
                    </Card.Body>
                </Card>
            </Container>
        </>
    )
}

export default StoreAds