import { baseUrl, options } from './common'
import axios from 'axios'

const listSuppliers = async (name = '', pageIx, status = '') => {
    try {
        const url = `${baseUrl}/api/suppliers?name=${name}&page=${pageIx}&status=${status}`;
        const response = await axios.get(url, options())
        return response.data
    } catch (err) {
        console.log(err)
        return {
            error: err
        }
    }
}

const getSupplier = async (id) => {
    try {
        const url = `${baseUrl}/api/suppliers/${id}`;
        const response = await axios.get(url, options())
        return response.data
    } catch (err) {
        console.log(err)
        return {
            error: err
        }
    }
}

const saveSupplier = async (supplier) => {
    try {
        let isNew = (supplier.id === null)
     
        let url = `${baseUrl}/api/suppliers`
        

        let response
        if (isNew) {
            response = await axios.post(url, supplier, options())
        } else {
            url += `/${supplier.id}`
            response = await axios.put(url, supplier, options())
        }
        return response.data
    } catch (err) {
        console.log(err)
        return {
            error: err.response ? err.response.data : err
        }
    }
}

const deleteSupplier = async(supplier) => {
    try {
        const url = `${baseUrl}/api/suppliers/${supplier.id}`
        const response = await axios.delete(url, options())
        return response.data
    } catch (err) {
        console.log(err)
        return {
            error: err
        }
    }
}

export { listSuppliers, getSupplier, saveSupplier, deleteSupplier }