import * as actions from './actionTypes'

export const initialState = {
    user: null,
    loading: false
}

export const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actions.USER_LOGGED_IN:
            return {...state, user: action.payload}
        case actions.USER_LOGGED_OUT:
            return {...state, user: null}
        case actions.CONTENT_LOADING:
            return {...state, loading: true}
        case actions.CONTENT_LOADED:
            return {...state, loading: false}
        default:
            return state
    }
}
