import { baseUrl, options, formatError } from './common'
import axios from 'axios'

const getProfile = async () => {
    try {
        const url = `${baseUrl}/profile`;
        const response = await axios.get(url, options())
        return response.data
    } catch (err) {
        return formatError(err)
    }
}

const listUsers = async (name = '', pageIx = 1) => {
    try {
        const url = `${baseUrl}/api/users?page=${pageIx}&email=${name}`;
        const response = await axios.get(url, options())
        return response.data
    } catch (err) {
        return formatError(err)
    }
}

const blockUser = async(user) => {
    try {
        const url = `${baseUrl}/api/users/${user.id}/block`
        const response = await axios.post(url, null, options())
        return response.data
    } catch (err) {
        return formatError(err)
    }
}

const unlockUser = async(user) => {
    try {
        const url = `${baseUrl}/api/users/${user.id}/unlock`
        const response = await axios.post(url, null, options())
        return response.data
    } catch (err) {
        return formatError(err)
    }
}

export { listUsers, blockUser, unlockUser, getProfile }