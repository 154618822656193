import { useEffect, useState, useContext } from "react";
import UserContext from "../../UserContext";
import { contentLoading, contentLoaded } from "../../reducers/actions";
import {
  Container,
  Row,
  Col,
  Table,
  ButtonToolbar,
  ButtonGroup,
  Button,
  Form,
  Card,
} from "react-bootstrap";
import { useHistory, useLocation } from "react-router-dom";
import { listSuppliers } from "../../api/suppliers";
import { blockUser, unlockUser } from "../../api/users";
import Pagination from "../../components/Pagination";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEdit,
  faLock,
  faSearch,
  faUnlock,
} from "@fortawesome/free-solid-svg-icons";
import { getLabelFromUserStatus, getColorFromUserStatus } from "../../utils/typeUtils";
import GoBack from "../../components/GoBack";

const Suppliers = () => {
  const { dispatch } = useContext(UserContext);
  const location = useLocation();
  const history = useHistory();
  const [filter, setFilter] = useState("");
  const [params, setParams] = useState();
  const [data, setData] = useState();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    setParams({
      pageNo: searchParams.get("page") || 1,
      filter: searchParams.get("name") || "",
      status: searchParams.get("status") || ""
    });
  }, [location]);

  useEffect(() => {
    if (params) {
      (async () => {
        dispatch(contentLoading());
        const res = await listSuppliers(params.filter, params.pageNo, params.status);
        setData(res);
        dispatch(contentLoaded());
      })();
    }
  }, [params]);

  const changeFilter = (e) => {
    e.preventDefault();
    // Always reset page to 1
    history.push(location.pathname + "?name=" + filter);
  };

  const pageChangedHandler = (page) => {
    let url = location.pathname + "?page=" + page;
    if (params.filter) url += "&name=" + params.filter;
    history.push(url);
  };

  const editItem = (supplier) => {
    history.push(`/suppliers/${supplier.id}/edit`);
  };

  const blockItem = (supplier) => {
    if (
      !window.confirm(
        `Vuoi cambiare lo stato dell'utente\n"${supplier.user.email}"?`
      )
    )
      return;

    (async () => {
      const res =
        supplier.user.status === "A"
          ? await blockUser(supplier.user)
          : await unlockUser(supplier.user);

      const index = data.data.findIndex(
        (supplierTmp) => supplierTmp.id === supplier.id
      );

      const updatedData = { ...data };
      updatedData.data[index].user = res;

      setData(updatedData);
    })();
  };

  return (
    <Card>
      <Card.Header>
        <Card.Title><GoBack /> Fornitori</Card.Title>
      </Card.Header>
      <Card.Body>
        <Container fluid>
          <Row className="align-items-center">
            <Col xs={12} md={8}>
              <Form onSubmit={changeFilter}>
                <Row className="align-items-center">
                  <Col className="pr-0">
                    <Form.Control
                      type="text"
                      placeholder="Cerca per cognome..."
                      onChange={(e) => setFilter(e.target.value)}
                      value={filter}
                    />
                  </Col>
                  <Col className="pl-1">
                    <Button type="submit">
                      <FontAwesomeIcon icon={faSearch} />
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <Table responsive hover className="mt-2">
                <thead>
                  <tr>
                    <th>Nome</th>
                    <th className="d-xs-none" >Città</th>
                    <th className="d-xs-none" >Utente</th>
                    <td></td>
                  </tr>
                </thead>
                <tbody>
                  {data && data.data ? (
                    data.data.map((supplier) => (
                      <tr key={supplier.id}>
                        <td style={{ display: "table-cell" }}>
                          <strong>
                            {supplier.name ? supplier.name : "Nuovo fornitore"}
                          </strong>
                          <br />
                          <small
                            className={
                              getColorFromUserStatus(supplier.user.status)
                            }
                          >
                            {getLabelFromUserStatus(supplier.user.status)}
                          </small>
                        </td>
                        <td className="d-xs-none" >
                          {supplier.city} / {supplier.prov}
                        </td>
                        <td className="d-xs-none" >{supplier.user.email}</td>
                        <td className="d-flex justify-content-end">
                          <ButtonToolbar>
                            <ButtonGroup>
                              <Button
                                variant="secondary"
                                onClick={(e) => editItem(supplier)}
                              >
                                <FontAwesomeIcon icon={faEdit} />
                              </Button>
                              {supplier.user.status === "I" || supplier.user.status === 'L' || supplier.user.status === 'P' ? (
                                <Button
                                  variant="danger"
                                  onClick={() => blockItem(supplier)}
                                >
                                  <FontAwesomeIcon icon={faLock} />
                                </Button>
                              ) : (
                                <Button
                                  variant="secondary"
                                  onClick={() => blockItem(supplier)}
                                >
                                  <FontAwesomeIcon icon={faUnlock} />
                                </Button>
                              )}
                            </ButtonGroup>
                          </ButtonToolbar>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="4">No result</td>
                    </tr>
                  )}
                </tbody>
                <tfoot>
                  <tr>
                    <td colSpan="5" style={{ paddingBottom: 0 }}>
                      <Pagination
                        currentPage={data?.current_page}
                        totPages={data?.last_page}
                        pageChanged={pageChangedHandler}
                      />
                    </td>
                  </tr>
                </tfoot>
              </Table>
            </Col>
          </Row>
        </Container>
      </Card.Body>
    </Card>
  );
};

export default Suppliers;
