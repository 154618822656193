import {
  faCheck,
  faDownload,
  faEye,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import {
  Modal,
  Container,
  Row,
  Col,
  Card,
  ButtonToolbar,
  ButtonGroup,
  Button,
  ToggleButton,
} from "react-bootstrap";
import { formatDateLong } from "../../utils";

const DeceasedPostersList = (props) => {
  const [postersListVisibility, setPostersListVisibility] = useState(false);
  const [groupedItems, setGroupedItems] = useState();
  const [userPostersOnly, setUserPostersOnly] = useState(false);

  useEffect(() => {
    prepareGrid();
    setPostersListVisibility(!!props.selectedEvent);
  }, [props.selectedEvent, userPostersOnly]);

  const closePostersListHandler = (item) => {
    props.onPosterSelected(item);
  };

  const prepareGrid = () => {
    if (!props.selectedEvent) return;
    const size = 3;
    const posters = userPostersOnly
      ? props.selectedEvent.posters.filter((item) => item.type === "U")
      : props.selectedEvent.posters;
    const groupedArray = [];
    for (var i = 0; i < posters.length; i += size) {
      groupedArray.push(posters.slice(i, i + size));
    }
    setGroupedItems(groupedArray);
  };

  // #region Poster toolbar

  const editPosterHandler = (e, item) => {
    e.stopPropagation();
    props.onEditPoster(item);
  };

  const deletePosterHandler = (e, item) => {
    e.stopPropagation();
    props.onDeletePoster(item);
  };

  const previewPosterHandler = (e, item) => {
    e.stopPropagation();
    props.onPosterPreview(item);
  };

  const downloadPosterHandler = (e, item) => {
    e.stopPropagation();
    props.onPosterDownload(item);
  };

  // #endregion Poster toolbar

  // #region Poster approve / reject

  const approvePosterHandler = (item) => {
    props.onApprovePoster(item);
  };

  const rejectPosterHandler = (item) => {
    props.onRejectPoster(item);
  };

  // #endregion Poster approve / reject

  return (
    <Modal
      size="lg"
      show={postersListVisibility}
      onHide={closePostersListHandler}
    >
      <Modal.Header closeButton>
        <Modal.Title>Manifesti</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ overflow: "hidden", height: "70vh" }}>
        <ButtonToolbar aria-label="Toolbar with button groups">
          <ButtonGroup toggle>
            <ToggleButton
              type="checkbox"
              variant="secondary"
              checked={userPostersOnly}
              value="1"
              onChange={(e) => setUserPostersOnly(e.currentTarget.checked)}
            >
              <span></span>{" "}
              {userPostersOnly
                ? "Mostra tutti"
                : "Mostra solo quelli degli utenti"}
            </ToggleButton>
          </ButtonGroup>
        </ButtonToolbar>

        <div
          class="mt-3"
          style={{
            display: "grid",
            gridGap: "1rem",
            gridTemplateColumns: "auto auto auto auto",
          }}
        >
          {groupedItems?.map((group, id) => {
            return group.map((poster) => {
              if (poster.type === "A") {
                return (
                  <Card style={{ width: 180 }}>
                    <Card.Header>
                      <Card.Title>
                        {props.posterTypes[poster.poster_type]}
                      </Card.Title>
                      <Card.Subtitle>
                        <small>
                          Aggiunto dall'agenzia il{" "}
                          {formatDateLong(poster.created_at, false)}
                        </small>
                      </Card.Subtitle>
                    </Card.Header>
                    <ButtonGroup size="sm" vertical>
                      <Button
                        variant="info"
                        onClick={(e) => editPosterHandler(e, poster)}
                        title="Apri il manifesto"
                      >
                        Modifica
                      </Button>
                      <Button
                        variant="info"
                        onClick={(e) => previewPosterHandler(e, poster)}
                        title="Anteprima del manifesto"
                      >
                        Anteprima <FontAwesomeIcon icon={faEye} />
                      </Button>
                      <Button
                        variant="info"
                        onClick={(e) => downloadPosterHandler(e, poster)}
                        title="Scarica il manifesto"
                      >
                        Scarica PDF <FontAwesomeIcon icon={faDownload} />
                      </Button>
                      <Button
                        variant="danger"
                        onClick={(e) => deletePosterHandler(e, poster)}
                        title="Elimina il manifesto"
                      >
                        Elimina <FontAwesomeIcon icon={faTrash} />
                      </Button>
                    </ButtonGroup>
                  </Card>
                );
              } else {
                return (
                  <Card style={{ width: 180 }}>
                    <Card.Header>
                      <Card.Title>
                        Aggiunto dall'utente {poster.user.email}
                      </Card.Title>
                      <Card.Subtitle>
                        <small>
                          il {formatDateLong(poster.created_at, false)}
                        </small>
                      </Card.Subtitle>
                    </Card.Header>
                    <Card.Body>
                      {poster.state === "R" ? (
                        <span>scartato</span>
                      ) : poster.state === "A" ? (
                        <span>approvato</span>
                      ) : null}
                    </Card.Body>
                    <ButtonGroup size="sm" vertical>
                      <Button
                        variant="info"
                        onClick={(e) => previewPosterHandler(e, poster)}
                        title="Anteprima del manifesto"
                      >
                        Anteprima <FontAwesomeIcon icon={faEye} />
                      </Button>
                      <Button
                        variant="success"
                        onClick={(e) => approvePosterHandler(poster)}
                        title="Approva il manifesto dell'utente"
                        hidden={poster.state !== "P"}
                      >
                        Approva <FontAwesomeIcon icon={faCheck} />
                      </Button>
                      <Button
                        variant="danger"
                        onClick={(e) => rejectPosterHandler(poster)}
                        title="Scarta il manifesto dell'utente"
                        hidden={poster.state !== "P"}
                      >
                        Scarta <FontAwesomeIcon icon={faTrash} />
                      </Button>
                    </ButtonGroup>
                  </Card>
                );
              }
            });
          })}
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default DeceasedPostersList;
