import React, { useEffect, useState, useContext } from "react";
import UserContext from "../../UserContext";
import { contentLoading, contentLoaded } from "../../reducers/actions";
import { Link, useHistory, useParams } from "react-router-dom";
import { Tabs, Tab, Row, Col, Container, Card } from "react-bootstrap";
import BaseLayout from "../layouts/BaseLayout";
import DeceasedForm from "./DeceasedForm";
import DeceasedEvents from "./DeceasedEvents";
import DeceasedAttachments from "./DeceasedAttachments";
import { getDeceased, saveDeceased } from "../../api/deceased";
import { listEventsTypes } from "../../api/events";
import { formatDateISO8601 } from "../../utils";
import { listPosterTypes } from "../../api/posters";
import { faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import GoBack from "../../components/GoBack";

const DeceasedEditor = () => {
  const { dispatch } = useContext(UserContext);
  const history = useHistory();
  const { deceasedId } = useParams();
  const [deceased, setDeceased] = useState({
    id: null,
    firstname: "",
    lastname: "",
    gender: "M",
    birth: "",
    death: "",
    address: "",
    city: "",
    prov: "",
    posters: [],
  });
  const [validated, setValidated] = useState(false);
  const [errors, setErrors] = useState();
  const [eventTypes, setEventTypes] = useState([]);
  const [posterTypes, setPosterTypes] = useState([]);

  useEffect(() => {
    if (deceasedId) {
      (async () => {
        dispatch(contentLoading());
        const eventTypesRes = await listEventsTypes();
        setEventTypes(eventTypesRes);
        const deceasedRes = await getDeceased(deceasedId);
        setDeceased(deceasedRes.deceased);
        const posterTypesRes = await listPosterTypes();
        setPosterTypes(posterTypesRes);
        dispatch(contentLoaded());
      })();
    }
  }, [deceasedId]);

  const changeDeceasedFieldHandler = (e) => {
    const { type, name, value } = e.target;
    // setDeceased({ ...deceased, [e.target.name]: e.target.value })
    setDeceased({
      ...deceased,
      [name]: type === "date" ? formatDateISO8601(value) : value,
    });
  };

  const saveDeceasedHandler = (e) => {
    (async () => {
      e.preventDefault();
      e.stopPropagation();
      setValidated(true);
      if (!e.currentTarget.checkValidity()) return;
      const res = await saveDeceased(deceased);
      if (res.error) {
        console.warn(res.error);
        setErrors(res.error);
        alert("Si sono verificati errori");
      } else {
        setErrors({});
        history.goBack();
      }
    })();
  };

  const saveEventHandler = (event) => {
    if (!event.posters) event.posters = [];
    const events = [...deceased.events];
    const index = events.findIndex((item) => item.id === event.id);
    if (index > -1) {
      events[index] = event;
    } else {
      events.push(event);
    }
    // TOOS Sorting by date
    // events.sort( (first, second) => {
    //     return first.date > second.date ? -1 : 1
    // })
    setDeceased({ ...deceased, events });
  };

  const eventDeletedHandler = (event) => {
    const events = [...deceased.events];
    const index = events.findIndex((item) => item.id === event.id);
    events.splice(index, 1);
    setDeceased({ ...deceased, events });
  };

  const savePosterHandler = (poster) => {
    const events = [...deceased.events];
    const eventIndex = events.findIndex((item) => item.id === poster.event_id);
    const posterIndex = events[eventIndex].posters.findIndex(
      (item) => item.id === poster.id
    );
    if (posterIndex > -1) {
      events[eventIndex].posters[posterIndex] = poster;
    } else {
      events[eventIndex].posters.push(poster);
    }
    setDeceased({ ...deceased, events });
  };

  const deletePosterHandler = (poster) => {
    const events = [...deceased.events];
    const eventIndex = events.findIndex((item) => item.id === poster.event_id);
    const posterIndex = events[eventIndex].posters.findIndex(
      (item) => item.id === poster.id
    );
    events[eventIndex].posters.splice(posterIndex, 1);
    setDeceased({ ...deceased, events });
  };

  const attachmentSavedHandler = (attachment) => {
    const attachments = [...deceased.attachments];
    const index = attachments.findIndex((item) => item.id === attachment.id);
    if (index > -1) {
      attachments[index] = attachment;
    } else {
      attachments.push(attachment);
    }
    setDeceased({ ...deceased, attachments });
  };

  const attachmentDeletedHandler = (attachment) => {
    const attachments = [...deceased.attachments];
    const index = attachments.findIndex((item) => item.id === attachment.id);
    attachments.splice(index, 1);
    setDeceased({ ...deceased, attachments });
  };

  return (
    <Card>
      <Card.Header>
        <Card.Title>
          <GoBack />
          {deceasedId ? "Modifica defunto" : "Nuovo defunto"}
        </Card.Title>
      </Card.Header>
      <Card.Body>
        <Container fluid>
          <Row>
            <Col>
              <Tabs defaultActiveKey="data" id="deceased-tabs">
                <Tab eventKey="data" title="Anagrafica">
                  <Container fluid className="mt-3">
                    <DeceasedForm
                      deceased={deceased}
                      errors={errors}
                      validated={validated}
                      changeDeceasedFieldHandler={changeDeceasedFieldHandler}
                      saveDeceasedHandler={saveDeceasedHandler}
                    />
                  </Container>
                </Tab>
                <Tab eventKey="events" title="Eventi" disabled={!deceasedId}>
                  <Container fluid className="mt-3">
                    <DeceasedEvents
                      deceased={deceased}
                      posterTypes={posterTypes}
                      eventTypes={eventTypes}
                      onEventSaved={saveEventHandler}
                      eventDeletedHandler={eventDeletedHandler}
                      onPosterSaved={savePosterHandler}
                      onPosterDeleted={deletePosterHandler}
                    />
                  </Container>
                </Tab>
                <Tab
                  eventKey="attachments"
                  title="Allegati"
                  disabled={!deceasedId}
                >
                  <Container fluid className="mt-3">
                    <DeceasedAttachments
                      deceased={deceased}
                      attachmentSavedHandler={attachmentSavedHandler}
                      attachmentDeletedHandler={attachmentDeletedHandler}
                    />
                  </Container>
                </Tab>
              </Tabs>
            </Col>
          </Row>
        </Container>
      </Card.Body>
    </Card>
  );
};

export default DeceasedEditor;
