import React, { useEffect, useState } from 'react'
import { Card, ListGroup } from 'react-bootstrap'
import { transactionInfoGrouped } from '../../api/payments'

const AgencyWallet = props => {
    const [data, setData] = useState()

    useEffect(() => {
        if (props.agency && props.agency.id) {
            (async () => {
                const res = await transactionInfoGrouped(props.agency.user_id)
                setData(res)
            })()
        }
    }, [props.agency.id])

    const getBadge = item => {
        const totProducts = parseInt(item.tot_purchased_qt)
        const totUsed = parseInt(item.tot_used)
        const className = (totProducts > totUsed) ? 'info' : 'warning'
        return (
            <span className={`badge badge-${className} float-right`}>
                Usati {totUsed} su {totProducts}
            </span>
        )
    }

    return (
        <>
                {data && (data.length > 0) ? data.map((item, ix) => (
                    <ListGroup key={`key${ix}`}>
                        <ListGroup.Item>
                            <i ></i>
                            {item.product_name}
                            {getBadge(item)}
                        </ListGroup.Item>
                    </ListGroup>
                )) : (
                    <h6>Nessun dato da mostrare</h6>
                )}
        </>
    )
}

export default AgencyWallet