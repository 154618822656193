import React, { useEffect, useState } from 'react'
import { Map, GoogleApiWrapper, Marker } from 'google-maps-react';


const key = process.env.REACT_APP_GOOGLE_MAPS_API_KEY

const mapStyles = {
    width: '100%',
    height: '100%'
}
const initialZoom = 17

const MapContainer = (props) => {
    const [markerPos, setMarkerPos] = useState(props.initialPos)

    useEffect(() => {
        console.log(props.initialPos)
    }, [])


    const mapClicked = (mapProps, map, clickEvent) => {
        const latLng = {
            lat: clickEvent.latLng.lat(),
            lng: clickEvent.latLng.lng()
        }
        setMarkerPos(latLng)

        if (props.posChanged) {
            props.posChanged({latLng})
        }
    }

    // roadmap, satellite, hybrid, terrain
    return (
        <Map google={props.google} zoom={initialZoom} style={mapStyles} mapType="satellite" 
            initialCenter={props.mapCenter}
            onClick={mapClicked}>
            <Marker
                visible={!!markerPos}
                title=''
                name=''
                position={markerPos} />
        </Map>
    )
}

// export MapContainer

export default GoogleApiWrapper({apiKey: key})(MapContainer)
