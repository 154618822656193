import React, { useContext, useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { Container, Tabs, Tab, Row, Col, Button, Card } from "react-bootstrap";
import UserContext from "../../UserContext";
import { getAgency } from "../../api/agencies";
import BaseLayout from "../layouts/BaseLayout";
import AgencyForm from "./AgencyForm";
import AgencyWallet from "./AgencyWallet";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import GoBack from "../../components/GoBack";

const AgencyEditor = (props) => {
  const { state } = useContext(UserContext);
  const history = useHistory();
  const { agencyId } = useParams();
  const [agency, setAgency] = useState(
    props.agency
      ? props.agency
      : {
          id: null,
          user_id: null,
          name: "",
          email: "",
          description: "",
          short_description: "",
          address: "",
          city: "",
          prov: "",
          phone: "",
          certified_mail: "",
          unique_code: "",
          vat: "",
          tax_code: "",
        }
  );
  const [transactionButtonVisible, setTransactionButtonVisible] =
    useState(false);

  useEffect(() => {
    if (props.agency) {
      console.log(`Setting agency with id ${props.agency.id}`);
      setAgency(props.agency);
    } else if (agencyId > 0) {
      if (agency?.id !== agencyId) {
        (async () => {
          const res = await getAgency(agencyId);
          setAgency(res);
        })();
      }
    }
  }, []);

  const savedAgencyHandler = (updatedAgency) => {
    setAgency(updatedAgency);
  };

  const userIsNotAdmin = state?.user?.role !== 'A';

  return (
    <Card>
      <Card.Header>
        <Card.Title>
          <GoBack buttonProps={{hidden: userIsNotAdmin}} />
          {agency.id ? "Modifica agenzia" : "Nuova agenzia"}
        </Card.Title>
        <div style={{ float: "right" }}>
          <Button
            variant="secondary"
            hidden={!transactionButtonVisible}
            onClick={() => history.push("/stores/transactions")}
          >
            I tuoi acquisti
          </Button>
        </div>
      </Card.Header>
      <Card.Body>
        <Container fluid>
          <Row>
            <Col>
              <Tabs
                defaultActiveKey="data"
                id="agency-tabs"
                onSelect={(k) => setTransactionButtonVisible(k === "wallet")}
              >
                <Tab eventKey="data" title="Anagrafica">
                  <Container className="mt-3">
                    <AgencyForm
                      agency={agency}
                      onAgencySaved={savedAgencyHandler}
                      user={state?.user}
                    />
                  </Container>
                </Tab>
                <Tab eventKey="wallet" title="Portafoglio manifesti">
                  <Container className="mt-3">
                    <AgencyWallet agency={agency} />
                  </Container>
                </Tab>
              </Tabs>
            </Col>
          </Row>
        </Container>
      </Card.Body>
    </Card>
  );
};

export default AgencyEditor;
