import { baseUrl, formatError, options } from './common'
import axios from 'axios'

const targetTypes = {
    "*": "Tutti",
    "G": "Agenzie",
    "U": "Utenti"
}

const listTemplateLayouts = async () => {
    try {
        const url = `${baseUrl}/api/templates/layouts`
        const response = await axios.get(url, options())
        return response.data
    } catch (err) {
        return formatError(err)
    }
}

const listTemplates = async (name = '', posterType = '', pageIx = 1) => {
    try {
        const url = `${baseUrl}/api/templates?page=${pageIx}&name=${name}&poster_type=${posterType}`
        const response = await axios.get(url, options())
        return response.data
    } catch (err) {
        console.log(err)
        return {
            error: err
        }
    }
}

const getTemplate = async (id) => {
    try {
        const url = `${baseUrl}/api/templates/${id}`;
        const response = await axios.get(url, options())
        return response.data
    } catch (err) {
        return formatError(err)
    }
}

const saveTemplate = async (template) => {
    try {
        let isNew = (template.id === null)
     
        let url = `${baseUrl}/api/templates`

        let response
        if (isNew) {
            response = await axios.post(url, template, options())
        } else {
            url += `/${template.id}`
            response = await axios.put(url, template, options())
        }
        return response.data
    } catch (err) {
        return formatError(err)
    }
}

const deleteTemplate = async(template) => {
    try {
        const url = `${baseUrl}/api/templates/${template.id}`
        const response = await axios.delete(url, options())
        return response.data
    } catch (err) {
        return formatError(err)
    }
}

export { targetTypes, listTemplateLayouts, listTemplates, deleteTemplate, saveTemplate, getTemplate }