import React, { useReducer } from "react";
import { BrowserRouter } from "react-router-dom";
import Routing from "./Routing";
import UserContext from "./UserContext";
import { reducer, initialState } from "./reducers/userReducer";
import "./App.scss";
import BaseLayout from "./views/layouts/BaseLayout"

function App() {
  const [state, dispatch] = useReducer(reducer, initialState);
  return (
    <UserContext.Provider value={{ state, dispatch }}>
      <BrowserRouter>
        <BaseLayout>
          <Routing />
        </BaseLayout>
      </BrowserRouter>
    </UserContext.Provider>
  );
}

export default App;
