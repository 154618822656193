import { baseUrl, options, formatError } from './common'
import axios from 'axios'

const listPosterTypes = async () => {
    try {
        const url = `${baseUrl}/api/posters/types`;
        const response = await axios.get(url, options())
        return response.data
    } catch (err) {
        return formatError(err)
    }
}

const listPosters = async (name = '', pageIx = 1) => {
    try {
        const response = await axios.get(`${baseUrl}/api/posters?page=${pageIx}&name=${name}`, options())
        return response.data
    } catch (err) {
        return formatError(err)
    }
}

const savePoster = async (deceased, poster) => {
    try {
        const response = poster.id ?
            await axios.put(`${baseUrl}/api/deceased/${deceased.id}/posters/${poster.id}`, poster, options()) : 
            await axios.post(`${baseUrl}/api/deceased/${deceased.id}/posters`, poster, options())
        return response.data
    } catch (err) {
        return formatError(err)
    }
}

const deletePoster = async (deceased, poster) => {
    try {
        const url = `${baseUrl}/api/deceased/${deceased.id}/posters/${poster.id}`
        const response = await axios.delete(url, options())
        return response.data
    } catch (err) {
        return formatError(err)
    }
}

const approvePoster = async (deceased, poster) => {
    try {
        const url = `${baseUrl}/api/deceased/${deceased.id}/posters/${poster.id}/approve`
        const response = await axios.post(url, null, options())
        return response.data
    } catch (err) {
        return formatError(err)
    }
}

const rejectPoster = async (deceased, poster) => {
    try {
        const url = `${baseUrl}/api/deceased/${deceased.id}/posters/${poster.id}/reject`
        const response = await axios.post(url, null, options())
        return response.data
    } catch (err) {
        return formatError(err)
    }
}

const previewPosterUrl = (deceased, poster) => `${baseUrl}/api/deceased/${deceased.id}/posters/${poster.id}/preview`

const previewPoster = async (deceased, poster) => {
    try {
        // const url = `${baseUrl}/api/deceased/${deceased.id}/posters/${poster.id}/preview`
        const url = previewPosterUrl(deceased, poster)
        const response = await axios.get(url, options())
        return response.data
    } catch (err) {
        return formatError(err)
    }
}

const downloadPoster = async (deceased, poster) => {
    try {
        const url = `${baseUrl}/api/deceased/${deceased.id}/posters/${poster.id}/export`
        const response = await axios.get(url, {
                responseType: 'blob',
                headers: {
                    'Accept': '*/*',
                    'Content-Type': 'application/pdf'
                }})
        const file = new Blob(
            [response.data], 
            {type: 'application/pdf'})
        return file
    } catch (err) {
        return formatError(err)
    }
}

export { listPosterTypes, listPosters, savePoster, deletePoster, approvePoster, rejectPoster, previewPosterUrl, previewPoster, downloadPoster }
