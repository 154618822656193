import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Form, Button, Col } from 'react-bootstrap'
import ImageUploader from '../../components/ImageUploader'
import { saveSupplier } from '../../api/suppliers'

const SupplierForm = props => {
    const history = useHistory()

    const [supplier, setSupplier] = useState()
    const [validated, setValidated] = useState(false)
    const [error, setError] = useState({})

    useEffect(() => {
        if (supplier?.id !== props.supplier.id) {
            setSupplier(props.supplier)
        }
    }, [props.supplier.id])

    const changeSupplierHandler = e => {
        const {name, value} = e.target
        setSupplier({...supplier, [name]: value})
    }

    const saveSupplierHandler = e => {
        (async () => {
            e.preventDefault()
            e.stopPropagation()
            setValidated(true)
            if (!e.currentTarget.checkValidity()) {
                setTimeout(() => {
                    alert('Ci sono errori nella scheda.')
                }, 200);
                return
            }

            const res = await saveSupplier(supplier)
            if (res.error) {
                setError(res.error)
                setTimeout(() => {
                    alert(res.error.message || 'Si sono verificati errori durante il salvataggio')
                }, 200)
            } else {
                setError([])
                props.onSupplierSaved(res)
                setTimeout(() => {
                    alert('I dati sono stati aggiornati.')
                }, 200)
            }
        })()
    }

    const setImage = data => {
        if (data) {
            setSupplier({...supplier, attachment: {
                filename: data.filename,
                url: data.url
            }})
        } else {
            if (supplier.attachment) {
                const updatedSupplier = {...supplier}
                delete updatedSupplier.attachment
                setSupplier(updatedSupplier)
            }
        }
    }

    const supplierAttachmentImageUrl = item => item?.attachment?.url || '/sample-template.png'


    return (
        <Form noValidate validated={validated} onSubmit={saveSupplierHandler}>

            <Form.Group>
                <Form.Label>Nome</Form.Label>
                <Form.Control type="text" placeholder="Nome del fornitore"
                    name="name" value={supplier?.name || ''} onChange={changeSupplierHandler} maxLength={50} required
                    isInvalid={error?.name} />
                <ErrorLabel errors={!supplier?.name || supplier.name === ''} >Inserici il nome del fornitore</ErrorLabel>
            </Form.Group>

            <Form.Group>
                <Form.Label>Indirizzo</Form.Label>
                <Form.Control type="text" placeholder="Indirizzo"
                    name="address" value={supplier?.address || ''} onChange={changeSupplierHandler} maxLength={50} required />
            </Form.Group>

            <Form.Row>
                <Form.Group as={Col} sm={12} md={8}>
                    <Form.Label>Città</Form.Label>
                    <Form.Control type="text" placeholder="Città"
                        name="city" value={supplier?.city || ''} onChange={changeSupplierHandler} maxLength={50} required />
                </Form.Group>
                <Form.Group as={Col} sm={12} md={4}>
                    <Form.Label>Provincia</Form.Label>
                    <Form.Control type="text" placeholder="Provincia"
                        name="prov" value={supplier?.prov || ''} onChange={changeSupplierHandler} maxLength={2} required />
                </Form.Group>
            </Form.Row>

            <Form.Row>
                <Form.Group as={Col} sm={12} md={6}>
                    <Form.Label>Telefono</Form.Label>
                    <Form.Control type="text" placeholder="Telefono"
                        name="phone" value={supplier?.phone || ''} onChange={changeSupplierHandler} maxLength={15} required />
                </Form.Group>
                <Form.Group as={Col} sm={12} md={6}>
                    <Form.Label>Email</Form.Label>
                    <Form.Control type="email" placeholder="Email di contatto"
                        name="email" value={supplier?.email || ''} onChange={changeSupplierHandler} maxLength={50} required />
                </Form.Group>
            </Form.Row>

            <Form.Group>
                <Form.Label>Logo fornitore (inserisci un'immagine)</Form.Label>
                <ImageUploader
                    height={'100px'}
                    initialImage={supplierAttachmentImageUrl(supplier)}
                    imageURL={supplierAttachmentImageUrl(supplier)}
                    uploadedFileHandler={setImage} />
            </Form.Group>

            <Form.Group>
                <Form.Label>Descrizione breve</Form.Label>
                <Form.Control as="textarea" rows={4} placeholder="Descrizione breve"
                    name="short_description" value={supplier?.short_description || ''} onChange={changeSupplierHandler} required />
            </Form.Group>

            <Form.Group>
                <Form.Label>Descrizione</Form.Label>
                <Form.Control as="textarea" rows={12} placeholder="Descrizione"
                    name="description" value={supplier?.description || ''} onChange={changeSupplierHandler} required />
            </Form.Group>

            <Form.Row>
                <Form.Group as={Col} sm={12} md={6}>
                    <Form.Label>Partita IVA</Form.Label>
                    <Form.Control type="text" placeholder="Partita IVA"
                        isInvalid={!!error?.vat} pattern="[0-9]{11}"
                        name="vat" value={supplier?.vat || ''} onChange={changeSupplierHandler} maxLength={11} required />
                </Form.Group>
                <Form.Group as={Col} sm={12} md={6}>
                    <Form.Label>Codice Fiscale</Form.Label>
                    <Form.Control type="text" placeholder="Codice fiscale"
                        isInvalid={!!error?.tax_code} pattern="[0-9]{11}|[A-Za-z]{6}[0-9]{2}[A-Za-z]{1}[0-9]{2}[A-Za-z]{1}[0-9]{3}[A-Za-z]{1}"
                        name="tax_code" value={supplier?.tax_code || ''} onChange={changeSupplierHandler} maxLength={16} required />
                </Form.Group>
            </Form.Row>

            <Form.Row>
                <Form.Group as={Col} sm={12} md={6}>
                    <Form.Label>Codice Destinatario SDI</Form.Label>
                    <Form.Control type="text" placeholder="Codice Univoco"
                        name="unique_code" value={supplier?.unique_code || ''} onChange={changeSupplierHandler} maxLength={10} required />
                </Form.Group>
                <Form.Group as={Col} sm={12} md={6}>
                    <Form.Label>Email certificata</Form.Label>
                    <Form.Control type="email" placeholder="Email certificata"
                        name="certified_mail" value={supplier?.certified_mail || ''} onChange={changeSupplierHandler} maxLength={20} required />
                </Form.Group>
            </Form.Row>

            <div>
                <Button variant="secondary"  onClick={() => history.goBack()} type="button">Annulla</Button>
                <Button variant="primary" type="submit" className="float-right">Salva</Button>
            </div>
        </Form>
    )
}

function ErrorLabel({ errors, children }) {
  if (errors) return <Form.Label className="text-danger">{children}</Form.Label>;
  else return null;
}

export default SupplierForm
