import React, { useEffect, useState } from 'react'
import { Modal, Container, Form, Button } from 'react-bootstrap'
import moment from 'moment/min/moment-with-locales'
import { saveEvent } from '../../api/events'
import { formatDateYYYYMMDD, formatDateISO8601, formatTimeShort } from '../../utils'

const DeceasedEventModal = (props) => {
    const [event, setEvent] = useState()
    const [validated, setValidated] = useState(false)

    useEffect(() => {
        setEvent(props.event)
        setValidated(false)
    }, [props.event])

    const closeEventModalHandler = () => {
        props.onEventSaved(null)
    }

    const eventModalTitle = () => (props.eventTypes && event) ?
            props.eventTypes.find(item => item.type === event.type).label : ''

    const changeEventFieldHandler = e => {
        const { type, name, value } = e.target

        if (type === 'time') {
            const currEventDateTime = event.date
            const dateTime = moment(currEventDateTime).utc(0)
            const [hour, minute] = value.split(':');
            dateTime.set({hour, minute})
            setEvent({...event, date: dateTime})
        } else if ((type === 'date') && (name === 'date')) {
            const currEventDateTime = moment.utc(event.date)
            const newEventDateTime = moment.utc(value)
            newEventDateTime.set({
                hour: currEventDateTime.hours(), 
                minute: currEventDateTime.minutes()
            })
            const dateTime = formatDateISO8601(newEventDateTime)
            setEvent({...event, date: dateTime})
        } else {
            setEvent({ ...event, 
                [name]: type === 'date' ? formatDateISO8601(value) : value
            })
        }
    }

    const saveEventHandler = e => {
        (async () => {
            e.preventDefault()
            e.stopPropagation()
            setValidated(true)
            if (!e.currentTarget.checkValidity()) return

            const res = await saveEvent(event)
            if (res.error) {
                console.warn(res.error)
                alert('Si è verificato un errore durante il salvataggio')
                return
            }
            props.onEventSaved(res)
        })()
    }

    return (
        <Modal show={event !== null} onHide={closeEventModalHandler} backdrop="static">
            <Modal.Header closeButton>
                <Modal.Title >{ eventModalTitle() }</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Container>
                    <Form noValidate validated={validated} onSubmit={ saveEventHandler }>
                        <Form.Group _style={{marginBottom: '0'}}>
                            <Form.Label>Data e ora evento</Form.Label>
                            <Form.Control name="date" type="date" placeholder="Data dell'evento" 
                                value={ formatDateYYYYMMDD(event?.date) }
                                onChange={ changeEventFieldHandler }
                                required />
                            <Form.Control name="date" type="time" placeholder="Ora dell'evento" 
                                value={ formatTimeShort(event?.date) }
                                onChange={ changeEventFieldHandler }
                                required />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Data preavviso</Form.Label>
                            <Form.Control name="notice_date" type="date" placeholder="Data di preavviso"
                                value={ formatDateYYYYMMDD(event?.notice_date) }
                                onChange={ changeEventFieldHandler } />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Luogo del rito</Form.Label>
                            <Form.Control name="location_name" placeholder="Nome della Chiesa o altro (es: Chiesa del Sacro Cuore)" 
                                value={ event?.location_name || ''}
                                onChange={ changeEventFieldHandler } required />
                        </Form.Group>
                        <Form.Group>
                            <Form.Control name="location_address" placeholder="Indirizzo"
                                value={ event?.location_address || '' }
                                onChange={ changeEventFieldHandler } required />
                        </Form.Group>
                        <Form.Group>
                            <Form.Control name="location_city" placeholder="Città"
                                value={ event?.location_city || '' }
                                onChange={ changeEventFieldHandler } required />
                        </Form.Group>
                        <Form.Group>
                            <Form.Control name="location_prov" placeholder="Prov" maxLength={2}
                                value={ event?.location_prov || '' }
                                onChange={ changeEventFieldHandler } required />
                        </Form.Group>
                        <div>
                            <Button variant="secondary"  onClick={ closeEventModalHandler }>Annulla</Button>
                            <Button variant="primary" type="submit" className="float-right">Salva</Button>
                        </div>
                    </Form>
                </Container>
            </Modal.Body>
        </Modal>
    )
}

export default DeceasedEventModal
