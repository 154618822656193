import React, { useEffect, useState, useContext } from "react";
import UserContext from "../../UserContext";
import { contentLoading, contentLoaded } from "../../reducers/actions";
import {
  Container,
  Row,
  Col,
  Table,
  ButtonToolbar,
  ButtonGroup,
  Button,
  Form,
  Card,
} from "react-bootstrap";
import { Link, useHistory, useLocation } from "react-router-dom";
import { listDeceased, deleteDeceased } from "../../api/deceased";
import BaseLayout from "../layouts/BaseLayout";
import Pagination from "../../components/Pagination";
import Footer from "../../components/Footer";
import {
  formatDateYYYYMMDD,
  formatDateISO8601,
  formatDateLong,
} from "../../utils";
import DateRangePicker from "../../components/DateRangePicker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendar,
  faEdit,
  faSearch,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { HTML5_FMT } from "moment";
import GoBack from "../../components/GoBack";

const Deceased = () => {
  const { state, dispatch } = useContext(UserContext);
  const location = useLocation();
  const history = useHistory();
  const [filter, setFilter] = useState("");
  const [params, setParams] = useState();
  const [data, setData] = useState();
  const [showPicker, setShowPicker] = useState(false);
  const [selectedRange, setSelectetRange] = useState({
    date_start: null,
    date_end: null,
  });

  useEffect(() => {
    // const currentPath = location.pathname
    const searchParams = new URLSearchParams(location.search);
    setParams({
      lastName: searchParams.get("lastName") || "",
      firstName: searchParams.get("firstName") || "",
      from: searchParams.get("from") || "",
      to: searchParams.get("to") || "",
      pageNo: searchParams.get("page") || 1,
    });
  }, [location]);

  useEffect(() => {
    if (params) {
      (async () => {
        dispatch(contentLoading());
        const from =
          params.from && params.from !== ""
            ? formatDateISO8601(params.from)
            : "";
        const to =
          params.to && params.to !== "" ? formatDateISO8601(params.to) : "";
        const res = await listDeceased(
          params.lastName,
          params.firstName,
          from,
          to,
          params.pageNo
        );
        setData(res);
        dispatch(contentLoaded());
      })();
    }
  }, [params]);

  const changeFilter = (e) => {
    e.preventDefault();
    // Always reset page to 1
    history.push(
      location.pathname +
        "?lastName=" +
        filter +
        "&from=" +
        (selectedRange.date_start
          ? formatDateYYYYMMDD(selectedRange.date_start)
          : "") +
        "&to=" +
        (selectedRange.date_end
          ? formatDateYYYYMMDD(selectedRange.date_end)
          : "")
    );
  };

  const pageChangedHandler = (page) => {
    let url = location.pathname + "?page=" + page;
    if (params.lastName) url += "&lastName=" + params.lastName;
    if (params.from) url += "&from=" + params.from;
    if (params.to) url += "&to=" + params.to;
    history.push(url);
  };

  const editItem = (deceased) => {
    history.push(`/deceased/${deceased.id}/edit`);
  };

  const deleteItem = (deceased) => {
    if (
      !window.confirm(`Eliminare "${deceased.lastname} ${deceased.firstname}"?`)
    )
      return;
    (async () => {
      await deleteDeceased(deceased);
      history.go(0);
    })();
  };

  const openCalendarModalHandler = (eventType) => {
    if (selectedRange.date_start && selectedRange.date_end) {
      setSelectetRange({ date_start: null, date_end: null });
    } else {
      setShowPicker(true);
    }
  };

  const saveRangePickerHandler = (range) => {
    if (range) {
      setSelectetRange(range);
    }

    setShowPicker(false);
  };

  const titleRangeSelected = () => {
    if (selectedRange.date_start && selectedRange.date_end) {
      return (
        formatDateLong(selectedRange.date_start) +
        " - " +
        formatDateLong(selectedRange.date_end)
      );
    } else {
      return "Qualsiasi data";
    }
  };

  const actionRangeSelected = () => {
    if (selectedRange.date_start && selectedRange.date_end) {
      return <FontAwesomeIcon icon={faTrash} />;
    } else {
      return <FontAwesomeIcon icon={faCalendar} />;
    }
  };

  const pendingPostersOfDeceased = (deceased) => {
    const totPendingPosters = deceased.events
      ? deceased.events.reduce(
          (pendingPosters, event) =>
            pendingPosters + event.pending_posters_count,
          0
        )
      : 0;
    return totPendingPosters > 0 ? (
      <small>{totPendingPosters} MANIFESTI DA APPROVARE</small>
    ) : (
      ""
    );
  };

  return (
    <Card>
      <Card.Header>
        <Card.Title>
          <GoBack />
          <span>Defunti</span>
          {data ? (
            <span>
              &nbsp;- Pagina {data.current_page} di {data.last_page}
            </span>
          ) : (
            ""
          )}
        </Card.Title>
      </Card.Header>
      <Card.Body>
        <DateRangePicker show={showPicker} onClose={saveRangePickerHandler} />
        <Container fluid>
          <Row className="align-items-center">
            <Col xs={12} md={8}>
              <Form onSubmit={changeFilter}>
                <Row className="align-items-center">
                  <Col className="pr-0">
                    <Form.Control
                      type="text"
                      placeholder="Cerca per cognome..."
                      onChange={(e) => setFilter(e.target.value)}
                      value={filter}
                    />
                  </Col>
                  <Col className="pl-1">
                    <Button type="submit">
                      <FontAwesomeIcon icon={faSearch} />
                    </Button>
                  </Col>
                </Row>
                <Button
                  className="pl-0"
                  onClick={(e) => openCalendarModalHandler()}
                  variant="link"
                >
                  {titleRangeSelected()} {actionRangeSelected()}
                </Button>
              </Form>
            </Col>
          </Row>
          {state.user?.role === "G" && (
            <Row>
              <Col className="text-right">
                <Link to="/deceased/new">Aggiungi</Link>
              </Col>
            </Row>
          )}
          <Row>
            <Col xs={12}>
              <Table responsive hover>
                <thead>
                  <tr>
                    <th style={{ display: "table-cell" }}>Nome</th>
                    <th className="d-xs-none" style={{ display: "table-cell" }}>Località</th>
                    <th className="d-xs-none" style={{ display: "table-cell" }}>Decesso</th>
                    <td style={{ display: "table-cell", width: "1%" }}></td>
                  </tr>
                </thead>
                <tbody>
                  {data && data.data ? (
                    data.data.map((deceased) => (
                      <tr key={deceased.id}>
                        <td style={{ display: "table-cell" }}>
                          <strong>
                            {deceased.lastname} {deceased.firstname}
                          </strong>
                          {pendingPostersOfDeceased(deceased)}
                        </td>
                        <td className="d-xs-none" style={{ display: "table-cell" }}>
                          {deceased.city ? `${deceased.city}` : null}{", "}
                          {deceased.prov ? `${deceased.prov}` : null}
                        </td>
                        <td className="d-xs-none" style={{ display: "table-cell" }}>
                          {formatDateLong(deceased.death)}
                        </td>
                        <td className="d-flex justify-content-end" style={{ display: "table-cell" }}>
                          <ButtonToolbar>
                            <ButtonGroup>
                              <Button
                                variant="secondary"
                                onClick={(e) => editItem(deceased)}
                              >
                                <FontAwesomeIcon icon={faEdit} />
                              </Button>
                              <Button
                                variant="danger"
                                onClick={(e) => deleteItem(deceased)}
                              >
                                <FontAwesomeIcon icon={faTrash} />
                              </Button>
                            </ButtonGroup>
                          </ButtonToolbar>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="4">No result</td>
                    </tr>
                  )}
                </tbody>
                <tfoot>
                  <tr>
                    <td
                      className="align-items-center"
                      colSpan="5"
                      style={{ paddingBottom: 0 }}
                    >
                      <Pagination
                        currentPage={data?.current_page}
                        totPages={data?.last_page}
                        pageChanged={pageChangedHandler}
                      />
                    </td>
                  </tr>
                </tfoot>
              </Table>
            </Col>
          </Row>
        </Container>
      </Card.Body>
    </Card>
  );
};

export default Deceased;
