import React from 'react'
import { Redirect, Route } from 'react-router-dom'

const PrivateRoute = ({ component: Component, ...rest }) => {
    const isAuthorized = rest.requiredRole ? (rest.user.role === rest.requiredRole) : true

    return (
        <Route {...rest} render={ props => 
                isAuthorized ? (
                    <Component {...props} />
                ) : (
                    <Redirect to={{
                        pathname: '/signin',
                        state: { from: props.location }
                    }} />
                )
        } />
    )
}

export default PrivateRoute
