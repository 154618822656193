import { baseUrl, options } from './common'
import axios from 'axios'

const listBoards = async (pageIx = 1, city = '') => {
    try {
        const response = await axios.get(`${baseUrl}/api/boards?page=${pageIx}&city=${city}`, options())
        return response.data
    } catch (err) {
        return {
            error: err
        }
    }
}

const getBoard = async (id) => {
    try {
        const url = `${baseUrl}/api/boards/${id}`;
        const response = await axios.get(url, options())
        return response.data
    } catch (err) {
        return {
            error: err
        }
    }
}

const saveBoard = async (board) => {
    try {
        const response = board.id ?
            await axios.put(`${baseUrl}/api/boards/${board.id}`, board, options()) : 
            await axios.post(`${baseUrl}/api/boards`, board, options())

        return response.data
    } catch (err) {
        return {
            error: err.response ? err.response.data : err
        }
    }
}

const deleteBoard = async(board) => {
    try {
        const url = `${baseUrl}/api/boards/${board.id}`
        const response = await axios.delete(url, options())
        return response.data
    } catch (err) {
        return {
            error: err
        }
    }
}

export { listBoards, getBoard, saveBoard, deleteBoard }
