import { baseUrl, options, formatError } from './common'
import axios from 'axios'

const listDeceased = async (lastname = '', firstname = '', from = '', to = '', pageIx = 1) => {
    try {
        const url = 
            `${baseUrl}/api/deceased?lastname=${lastname}&firstname=${firstname}&from=${from}&to=${to}&page=${pageIx}`;
        const response = await axios.get(url, options())
        return response.data
    } catch (err) {
        return formatError(err)
    }
}

const getDeceased = async (id) => {
    try {
        const url = `${baseUrl}/api/deceased/${id}`
        const response = await axios.get(url, options())
        return response.data
    } catch (err) {
        return formatError(err)
    }
}

const saveDeceased = async (deceased) => {
    try {
        const response = deceased.id ?
            await axios.put(`${baseUrl}/api/deceased/${deceased.id}`, deceased, options()) : 
            await axios.post(`${baseUrl}/api/deceased`, deceased, options())
        return response.data
    } catch (err) {
        return formatError(err)
    }
}

const deleteDeceased = async (deceased) => {
    try {
        const response = await axios.delete(`${baseUrl}/api/deceased/${deceased.id}`, options())
        return response.data
    } catch (err) {
        return formatError(err)
    }
}

const saveAttachment = async (deceased, attachment) => {
    try {
        const response = attachment.id ?
            await axios.put(`${baseUrl}/api/deceased/${deceased.id}/attachments/${attachment.id}`, attachment, options()) : 
            await axios.post(`${baseUrl}/api/deceased/${deceased.id}/attachments`, attachment, options())
        return response.data
    } catch (err) {
        return formatError(err)
    }
}

const deleteAttachment = async (deceased, attachment) => {
    try {
        const response = await axios.delete(`${baseUrl}/api/deceased/${deceased.id}/attachments/${attachment.id}`, options())
        return response.data
    } catch (err) {
        return formatError(err)
    }
}

export { listDeceased, getDeceased, saveDeceased, deleteDeceased, saveAttachment, deleteAttachment }
