import React, { useEffect, useState } from "react";
import {
  Modal,
  Container,
  Row,
  Col,
  Table,
  Form,
  Button,
  InputGroup,
  ListGroup,
} from "react-bootstrap";
import ImageUploader from "../../components/ImageUploader";
import HTMLEditor from "../../components/editor/Editor";
import { listTemplates } from "../../api/templates";
import { listBoards } from "../../api/boards";
import { savePoster } from "../../api/posters";
import {
  formatWeekDay,
  formatDayAndMonth,
  formatDateLong,
  formatTimeShort,
} from "../../utils";
import MailSender from "../../components/MailSender";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";

const dummyDeceased = {
  firstname: "NOME",
  lastname: "COGNOME",
};
const dummyPoster = {
  event: {
    date: new Date().toISOString(),
    location_name: "NOME LUOGO",
    location_address: "INDIRIZZO",
    location_city: "CITTA'",
    location_prov: "PROV",
  },
};

const DeceasedPosterModal = (props) => {
  const [poster, setPoster] = useState();
  // eslint-disable-next-line
  const [validated, setValidated] = useState(false);
  const [step, setStep] = useState(1);
  const [templates, setTemplates] = useState();
  const [selectedTemplate, setSelectedTemplate] = useState();
  const [showBoards, setShowBoards] = useState(false);

  useEffect(() => {
    // Load available templates
    if (props.poster) {
      (async () => {
        const templatesResult = await listTemplates();
        const filtered = templatesResult.filter(
          (item) => item.poster_type === props.poster?.poster_type
        );
        setTemplates(filtered);
      })();
    }

    setPoster(props.poster);
    setStep(props.poster && props.poster.id ? 2 : 1);
    setBoardsFilter("");
    setBoardsResult(null);
    setSelectedTemplate(null);
    setSelectedBoards(props.poster?.boards || []);

    setValidated(false);
  }, [props.poster]);

  const moveToBackStep = (e) => {
    if (step > 1) {
      setStep(step - 1);
    }
  };

  const moveToNextStep = (e) => {
    if (step === 1 && selectedTemplate === null) {
      alert("Seleziona un modello dall'elenco");
      return;
    }
    if (step < 3) {
      setStep(step + 1);
    }
  };

  const closePosterModalHandler = () => {
    props.onPosterSaved(null);
  };

  const posterModalTitle = (step) =>
    poster && poster.id
      ? `Modifica Manifesto - Step ${step - 1}`
      : `Aggiungi Manifesto - Step ${step}`;

  // #region Step 1 - Select template

  const templatePreviewUrl = (item) =>
    item.attachment?.url || "/sample-template.png";

  const selectTemplateHandler = (ix) => {
    const choosedTemplate = templates[ix];
    setSelectedTemplate(choosedTemplate);
    const posterText = replaceTemplateTextFields(
      choosedTemplate.text,
      props.deceased,
      props.poster
    );
    setPoster({ ...poster, text: posterText, template_id: choosedTemplate.id });
  };

  // TODO This logic should be placed in a util module
  const replaceTemplateTextFields = (text, deceased, poster) =>
    text.replace(
      /{firstname}|{lastname}|{death-date-wd}|{death-date-dmy}|{death-date-dM}|{event-date-wd}|{event-date-dmy}|{event-date-dM}|{event-time}|{location-name}|{location-address}|{location-city}|{location-prov}/gi,
      (match) => {
        switch (match) {
          case "{firstname}":
            return deceased.firstname;
          case "{lastname}":
            return deceased.lastname;
          // '{image-url}' => $posterImageUrl,
          case "{death-date-wd}":
            return formatWeekDay(deceased.death);
          case "{death-date-dmy}":
            return formatDateLong(deceased.death, false);
          case "{death-date-dM}":
            return formatDayAndMonth(deceased.death);
          case "{event-date-wd}":
            return formatWeekDay(poster?.event.date);
          case "{event-date-dmy}":
            return formatWeekDay(poster?.event.date);
          case "{event-date-dM}":
            return formatDayAndMonth(poster?.event.date);
          case "{event-time}":
            return formatTimeShort(poster?.event.date);
          case "{location-name}":
            return poster?.event.location_name;
          case "{location-address}":
            return poster?.event.location_address;
          case "{location-city}":
            return poster?.event.location_city;
          case "{location-prov}":
            return poster?.event.location_prov;
          default:
            return "";
        }
      }
    );

  // #endregion Step 1 - Select template

  // #region Step 2 - Text and photo

  const posterAttachmentImageUrl = (item) =>
    item?.attachment?.url ||
    (props.deceased.gender === "F" ? "/female.png" : "/male.jpg");

  const setPosterImage = (data) => {
    if (data) {
      setPoster({
        ...poster,
        attachment: {
          filename: data.filename,
          url: data.url,
        },
      });
    } else {
      if (poster.attachment) {
        const updatedPoster = { ...poster };
        delete updatedPoster.attachment;
        setPoster(updatedPoster);
      }
    }
  };

  // #endregion Step 2 - Text and photo

  // #region Step 3 - Boards selection

  const [boardsFilter, setBoardsFilter] = useState("");
  const [boardsResult, setBoardsResult] = useState();
  const [selectedBoards, setSelectedBoards] = useState([]);

  const searchBoards = (e) => {
    (async () => {
      e.preventDefault();
      const res = await listBoards(1, boardsFilter);
      setBoardsResult(res);
      setShowBoards(true);
    })();
  };

  const selectBoard = (board) => {
    // const exists = selectedBoards.indexOf(board) > -1
    const exists =
      selectedBoards.findIndex((item) => item.id === board.id) > -1;
    if (!exists) {
      const boards = [...selectedBoards];
      boards.push(board);
      setSelectedBoards(boards);
    }
  };

  const removeBoard = (board) => {
    const index = selectedBoards.indexOf(board);
    if (index > -1) {
      const boards = [...selectedBoards];
      boards.splice(index, 1);
      setSelectedBoards(boards);
    }
  };

  // #endregion Step 3 - Boards selection

  const savePosterHandler = (e) => {
    (async () => {
      const boardIds = selectedBoards.map((item) => item.id);
      const updatedPoster = { ...poster, board_ids: boardIds };
      const res = await savePoster(props.deceased, updatedPoster);
      if (res.error) {
        console.warn(res.error);
        setPoster(updatedPoster);
        alert("Si è verificato un errore durante il salvataggio");
        return;
      }
      setPoster(null);
      props.onPosterSaved(res);
    })();
  };

  return (
    <Modal
      size="lg"
      show={poster !== null}
      onHide={closePosterModalHandler}
      onClick={() => {
        setShowBoards(false);
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title>{posterModalTitle(step)}</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ height: "70vh", overflowY: "auto" }}>
        <Container className="h-100">
          <Row hidden={step !== 1}>
            <Col style={{ overflowX: "auto" }}>
              <ListGroup horizontal>
                {templates &&
                  templates.map((item, ix) => (
                    <ListGroup.Item
                      key={ix}
                      onClick={(e) => {
                        selectTemplateHandler(ix);
                      }}
                    >
                      <img
                        src={templatePreviewUrl(item)}
                        style={{ objectFit: "contain", maxHeight: 200 }}
                        alt=""
                      />
                    </ListGroup.Item>
                  ))}
              </ListGroup>
            </Col>
          </Row>
          <Row hidden={step !== 1}>
            <Col>
              {selectedTemplate ? (
                <div
                  dangerouslySetInnerHTML={{
                    __html: replaceTemplateTextFields(
                      selectedTemplate.text,
                      dummyDeceased,
                      dummyPoster
                    ),
                  }}
                />
              ) : (
                <div>
                  <h5>Seleziona il modello da usare dall'elenco sopra</h5>
                </div>
              )}
            </Col>
          </Row>

          <Row hidden={step !== 2}>
            <Col className="h-100">
              <h3>Testo e foto del manifesto</h3>

              <ImageUploader
                height={"100px"}
                initialImage={posterAttachmentImageUrl(poster)}
                uploadedFileHandler={setPosterImage}
              />

              {/*
                            <textarea name="text"  value={ poster.text } onChange={setPosterField} />
                            */}
              <HTMLEditor
                value={poster?.text}
                valueChanged={(updatedValue) => {
                  setPoster({ ...poster, text: updatedValue });
                }}
              />
            </Col>
          </Row>

          <Row hidden={step !== 3}>
            <Col>
              <h3>Bacheche</h3>

              <Form onSubmit={searchBoards}>
                <Form.Group style={{ marginBottom: "0" }}>
                  <InputGroup>
                    <Form.Control
                      style={{ zIndex: 1000 }}
                      placeholder="Cerca per località..."
                      value={boardsFilter}
                      onChange={(e) => setBoardsFilter(e.target.value)}
                    />
                    <InputGroup.Append>
                      <Button type="submit" variant="primary">
                        <FontAwesomeIcon icon={faSearch} />
                      </Button>
                    </InputGroup.Append>
                  </InputGroup>
                </Form.Group>
              </Form>

              <ListGroup
                hidden={!showBoards}
                style={{
                  border: "solid 1px #cecece",
                  maxHeight: 200,
                  overflow: "auto",
                }}
              >
                {boardsResult?.total === 0 ? (
                  <ListGroup.Item>
                    Nessun risultato
                    <MailSender
                      text="Richiedi l'aggiunta di una bacheca"
                      type="request-new-board"
                    />
                  </ListGroup.Item>
                ) : (
                  boardsResult?.data.map((board) => {
                    return (
                      <ListGroup.Item
                        action
                        onClick={() => {
                          selectBoard(board);
                        }}
                      >
                        <strong>{board.name}</strong>
                        <br />
                        <small>{board.area.name}</small>
                      </ListGroup.Item>
                    );
                  })
                )}
              </ListGroup>

              {selectedBoards && selectedBoards.length ? (
                <ul>
                  {selectedBoards.map((board) => (
                    <li key={board.id}>
                      <Button
                        variant="link"
                        className="float-right"
                        style={{ textDecoration: "none" }}
                        onClick={(e) => removeBoard(board)}
                      >
                        <i className="ti-close"></i>
                      </Button>
                      {board.name}
                    </li>
                  ))}
                </ul>
              ) : (
                <div>
                  <h4>Nessuna bacheca selezionata</h4>
                </div>
              )}
            </Col>
          </Row>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <Container>
          <Row>
            <Col>
              <Button
                variant="secondary"
                className="float-left"
                onClick={closePosterModalHandler}
              >
                Annulla
              </Button>
              <Button
                variant="secondary"
                className="mr-1"
                disabled={step === (poster?.id ? 2 : 1)}
                onClick={moveToBackStep}
              >
                <i className="ti-angle-left"></i> Indietro
              </Button>
              <Button
                variant="secondary"
                disabled={step === 3}
                onClick={moveToNextStep}
              >
                Avanti <i className="ti-angle-right"></i>
              </Button>
              <Button
                variant="primary"
                disabled={!poster?.id && step < 3}
                onClick={savePosterHandler}
              >
                Salva
              </Button>
            </Col>
          </Row>
        </Container>
      </Modal.Footer>
    </Modal>
  );
};

export default DeceasedPosterModal;
