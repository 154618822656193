import { baseUrl, options, formatError } from './common'
import axios from 'axios'
import { GenericError, SignUpRequest, User } from './types';

const getAuthenticatedUser = () => {
    const storedUser: string | null = localStorage.getItem('userrdmbo');
    if (storedUser) {
        return JSON.parse(storedUser) as User;
    } else {
        return null;
    }
};

const getAuthToken = () => localStorage.getItem('tokenrdmbo')

const signUp = async (request: SignUpRequest | FormData): Promise<User | GenericError> => {
    try {
        let formData: FormData;
        if (request instanceof FormData) {
            formData = request;
        } else {
            formData = new FormData();
            Object.entries(request).forEach(([name, value]) => {
                formData.append(name, value)
            })
        }
        const response = await axios.post<User | GenericError>(`${baseUrl}/signup`,
            formData,
            options());
        return response.data;
    } catch (err) {
        return formatError(err) as GenericError;
    }
}

const signIn = async (email: string, password: string) => {
    try {
        const response = await axios.post(`${baseUrl}/signin`,
            { email, password },
            options())

        if (response.data.user.role === 'U') {
            throw new Error('Invalid user')
        }

        localStorage.setItem('tokenrdmbo', response.data.token)
        localStorage.setItem('userrdmbo', JSON.stringify(response.data.user))

        return response.data
    } catch (err) {
        return formatError(err)
    }
}

const verify = async (email: string) => {
    try {
        const response = await axios.get(`${baseUrl}/email/verify?email=${email}`, options())
        return response.data
    } catch (err) {
        return formatError(err)
    }
}

const verifyToken = async (token: any) => {
    try {
        const response = await axios.get(`${baseUrl}/email/verify/${token}`, options())
        return response.data
    } catch (err) {
        return formatError(err)
    }
}

const resetPassword = async (email: string) => {
    try {
        const response = await axios.get(`${baseUrl}/reset-password?email=${email}`, options())
        return response.data
    } catch (err) {
        return formatError(err)
    }
}

const verifyPassword = async (token: null, password: string) => {
    try {
        const response = await axios.post(`${baseUrl}/reset-password/${token}`, { password }, options())
        return response.data
    } catch (err) {
        return formatError(err)
    }
}

export { getAuthenticatedUser, getAuthToken, signIn, signUp, verify, verifyToken, resetPassword, verifyPassword }
