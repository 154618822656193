import { baseUrl, options } from './common'
import axios from 'axios'

const listAreas = async (parentId, name = '', pageIx = 1) => {
    try {
        const url = parentId ? 
            `${baseUrl}/api/areas/${parentId}/subareas?name=${name}&page=${pageIx}` :
            `${baseUrl}/api/areas?name=${name}&page=${pageIx}`;
        const response = await axios.get(url, options())
        return response.data
    } catch (err) {
        console.log(err)
        return {
            error: err
        }
    }
}

const getArea = async (id, parentId) => {
    try {
        const url = parentId ?
            `${baseUrl}/api/areas/${parentId}/subareas/${id}` :
            `${baseUrl}/api/areas/${id}`;
        const response = await axios.get(url, options())
        return response.data
    } catch (err) {
        console.log(err)
        return {
            error: err
        }
    }
}

const saveArea = async (area) => {
    try {
        let isNew = (area.id === null)
        let isParent = (area.parent_id === null)
        let url
        if (!isParent) {
            // It's a sub area
            url = `${baseUrl}/api/areas/${area.parent_id}/subareas`
        } else {
            // It's a main area
            url = `${baseUrl}/api/areas`
        }

        let response
        if (isNew) {
            response = await axios.post(url, area, options())
        } else {
            url += `/${area.id}`
            response = await axios.put(url, area, options())
        }
        return response.data
    } catch (err) {
        console.log(err)
        return {
            error: err.response ? err.response.data : err
        }
    }
}

const deleteArea = async(area) => {
    try {
        const url = area.parent_id ? 
            `${baseUrl}/api/areas/${area.parent_id}/subareas/${area.id}` :
            `${baseUrl}/api/areas/${area.id}`
        const response = await axios.delete(url, options())
        return response.data
    } catch (err) {
        console.log(err)
        return {
            error: err
        }
    }
}

export { listAreas, getArea, saveArea, deleteArea }
