import { FormEventHandler, useState } from "react";
import { useHistory } from "react-router-dom";
import { InputGroup, Row, Col, Form, Button, ButtonGroup } from "react-bootstrap";
import * as auth from "../../api/auth";
import Onboarding from "../layouts/Onboarding";
import { SignUpRequest } from "../../api/types";
import { propertyInSignUpRequest } from "../../utils/typeUtils";

const SignUp = () => {
  const history = useHistory<any>();
  const [formErrors, setFormErrors] = useState<Partial<SignUpRequest>>({
  });
  const [pendingRequest, setPendingRequest] = useState<boolean>(false);

  /**
   * Redirects the user to the
   * email confirmation page
   * @param email 
   * @returns 
   */
  const onSuccess = (email: string) => {
    return history.push({
      pathname: "/waiting_confirmation",
      search: "?email=" + email,
    })
  }

  /**
   * Handles back-end exceptions
   * @param exception 
   */
  const onError = (exception: any) => {
    if (exception.error?.detail) {
      // this is a normal error
      alert(`${exception.status} - ${exception.error?.detail}`)
    } else {
      // this might be a verification error
      const { error } = exception;
      // const formErrors: Partial<SignUpRequest> = {};
      const formErrors: any = {};
      Object.entries(error).forEach(([name, value]) => {
        if (propertyInSignUpRequest(name)) {
          formErrors[name] = value;
        }
      });
      // if formErrors is empty, the error may not be related to the form
      // in that case raise an alert
      // @TODO implement global snackbars
      if (Object.entries(formErrors).length === 0) {
        const message = Object.entries(error).map(([_context, message]) => {
          return message;
        }).join('\n');
        alert(`Errore n. ${exception.status}: ${message}`)
      } else {
        setFormErrors(formErrors);
      }
    }
  }

  /**
   * Registration form submit handler
   * @param event - form event
   */
  const handleSubmit: FormEventHandler<HTMLFormElement> = async (event) => {
    // prevent form's default behavior of reloading the page
    event.preventDefault();
    // reset form errors
    setFormErrors({});
    // get form data to pass as the body of the request
    const formData = new FormData(event.target as HTMLFormElement);
    // set pending state (pending request)
    setPendingRequest(true);
    try {
      const response = await auth.signUp(formData);
      if ('error' in response) {
        throw response;
      }
      // extract email to correctly redirect the user 
      const email = formData.get('email') as string;
      onSuccess(email);
    } catch (error) {
      onError(error)
    } finally {
      // reset pending state
      setPendingRequest(false);
    }
  }

  return (
    <Onboarding
      title="Registrati"
      body="Sei un'agenzia di onoranze? Registrati per una gestione innovativa delle tue bacheche."
      context="signup"
    >
      <Row>
        <Col xs={12}>
          <Form noValidate onSubmit={handleSubmit} style={{ overflowY: "auto", maxHeight: "50vh" }}  >
            {/* Name */}
            <InputGroup className="my-2">
              <Form.Control
                name="name"
                placeholder="Nome"
                maxLength={50}
                isInvalid={!!formErrors.name}
                required
              />
              <Form.Control.Feedback type="invalid">
                {formErrors.name}
              </Form.Control.Feedback>
            </InputGroup>
            {/* Address */}
            <InputGroup className="my-2">
              <Form.Control
                name="address"
                placeholder="Indirizzo"
                maxLength={50}
                isInvalid={!!formErrors.address}
                required
              />
              <Form.Control.Feedback type="invalid">
                {formErrors.address}
              </Form.Control.Feedback>
            </InputGroup>
            {/* City */}
            <InputGroup className="my-2">
              <Form.Control
                name="city"
                placeholder="Città"
                maxLength={50}
                isInvalid={!!formErrors.city}
                required
              />
              <Form.Control.Feedback type="invalid">
                {formErrors.city}
              </Form.Control.Feedback>
            </InputGroup>
            {/* Province */}
            <InputGroup className="my-2">
              <Form.Control
                name="prov"
                placeholder="Provincia"
                maxLength={2}
                isInvalid={!!formErrors.prov}
                required
              />
              <Form.Control.Feedback type="invalid">
                {formErrors.prov}
              </Form.Control.Feedback>
            </InputGroup>
            <section className="mt-1 p-2" style={{ backgroundColor: "rgba(0, 0, 0, .1", borderRadius: 5 }}>
              <span>Compila almeno uno di questi campi</span>
              {/* Vat */}
              <InputGroup className="my-2">
                <Form.Control
                  name="vat"
                  maxLength={11}
                  placeholder="Partita IVA"
                  isInvalid={!!formErrors.vat}
                />
              </InputGroup>
              {/* TAX code */}
              <InputGroup className="my-2">
                <Form.Control
                  name="tax_code"
                  placeholder="Codice fiscale"
                  maxLength={16}
                  isInvalid={!!formErrors.tax_code}
                />
              </InputGroup>
              {/* Unique code */}
              <InputGroup className="my-2">
                <Form.Control
                  name="unique_code"
                  placeholder="Codice Univoco SDI"
                  maxLength={10}
                  isInvalid={!!formErrors.unique_code}
                />
                <Form.Control.Feedback type="invalid">
                  {formErrors.vat || formErrors.tax_code || formErrors.unique_code}
                </Form.Control.Feedback>
              </InputGroup>
            </section>
            {/* Type */}
            <InputGroup className="my-2">
              <span className="mr-2">Che tipo di azienda sei?</span>
              <ButtonGroup>
                <label className="mr-2">
                  <input className="mr-1" type="radio" name="type" value="G" defaultChecked />
                  Agenzia
                </label>
                <label>
                  <input className="mr-1" type="radio" name="type" value="S" />
                  Fornitore
                </label>
              </ButtonGroup>
              {
                formErrors.type && <small className="text-danger mb-2">{formErrors.type}</small>
              }
            </InputGroup>
            {/* Email */}
            <InputGroup>
              <Form.Control
                name="email"
                type="email"
                placeholder="Email"
                isInvalid={!!formErrors.email}
                required
              />
              <Form.Control.Feedback type="invalid">
                {formErrors.email}
              </Form.Control.Feedback>
            </InputGroup>
            <InputGroup className="my-2" >
              <Form.Control
                name="password"
                type="password"
                placeholder="Password"
                isInvalid={!!formErrors.password}
              />
              <Form.Control.Feedback type="invalid">
                {formErrors.password}
              </Form.Control.Feedback>
            </InputGroup>
            <InputGroup >
              <Form.Control
                name="password-match"
                type="password"
                placeholder="Conferma password"
                isInvalid={!!formErrors.password}
              />
              <Form.Control.Feedback type="invalid">
                {formErrors.password}
              </Form.Control.Feedback>
            </InputGroup>
            <InputGroup>
              <label className="m-2">
                <input className="mr-1" type="radio" name="terms" />
                Ho letto ed accetto i <a href="#">Termini di utilizzo del software</a>
              </label>
              {
                formErrors.terms && <small className="text-danger mb-2">{formErrors.terms}</small>
              }
            </InputGroup>

            <Form.Group className="mt-2" >
              <Button variant="primary" type="submit" disabled={pendingRequest} >
                Registrati
              </Button>
            </Form.Group>
          </Form>
          {
            Object.entries(formErrors).length > 0 ?
              <small className="text-danger">C'è stato un errore, perfavore, ricontrolla i dati o contatta il supporto</small>
              :
              null
          }
        </Col>
      </Row>
    </Onboarding>
  );
};

export default SignUp;
