import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Row, Col, Container, Form, Button, Card } from "react-bootstrap";
import { getBoard, saveBoard } from "../../api/boards";
import AsyncSelect from "react-select/async";
import { listAreas } from "../../api/areas";
import BaseLayout from "../layouts/BaseLayout";
import GoBack from "../../components/GoBack";

const BoardEditor = (props) => {
  const history = useHistory();
  const { boardId } = useParams();
  const [board, setBoard] = useState({
    id: null,
    area_id: null,
    name: "",
    lat: 0,
    lng: 0,
    radius: 0,
    area: { name: "" },
  });
  const [validated, setValidated] = useState(false);
  const [inputSubArea, setInputSubArea] = useState("");
  const [selectedSubArea, setSelectedSubArea] = useState("");
  const [error, setError] = useState({});

  useEffect(() => {
    (async () => {
      console.log(`Loading board ${boardId}`);
      const res = await getBoard(boardId);
      if (res.id) {
        setSelectedSubArea({ value: res.area.id, label: res.area.name });
      }
      setBoard(res);
    })();
  }, []);

  const changeBoardHandler = (e) => {
    const { name, value } = e.target;
    setBoard({ ...board, [name]: value });
  };

  const saveBoardHandler = (e) => {
    (async () => {
      e.preventDefault();
      e.stopPropagation();
      setValidated(true);
      if (!e.currentTarget.checkValidity()) return;

      const res = await saveBoard(board);
      if (res.error) {
        setError(res.error);
        if (res.error.message) {
          alert(res.error.message);
        }
      } else {
        history.goBack();
      }
    })();
  };

  const loadAreas = (inputValue, callback) => {
    setTimeout(() => {
      (async () => {
        if (inputValue === "") return;

        const res = await listAreas("all", inputValue, 1);
        const keyValues = res.data.map((item) => {
          return {
            value: item.id,
            label: `${item.parent.name} / ${item.name}`,
          };
        });

        callback(keyValues);
      })();
    }, 1000);
  };

  const handleInputChange = (newValue) => {
    const inputValue = newValue.replace(/\W/g, "");
    setInputSubArea({ inputValue });
  };

  const handleSelectChange = (newValue) => {
    setSelectedSubArea({ value: newValue.value, label: newValue.label });
    setBoard({ ...board, area_id: parseInt(newValue.value) });
  };

  return (
    <Card>
      <Card.Header>
        <Card.Title>
          <GoBack />
          {props.isNew ? "Nuova bacheca" : "Modifica bacheca"}
        </Card.Title>
      </Card.Header>
      <Card.Body>
        <Container fluid>
          <Form noValidate validated={validated} onSubmit={saveBoardHandler}>
            <Form.Group>
              <Form.Label>Nome</Form.Label>
              <Form.Control
                type="text"
                placeholder="Nome della bacheca"
                name="name"
                value={board.name}
                onChange={changeBoardHandler}
                required
                isInvalid={error?.name}
              />
              <ErrorLabel errors={!board?.name || board.name === ""}>
                Inserici il nome della bacheca
              </ErrorLabel>
            </Form.Group>

            <Form.Group>
              <Form.Label>Latitude</Form.Label>
              <Form.Control
                type="text"
                placeholder="Latitude"
                name="lat"
                value={board.lat}
                onChange={changeBoardHandler}
                required
              />
            </Form.Group>

            <Form.Group>
              <Form.Label>Longitude</Form.Label>
              <Form.Control
                type="text"
                placeholder="Longitude"
                name="lng"
                value={board.lng}
                onChange={changeBoardHandler}
                required
              />
            </Form.Group>

            <Form.Group>
              <Form.Label>Raggio</Form.Label>
              <Form.Control
                type="text"
                placeholder="Raggio"
                name="radius"
                value={board.radius}
                onChange={changeBoardHandler}
                required
              />
            </Form.Group>

            <Form.Group>
              <Form.Label>Sottoarea</Form.Label>

              <AsyncSelect
                name="area_id"
                value={selectedSubArea}
                cacheOptions
                placeholder="Seleziona dall'elenco"
                loadOptions={loadAreas}
                defaultOptions
                onInputChange={handleInputChange}
                onChange={handleSelectChange}
              />
            </Form.Group>

            <div>
              <Button
                variant="secondary"
                onClick={() => history.goBack()}
                type="button"
              >
                Annulla
              </Button>
              <Button variant="primary" type="submit" className="float-right">
                Salva
              </Button>
            </div>
          </Form>
        </Container>
      </Card.Body>
    </Card>
  );
};
function ErrorLabel({ errors, children }) {
  if (errors)
    return <Form.Label className="text-danger">{children}</Form.Label>;
  else return null;
}
export default BoardEditor;
