import * as actions from './actionTypes'

export const userLoggedIn = user => ({
    type: actions.USER_LOGGED_IN,
    payload: user
})

export const userLoggedOut = () => ({
    type: actions.USER_LOGGED_OUT
})

export const contentLoading = () => ({
    type: actions.CONTENT_LOADING
})

export const contentLoaded = () => ({
    type: actions.CONTENT_LOADED
})
