import React, { useEffect, useState, useContext, useMemo } from 'react'
import UserContext from '../../UserContext'
import { contentLoading, contentLoaded } from '../../reducers/actions'
import { Container, Row, Col, Card, Button, Form } from 'react-bootstrap'
import { Link, useHistory, useLocation } from 'react-router-dom'
import BaseLayout from '../layouts/BaseLayout'
import { listPosters } from '../../api/posters'
import useContinuousPagination from '../../hooks/useContinuousPagination'

const Posters = () => {
    const { dispatch } = useContext(UserContext)
    const [loading, setLoading,
        pageNo, setPageNo,
        hasMorePages, setHasMorePages,
        data, setData,
        loadNextPage] = useContinuousPagination()

    const [filter, setFilter] = useState('')
    const [params, setParams] = useState()
    const location = useLocation()
    const history = useHistory()

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        setPageNo(1)
        setData([])
        setFilter(searchParams.get('name') || '')
        setParams({
            filter: searchParams.get('name') || ''
        })
    }, [location])


    useEffect(() => {
        if (params) {
            (async() => {
                dispatch(contentLoading())
                const yOffset = window.pageYOffset
                const res = await listPosters(params.filter, pageNo)
                setData([...data, ...res.data])
                setHasMorePages(res.current_page < res.last_page)
                window.scrollTo(0, yOffset)
                dispatch(contentLoaded())
            })()
        }
    }, [params, pageNo])

    const changeFilter = (e) => {
        e.preventDefault()
        // Always reset page to 1
        setPageNo(1)
        setData([])
        history.push(location.pathname + '?name=' + filter)
    }

    const RandomImage = () => {
        var maxNumber = 3;
        var randomNumber = Math.floor((Math.random() * maxNumber) + 1);
        return <img src={require(`./../../assets/images/img${randomNumber}.jpg`)}  alt="" />
    }

    const renderedItems = useMemo(() => {
        return data ? data.map(poster => (
            <Col key={poster.id} xs={12} sm={6} xl={3}>
                <Card>
                    {
                        poster.attachment ?
                            <img src={'http://localhost:8081' + poster.attachment.filename}  alt="" />
                            : <RandomImage />
                    }
                    <Card.Body>
                        <div >
                            <div className="m-b-15">
                                <i className="ti-calendar"></i> {(new Date(poster.deceased.death)).toLocaleDateString('it-IT', { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' })}
                            </div>

                            <div>
                                <a href="#" >
                                    <h3 className="font-normal">
                                        {poster.deceased.lastname} {poster.deceased.firstname}
                                    </h3>
                                </a>
                            </div>
                        </div>
                        <p  style={{height: "30px", overflowY: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" }}>
                            {poster.text}
                        </p>
                        <Button variant="success" >Visualizza</Button>
                    </Card.Body>
                </Card>
            </Col>
        )): (
            <tr><td colSpan="4">No result</td></tr>
        )
    }, [data])

    return (
        <>
            <div  style={{ display: "block" }}>
                <div >
                    <Row>
                        <Col xs={5}>
                            <h4 >
                                Manifesti
                            </h4>
                        </Col>
                    </Row>
                </div>

                <Container fluid>
                    <Row >
                        <Col xs={12} md={8} className="pt-3">
                            <Form action="/posters" onSubmit={changeFilter}>
                                <div >
                                    <div >
                                        <Form.Control type="text" name="name" placeholder="Cerca"
                                            onChange={(e) => setFilter(e.target.value)} value={filter} />
                                        <div >
                                            <Button variant="default"><span ></span></Button>
                                        </div>
                                    </div>
                                </div>
                            </Form>
                        </Col>
                        <Col xs={12} md={4} >
                            <Link to="posters/new"
                                >Aggiungi</Link>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12}>
                            <h4> Tutte</h4>
                            <Row>
                                { renderedItems } 
                            </Row>
                        </Col>
                    </Row>

                    <h4 hidden={!loading}>Loading...</h4>
                    <Button variant="primary" type="button" hidden={!hasMorePages} onClick={loadNextPage}>Carica altri manifesti</Button>
                </Container>
            </div>
        </>
    )
}

export default Posters
