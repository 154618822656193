import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon, FontAwesomeIconProps } from "@fortawesome/react-fontawesome";
import { MouseEvent, MouseEventHandler } from "react";
import { Button, ButtonProps } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";

export interface GoBackProps {
    /**
     * Bootstrap props for the button that wraps the arrow icon
     */
    buttonProps?: ButtonProps,
    /**
     * Fontawesome Icon Props for the left arrow component
     */
    iconProps?: FontAwesomeIconProps,
    /**
     * If provided, the component will wait for the resolution of the promise before going back in history
     * @param event 
     */
    onBeforeGoingBack?(event: MouseEvent<HTMLElement, any>): Promise<any>
    /**
     * Override destination
     */
    to?: string
};

export default function GoBack(props: GoBackProps): JSX.Element {
    const history = useHistory();
    const handleClick: MouseEventHandler<HTMLElement> = async (event) => {
        if (props.onBeforeGoingBack) await props.onBeforeGoingBack(event)
        if (props.to) {
            history.replace(props.to)
        } else {
            history.goBack();
        }
    }

    return <Button variant="link" aria-label="torna alla pagina precedente" {...(props.buttonProps ?? {})} className={`${props.buttonProps?.className}`} onClick={handleClick}><FontAwesomeIcon {...(props.iconProps ?? {})} icon={faArrowLeft} /></Button>
}