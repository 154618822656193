import React from "react";
import { Form, Button, Col } from "react-bootstrap";
import { formatDateYYYYMMDD } from "../../utils";

const DeceasedForm = (props) => {
  console.log(props);
  return (
    <Form
      noValidate
      validated={props.validated}
      onSubmit={props.saveDeceasedHandler}
    >
      <Form.Row>
        <Form.Group as={Col}>
          <Form.Label>Nome</Form.Label>
          <Form.Control
            type="text"
            placeholder="Nome"
            name="firstname"
            value={props.deceased.firstname}
            onChange={props.changeDeceasedFieldHandler}
            isInvalid={props.errors?.firstname}
            required
          />
          <ErrorLabel errors={!props.deceased.firstname || props.deceased.firstname === ''}>
            Inserisci il nome del defunto
          </ErrorLabel>
        </Form.Group>
        <Form.Group as={Col}>
          <Form.Label>Cognome</Form.Label>
          <Form.Control
            type="text"
            placeholder="Cognome"
            name="lastname"
            value={props.deceased.lastname}
            onChange={props.changeDeceasedFieldHandler}
            isInvalid={props.errors?.lastname}
            required
          />
          <ErrorLabel errors={!props.deceased.lastname || props.deceased.lastname === ''}>
            Inserisci il cognome del defunto
          </ErrorLabel>
        </Form.Group>
      </Form.Row>

      <Form.Group>
        <Form.Label>Sesso</Form.Label>
        <Form.Control
          as="select"
          name="gender"
          value={props.deceased.gender}
          onChange={props.changeDeceasedFieldHandler}
        >
          <option>M</option>
          <option>F</option>
        </Form.Control>
      </Form.Group>

      <Form.Row>
        <Form.Group as={Col}>
          <Form.Label>Data nascita</Form.Label>
          <Form.Control
            type="date"
            placeholder="Data nascita"
            name="birth"
            value={formatDateYYYYMMDD(props.deceased.birth)}
            onChange={props.changeDeceasedFieldHandler}
            isInvalid={props.errors?.birth}
          />
        </Form.Group>
        <Form.Group as={Col}>
          <Form.Label>Data decesso</Form.Label>
          <Form.Control
            type="date"
            placeholder="Data decesso"
            name="death"
            value={formatDateYYYYMMDD(props.deceased.death)}
            onChange={props.changeDeceasedFieldHandler}
            isInvalid={props.errors?.death}
            required
          />
        </Form.Group>
      </Form.Row>

      <Form.Group>
        <Form.Label>Indirizzo</Form.Label>
        <Form.Control
          type="text"
          placeholder="Indirizzo"
          name="address"
          value={props.deceased.address}
          onChange={props.changeDeceasedFieldHandler}
          isInvalid={props.errors?.address}
        />
      </Form.Group>

      <Form.Row>
        <Form.Group as={Col}>
          <Form.Label>Città</Form.Label>
          <Form.Control
            type="text"
            placeholder="Città"
            name="city"
            value={props.deceased.city}
            onChange={props.changeDeceasedFieldHandler}
            isInvalid={props.errors?.city}
          />
        </Form.Group>

        <Form.Group as={Col}>
          <Form.Label>Provincia</Form.Label>
          <Form.Control
            type="text"
            placeholder="Prov"
            maxLength={2}
            name="prov"
            value={props.deceased.prov}
            onChange={props.changeDeceasedFieldHandler}
            isInvalid={props.errors?.prov}
          />
        </Form.Group>
      </Form.Row>

      <Button variant="primary" type="submit">
        Salva
      </Button>
    </Form>
  );
};

function ErrorLabel({ errors, children }) {
  if (errors) return <Form.Label className="text-danger">{children}</Form.Label>;
  else return null;
}
export default DeceasedForm;
