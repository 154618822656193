import React, { useEffect, useState, useContext } from "react";
import UserContext from "../../UserContext";
import { contentLoading, contentLoaded } from "../../reducers/actions";
import BaseLayout from "../layouts/BaseLayout";
import { listTransactions, transactionInfo } from "../../api/payments";
import { useHistory, useLocation } from "react-router-dom";
import Pagination from "../../components/Pagination";
import {
  Container,
  Row,
  Col,
  Table,
  Button,
  Form,
  Card,
} from "react-bootstrap";
import { formatDateLong, formatTimeShort } from "../../utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";

const Transactions = () => {
  const { state, dispatch } = useContext(UserContext);
  const location = useLocation();
  const history = useHistory();
  const [params, setParams] = useState();
  const [nameFilter, setNameFilter] = useState("");
  const [notCompletedFilter, setNotCompletedFilter] = useState(false);
  const [data, setData] = useState();
  const [showBusinessInfo, setShowBusinessInfo] = useState();
  const [showQtInfo, setShowQtInfo] = useState();
  const [showAreaInfo, setShowAreaInfo] = useState();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const name = searchParams.get("name") || "";
    const completed = searchParams && searchParams.get("notcompleted") === "1";
    setNameFilter(name);
    setNotCompletedFilter(completed);
    const updatedParams = {
      pageNo: searchParams.get("page") || 1,
      nameFilter: name,
      notCompletedFilter: completed,
    };
    setParams(updatedParams);
  }, [location]);

  useEffect(() => {
    if (params) {
      (async () => {
        dispatch(contentLoading());
        const res = await listTransactions(
          params.nameFilter,
          params.notCompletedFilter,
          params.pageNo
        );
        setData(res);
        dispatch(contentLoaded());
      })();
    }
    setShowBusinessInfo(state.user?.role === "A");
    setShowQtInfo(["A", "G"].includes(state.user?.role));
    setShowAreaInfo(["A", "S"].includes(state.user?.role));
  }, [params]);

  const changeFilters = (e) => {
    if (e) e.preventDefault();
    history.push(
      location.pathname +
        "?name=" +
        nameFilter +
        "&notcompleted=" +
        (notCompletedFilter ? 1 : 0)
    );
  };

  const notCompletedFilterChanged = (e) => {
    const isChecked = e.target.checked;
    setNotCompletedFilter(isChecked);
    history.push(
      location.pathname +
        "?name=" +
        nameFilter +
        "&notcompleted=" +
        (isChecked ? 1 : 0)
    );
  };

  const pageChangedHandler = (page) => {
    let url = location.pathname + "?page=" + page;
    if (params.nameFilter) url += "&name=" + params.nameFilter;
    url += "&notcompleted=" + (notCompletedFilter ? 1 : 0);
    history.push(url);
  };

  const showNotConfirmedTransactionInfo = (id) => {
    (async () => {
      const res = await transactionInfo(id);
      if (res.error) {
        alert(res.error.message);
      } else {
        const agencyName = res.user.agency.name;
        const deceasedName = `${res.firstname} ${res.lastname}`;
        alert(`Agenzia: ${agencyName}\nDefunto: ${deceasedName}`);
      }
    })();
  };

  return (
    <Card>
      <Card.Header>
        <Card.Title>Le tue transazioni</Card.Title>
      </Card.Header>
      <Card.Body>
        <Container fluid>
          <Row className="align-items-center">
            <Col xs={12} md={8}>
              <Form actions="/suppliers" onSubmit={changeFilters}>
                <Row className="align-items-center">
                  <Col className="pr-0">
                    <Form.Control
                      type="text"
                      placeholder="Nome agenzia..."
                      onChange={(e) => setNameFilter(e.target.value)}
                      value={nameFilter}
                    />
                  </Col>
                  <Col className="pl-1">
                    <Button type="submit">
                      <FontAwesomeIcon icon={faSearch} />
                    </Button>
                  </Col>
                </Row>
                <Form.Check
                  id="notcompleted"
                  type="switch"
                  custom
                  onChange={(e) => notCompletedFilterChanged(e)}
                  checked={notCompletedFilter}
                  label="Solo transazioni da completare"
                />
              </Form>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <Table responsive hover>
                <thead>
                  <tr>
                    <th style={{ display: "table-cell" }}>Data</th>
                    {showBusinessInfo ? <th>Cliente</th> : null}
                    <th style={{ display: "table-cell" }}>Prodotto</th>
                    {showQtInfo ? <th>Qt</th> : null}
                    {showAreaInfo || showQtInfo ? <th>Info</th> : null}
                    <th>Totale</th>
                    <th style={{ display: "table-cell" }}>
                      ID Ordine / ID Pagamento
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {data && data.data ? (
                    data.data.map((transaction) => (
                      <tr key={transaction.id}>
                        <td style={{ display: "table-cell" }}>
                          <div>
                            {formatDateLong(transaction.created_at, false)}
                          </div>
                          <small>
                            {formatTimeShort(transaction.created_at)}
                          </small>
                        </td>
                        {showBusinessInfo ? (
                          <td>
                            {transaction.user?.role === "G" ? (
                              <>
                                <strong>{transaction.user?.agency.name}</strong>
                                <br />
                                <small>
                                  {transaction.user?.agency.address}, &nbsp;
                                  {transaction.user?.agency.city}&nbsp;-&nbsp;
                                  {transaction.user?.agency.prov}
                                </small>
                              </>
                            ) : transaction.user?.role === "S" ? (
                              <>
                                <strong>
                                  {transaction.user?.supplier.name}
                                </strong>
                                <br />
                                <small>
                                  {transaction.user?.supplier.address}, &nbsp;
                                  {transaction.user?.supplier.city}
                                  &nbsp;-&nbsp;
                                  {transaction.user?.supplier.prov}
                                </small>
                              </>
                            ) : null}
                            <div>{transaction.user?.email}</div>
                          </td>
                        ) : null}
                        <td style={{ display: "table-cell" }}>
                          <div>
                            {transaction.product.name ? (
                              transaction.product.name
                            ) : (
                              <i>Nuova</i>
                            )}
                          </div>
                          <small>{transaction.price_for_item} € cad.</small>
                        </td>
                        {showQtInfo ? (
                          <td>
                            <div style={{ fontSize: "16px" }}>
                              {transaction.qt}
                            </div>
                          </td>
                        ) : null}
                        {showAreaInfo || showQtInfo ? (
                          <td>
                            {transaction.transaction_area ? (
                              <>
                                <strong>
                                  {transaction.transaction_area?.area.name}
                                </strong>
                                <div>
                                  Posizione{" "}
                                  {transaction.transaction_area?.position}
                                </div>
                                <div>
                                  <small>
                                    dal{" "}
                                    {formatDateLong(
                                      transaction.transaction_area?.date_start,
                                      false
                                    )}
                                  </small>
                                </div>
                                <div>
                                  <small>
                                    <span>
                                      al{" "}
                                      {formatDateLong(
                                        transaction.transaction_area?.date_end,
                                        false
                                      )}
                                    </span>
                                  </small>
                                </div>
                              </>
                            ) : transaction.transaction_deceased ? (
                              <>
                                <strong>Defunti</strong>
                                {transaction.transaction_deceased.map(
                                  (transactionDeceased) => (
                                    <div key={transactionDeceased.id}>
                                      <small>
                                        {transactionDeceased.deceased.lastname}{" "}
                                        {transactionDeceased.deceased.firstname}
                                      </small>
                                    </div>
                                  )
                                )}
                              </>
                            ) : null}
                          </td>
                        ) : null}
                        <td>
                          <h6>{transaction.amount} €</h6>
                        </td>
                        <td style={{ display: "table-cell" }}>
                          {transaction.order_id}
                          {transaction.payment_id ? (
                            " / " + transaction.payment_id
                          ) : (
                            <div>
                              <Button
                                variant="primary"
                                size="sm"
                                onClick={(e) =>
                                  showNotConfirmedTransactionInfo(
                                    transaction.id
                                  )
                                }
                              >
                                Non confermata
                              </Button>
                            </div>
                          )}
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="4">No result</td>
                    </tr>
                  )}
                </tbody>
                <tfoot>
                  <tr>
                    <td colSpan="7" style={{ paddingBottom: 0 }}>
                      <Pagination
                        currentPage={data?.current_page}
                        totPages={data?.last_page}
                        pageChanged={pageChangedHandler}
                      />
                    </td>
                  </tr>
                </tfoot>
              </Table>
            </Col>
          </Row>
        </Container>
      </Card.Body>
    </Card>
  );
};

export default Transactions;
