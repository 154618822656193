import React, { useEffect, useState } from 'react'
import { Modal, Container, Form, Button } from 'react-bootstrap'
import { formatDateISO8601 } from '../utils'
import moment from 'moment/min/moment-with-locales'

const DateRangePicker = (props) => {
    const [show, setShow] = useState()
    const [validated, setValidated] = useState(false)
    const [selectedRange, setSelectetRange] = useState({
        'date_start': null,
        'date_end': null
    })
    
    useEffect(() => {
        setShow(props.show)
    }, [props.show])
  
    const closeEventModalHandler = () => {
        props.onClose(selectedRange)
    }

    const changeEventFieldHandler = e => {
        const { name, value } = e.target
        const newEventDateTime = moment.utc(value)      
        const dateTime = formatDateISO8601(newEventDateTime)
        
        if (name === 'dateFrom') {
            setSelectetRange({...selectedRange, date_start: dateTime})
        } else {
            setSelectetRange({...selectedRange, date_end: dateTime})
        }
    }

    const saveEventHandler = e => {
        props.onClose(selectedRange)
    }

    return (
        <Modal show={show !== false}onHide={closeEventModalHandler}  backdrop="static">
            <Modal.Header closeButton>
                <Modal.Title > Intervallo di date personalizzato</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Container>
                    <Form noValidate validated={validated} onSubmit={ saveEventHandler }>
                            <Form.Group _style={{marginBottom: '0'}}>
                                <Form.Label>Da</Form.Label>
                                <Form.Control name="dateFrom" type="date" placeholder="Da" 
                                    onChange={ changeEventFieldHandler }
                                    required />
                            </Form.Group>
                            <Form.Group _style={{marginBottom: '0'}}>
                                <Form.Label>a</Form.Label>
                                <Form.Control name="dateTo" type="date" placeholder="Da" 
                                    onChange={ changeEventFieldHandler }
                                    required />
                            </Form.Group>
                            <div>
                            <Button variant="secondary"  onClick={ closeEventModalHandler }>Annulla</Button>
                            <Button variant="primary" onClick={ closeEventModalHandler } >Salva</Button>
                        </div>
                    </Form>
                </Container>
            </Modal.Body>
        </Modal>
    )
}

export default DateRangePicker
